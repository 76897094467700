<!-- Modal -->
<div *ngIf="modal.open"  style=" background-color: rgba(0, 0, 0, 0.8)" class="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" x-show.transition.opacity="openModal">
    <div class="p-4 max-w-xl mx-auto relative absolute left-0 right-0 overflow-hidden mt-24">
        <div class="shadow absolute right-0 top-0 w-10 h-10 rounded-full bg-white text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer"
            (click)="modal.open = false">
            <svg class="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                    d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
            </svg>
        </div>

        <div class="shadow w-full rounded-lg bg-white overflow-hidden w-full block p-8" *ngIf="modal.cntx == 'add-bank'">
            
            <h2 class="font-bold text-2xl mb-6 text-gray-800 border-b pb-2">Добавяне на банкова сметка</h2>

            <div class="w-full">
				<div class="mb-2 flex flex-col items-center">
					<label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">Име на шаблон <span class="text-red-500">*</span></label>
					<input [(ngModel)]="modal.obj.name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="text">
				</div>
			</div>

            <div class="w-full flex">
                <div class="w-2/4">
                    <div class="mb-2 flex flex-col items-center">
                        <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">Име на банка <span class="text-red-500">*</span></label>
                        <input [(ngModel)]="modal.obj.bank" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="text">
                    </div>
                </div>
                <div class="w-2/4 ml-4">
                    <div class="mb-2 flex flex-col items-center">
                        <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">BIC<span class="text-red-500">*</span></label>
                        <input [(ngModel)]="modal.obj.bic" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="text">
                    </div>
                </div>
            </div>
            <div class="w-full">
				<div class="mb-2 flex flex-col items-center">
					<label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">IBAN <span class="text-red-500">*</span></label>
					<input [(ngModel)]="modal.obj.iban" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="text">
				</div>
			</div>
            <div class="mt-8 text-right">
                <button type="button" (click)="modal.open = false" class="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded shadow-sm mr-2">
                    Отказ
                </button>	
                <button type="button" (click)="system.AddBank(modal.obj, this)" class="bg-gray-800 hover:bg-gray-700 text-white font-semibold py-2 px-4 border border-gray-700 rounded shadow-sm">
                    Добавяне
                </button>	
            </div>
        </div>

        <div class="shadow w-full rounded-lg bg-white overflow-hidden w-full block p-8" *ngIf="modal.cntx == 'remove-bank'">
            
            <h2 class="font-bold text-2xl mb-6 text-gray-800 border-b pb-2">Премахване на банкова сметка</h2>

            <div class="flex">
                <span>Сигурни ли сте, че искате да премахнете сметка <strong>"{{ modal.obj.name }}"</strong> ?</span>
            </div>

            <div class="mt-8 text-right">
                <button type="button" (click)="modal.open = false" class="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded shadow-sm mr-2">
                    Не
                </button>	
                <button (click)="system.RemoveBank(modal.obj.uid, this)" type="button" class="bg-gray-800 hover:bg-gray-700 text-white font-semibold py-2 px-4 border border-gray-700 rounded shadow-sm">
                    Да
                </button>	
            </div>
        </div>

        <div class="shadow w-full rounded-lg bg-white overflow-hidden w-full block p-8" *ngIf="modal.cntx == 'add-currency'">
            
            <h2 class="font-bold text-2xl mb-6 text-gray-800 border-b pb-2">Добавяне на валута</h2>

            <div class="w-full">
				<div class="mb-2 flex flex-col items-center">
					<label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">Наименование <span class="text-red-500">*</span></label>
					<input [(ngModel)]="modal.obj.name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="text">
				</div>
			</div>

            <div class="w-full flex">
				<div class="mb-2 flex flex-col items-center w-3/4">
					<label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">Описание <span class="text-red-500">*</span></label>
					<input [(ngModel)]="modal.obj.description" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="text">
				</div>

                <div class="mb-2 flex flex-col items-center w-1/4 ml-2">
					<label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">Символ <span class="text-red-500">*</span></label>
					<input [(ngModel)]="modal.obj.label" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="text">
				</div>
			</div>

            <div class="w-full flex">
				<div class="mb-2 w-2/4 flex flex-col items-center">
					<label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">Позиция <span class="text-red-500">*</span></label>
					<select [(ngModel)]="modal.obj.position" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full">
                        <option value="front">Пред стойността</option>
                        <option value="back">Зад стойността</option>
                    </select>  
				</div>
                <div class="mb-2 w-2/4 flex flex-col items-center ml-2">
					<label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">Разстояние <span class="text-red-500">*</span></label>
					<select [(ngModel)]="modal.obj.spacing" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full">
                        <option value="1">Да</option>
                        <option value="0">Не</option>
                    </select>  
				</div>
			</div>
            <div class="mt-8 text-right">
                <button type="button" (click)="modal.open = false" class="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded shadow-sm mr-2">
                    Отказ
                </button>	
                <button type="button" (click)="system.AddCurrency(modal.obj, this)" class="bg-gray-800 hover:bg-gray-700 text-white font-semibold py-2 px-4 border border-gray-700 rounded shadow-sm">
                    Добавяне
                </button>	
            </div>
        </div>

        <div class="shadow w-full rounded-lg bg-white overflow-hidden w-full block p-8" *ngIf="modal.cntx == 'remove-currency'">
            
            <h2 class="font-bold text-2xl mb-6 text-gray-800 border-b pb-2">Премахване на валута</h2>

            <div class="flex">
                <span>Сигурни ли сте, че искате да премахнете валута <strong>"{{ modal.obj.name }}"</strong> ?</span>
            </div>

            <div class="mt-8 text-right">
                <button type="button" (click)="modal.open = false" class="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded shadow-sm mr-2">
                    Не
                </button>	
                <button (click)="system.RemoveCurrency(modal.obj.uid, this)" type="button" class="bg-gray-800 hover:bg-gray-700 text-white font-semibold py-2 px-4 border border-gray-700 rounded shadow-sm">
                    Да
                </button>	
            </div>
        </div>

        <div class="shadow w-full rounded-lg bg-white overflow-hidden w-full block p-8" *ngIf="modal.cntx == 'add-metric'">
            
            <h2 class="font-bold text-2xl mb-6 text-gray-800 border-b pb-2">Добавяне на мерна единица</h2>

            <div class="w-full">
				<div class="mb-2 flex flex-col items-center">
					<label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">Описание <span class="text-red-500">*</span></label>
					<input [(ngModel)]="modal.obj.description" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="text">
				</div>
			</div>

            <div class="w-full flex">
                <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">Мерна единица <span class="text-red-500">*</span></label>
                <input [(ngModel)]="modal.obj.name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="text">
			</div>

            <div class="mt-8 text-right">
                <button type="button" (click)="modal.open = false" class="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded shadow-sm mr-2">
                    Отказ
                </button>	
                <button type="button" (click)="system.AddMetric(modal.obj, this)" class="bg-gray-800 hover:bg-gray-700 text-white font-semibold py-2 px-4 border border-gray-700 rounded shadow-sm">
                    Добавяне
                </button>	
            </div>
        </div>

        <div class="shadow w-full rounded-lg bg-white overflow-hidden w-full block p-8" *ngIf="modal.cntx == 'remove-metric'">
            
            <h2 class="font-bold text-2xl mb-6 text-gray-800 border-b pb-2">Премахване на мерна единица</h2>

            <div class="flex">
                <span>Сигурни ли сте, че искате да премахнете мерна единица <strong>"{{ modal.obj.name }}"</strong> ?</span>
            </div>

            <div class="mt-8 text-right">
                <button type="button" (click)="modal.open = false" class="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded shadow-sm mr-2">
                    Не
                </button>	
                <button (click)="system.RemoveMetric(modal.obj.uid, this)" type="button" class="bg-gray-800 hover:bg-gray-700 text-white font-semibold py-2 px-4 border border-gray-700 rounded shadow-sm">
                    Да
                </button>	
            </div>
        </div>

        <div class="shadow w-full rounded-lg bg-white overflow-hidden w-full block p-8" *ngIf="modal.cntx == 'add-vat'">
            
            <h2 class="font-bold text-2xl mb-6 text-gray-800 border-b pb-2">{{ languageService.ReturnTranslatedLabel('label_add_vat') }}</h2>

            <div class="w-full">
				<div class="mb-2 flex flex-col items-center">
					<label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">{{ languageService.ReturnTranslatedLabel('label_description') }} <span class="text-red-500">*</span></label>
					<input [(ngModel)]="modal.obj.description" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="text">
				</div>
			</div>

            <div class="w-full flex">
                <div class="mb-2 flex flex-col items-center">
                    <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">{{ languageService.ReturnTranslatedLabel('label_vat_nomenclature') }} <span class="text-red-500">*</span></label>
                    <input [(ngModel)]="modal.obj.vat" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="number">
                </div>
            </div>

            <div class="mt-8 text-right">
                <button type="button" (click)="modal.open = false" class="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded shadow-sm mr-2">
                    Отказ
                </button>	
                <button type="button" (click)="system.AddVat(modal.obj, this)" class="bg-gray-800 hover:bg-gray-700 text-white font-semibold py-2 px-4 border border-gray-700 rounded shadow-sm">
                    Добавяне
                </button>	
            </div>
        </div>

        
        <div class="shadow w-full rounded-lg bg-white overflow-hidden w-full block p-8" *ngIf="modal.cntx == 'remove-vat'">
            
            <h2 class="font-bold text-2xl mb-6 text-gray-800 border-b pb-2">{{ languageService.ReturnTranslatedLabel('label_remove_vat') }}</h2>

            <div class="flex">
                <span>{{ languageService.ReturnTranslatedLabel('label_confirm_remove_vat') }} <strong>"{{ modal.obj.vat }}"</strong> ?</span>
            </div>

            <div class="mt-8 text-right">
                <button type="button" (click)="modal.open = false" class="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded shadow-sm mr-2">
                    {{ languageService.ReturnTranslatedLabel('action_no') }}
                </button>	
                <button (click)="system.RemoveVat(modal.obj.uid, this)" type="button" class="bg-gray-800 hover:bg-gray-700 text-white font-semibold py-2 px-4 border border-gray-700 rounded shadow-sm">
                    {{ languageService.ReturnTranslatedLabel('action_yes') }}
                </button>	
            </div>
        </div>

        <div class="shadow w-full rounded-lg bg-white overflow-hidden w-full block p-8" *ngIf="modal.cntx == 'add-user'">
            
            <h2 class="font-bold text-2xl mb-6 text-gray-800 border-b pb-2">Изпращане на покана</h2>

            <div class="w-full">
				<div class="mb-2 flex flex-col items-center">
					<label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">Име<span class="text-red-500">*</span></label>
					<input [(ngModel)]="modal.obj.name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="text">
				</div>
			</div>

            <div class="w-full">
                <div class="mb-2 flex flex-col items-center">
                    <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">Е-мейл <span class="text-red-500">*</span></label>
                    <input [(ngModel)]="modal.obj.email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="text">
                </div>
            </div>

            <div class="w-full">
                <div class="mb-2 flex flex-col items-center">
                    <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">Роля <span class="text-red-500">*</span></label>
                    <select [(ngModel)]="modal.obj.role" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ">
                        <option value="admin">Администратор</option>
                        <option value="user">Потребител</option>
                    </select>
                </div>
            </div>

            <div class="mt-8 text-right">
                <button type="button" (click)="modal.open = false" class="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded shadow-sm mr-2">
                    Отказ
                </button>	
                <button type="button" (click)="AddUser()" class="bg-gray-800 hover:bg-gray-700 text-white font-semibold py-2 px-4 border border-gray-700 rounded shadow-sm">
                    Изпрати
                </button>	
            </div>
        </div>
    </div>

    <div class="viewer-holder" *ngIf="modal.cntx == 'view-template'">
        <div class="viewer-photo" [ngStyle]="{ 'background-image':'url('+ modal.obj.template +')' }"></div>
        <button type="button" (click)="modal.open = false" style="left: 10px;top: 10px;" class="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded shadow-sm absolute">
            Назад
        </button>	
    </div>
</div>

<div class="loader-overlay" *ngIf="import_settings.importing"></div>
<div class="loader-modal"  *ngIf="import_settings.importing">
    <div class="loader-svg"></div>
    <div class="loader-text">
        <span *ngIf="import_settings.import_action == 'categories'">Импортиране на категории</span>
        <span *ngIf="import_settings.import_action == 'products'">Импортиране на продукти</span>
        <span *ngIf="import_settings.import_action == 'categories'">Категория {{ import_settings.category_idx + 1 }} от {{ import_settings.categories.length }}</span>
        <span *ngIf="import_settings.import_action == 'products'">Продукт {{ import_settings.product_idx + 1 }} от  {{ import_settings.products.length }}</span>
    </div>
</div>
<!-- /Modal -->


<div class="w-full flex-col flex bg-nexa">
    <div class="flex w-full flex-row" style="height:50px;line-height:50px;background: #fafafa;border-bottom: 1px solid #dbdbdb;">
        <svg style="float: left;
        width: 30px;
        height: 30px;
        margin-left: 10px;
        margin-top: 10px;" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
        </svg>  
        <span style="    font-size: 18px;
        margin-left: 10px;
        font-weight: bold;
        text-transform: uppercase;">Настройки</span>        
    </div>
    <div class="flex w-full flex-row" style="height: calc(100vh - 130px);">
        <div class="bg-white w-1/5 flex-col" style="border-right:1px solid #dbdbdb">
            <div class="flex-row flex w-full hover:bg-gray-100 cursor-pointer" (click)="SwitchTab(3)">
                <div class="flex w-1/4" style="height: 70px;line-height: 70px;">
                    <div class="settings-avatar" [ngClass]="{ 'active':tab == 3 }">
                        <svg aria-hidden="true" [ngClass]="{ 'text-white':tab == 3, 'text-gray-400': tab != 3  }" class="w-5 h-5 mr-2 group-hover:text-gray-500 " fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M11.828 2.25c-.916 0-1.699.663-1.85 1.567l-.091.549a.798.798 0 01-.517.608 7.45 7.45 0 00-.478.198.798.798 0 01-.796-.064l-.453-.324a1.875 1.875 0 00-2.416.2l-.243.243a1.875 1.875 0 00-.2 2.416l.324.453a.798.798 0 01.064.796 7.448 7.448 0 00-.198.478.798.798 0 01-.608.517l-.55.092a1.875 1.875 0 00-1.566 1.849v.344c0 .916.663 1.699 1.567 1.85l.549.091c.281.047.508.25.608.517.06.162.127.321.198.478a.798.798 0 01-.064.796l-.324.453a1.875 1.875 0 00.2 2.416l.243.243c.648.648 1.67.733 2.416.2l.453-.324a.798.798 0 01.796-.064c.157.071.316.137.478.198.267.1.47.327.517.608l.092.55c.15.903.932 1.566 1.849 1.566h.344c.916 0 1.699-.663 1.85-1.567l.091-.549a.798.798 0 01.517-.608 7.52 7.52 0 00.478-.198.798.798 0 01.796.064l.453.324a1.875 1.875 0 002.416-.2l.243-.243c.648-.648.733-1.67.2-2.416l-.324-.453a.798.798 0 01-.064-.796c.071-.157.137-.316.198-.478.1-.267.327-.47.608-.517l.55-.091a1.875 1.875 0 001.566-1.85v-.344c0-.916-.663-1.699-1.567-1.85l-.549-.091a.798.798 0 01-.608-.517 7.507 7.507 0 00-.198-.478.798.798 0 01.064-.796l.324-.453a1.875 1.875 0 00-.2-2.416l-.243-.243a1.875 1.875 0 00-2.416-.2l-.453.324a.798.798 0 01-.796.064 7.462 7.462 0 00-.478-.198.798.798 0 01-.517-.608l-.091-.55a1.875 1.875 0 00-1.85-1.566h-.344zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z" clip-rule="evenodd" />
                        </svg>
                    </div>
                </div>
                <div class="flex w-3/4 settings-text" [ngClass]="{ 'active':tab == 3 }">
                    {{ languageService.ReturnTranslatedLabel('label_settings_general') }}
                </div>
            </div>
            <div class="flex-row flex w-full hover:bg-gray-100 cursor-pointer" (click)="SwitchTab(1)">
                <div class="flex w-1/4" style="height: 70px;line-height: 70px;">
                    <div class="settings-avatar" [ngClass]="{ 'active':tab == 1 }">
                        <svg aria-hidden="true" [ngClass]="{ 'text-white':tab == 1, 'text-gray-400': tab != 1  }" class="w-5 h-5 mr-2 group-hover:text-gray-500 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd"></path></svg>
                    </div>
                </div>
                <div class="flex w-3/4 settings-text" [ngClass]="{ 'active':tab == 1 }">
                    {{ languageService.ReturnTranslatedLabel('label_settings_company') }}
                </div>
            </div>
            <div class="flex-row flex w-full hover:bg-gray-100 cursor-pointer" (click)="SwitchTab(2)">
                <div class="flex w-1/4" style="height: 70px;line-height: 70px;">
                    <div class="settings-avatar" [ngClass]="{ 'active':tab == 2 }">
                        <svg aria-hidden="true" [ngClass]="{ 'text-white':tab == 2, 'text-gray-400': tab != 2 }" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"> <path d="M5.625 3.75a2.625 2.625 0 100 5.25h12.75a2.625 2.625 0 000-5.25H5.625zM3.75 11.25a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5H3.75zM3 15.75a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75zM3.75 18.75a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5H3.75z" /></svg>
                    </div>
                </div>
                <div class="flex w-3/4 settings-text" [ngClass]="{ 'active':tab == 2 }">
                   Финансови инструменти
                </div>
            </div>
            <div class="flex-row flex w-full hover:bg-gray-100 cursor-pointer" (click)="SwitchTab(4)">
                <div class="flex w-1/4" style="height: 70px;line-height: 70px;">
                    <div class="settings-avatar" [ngClass]="{ 'active':tab == 4 }">
                        <svg aria-hidden="true" [ngClass]="{ 'text-white':tab == 4, 'text-gray-400': tab != 4 }" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path></svg>
                    </div>
                </div>
                <div class="flex w-3/4 settings-text" [ngClass]="{ 'active':tab == 4 }">
                    {{ languageService.ReturnTranslatedLabel('label_settings_banks') }}
                </div>
            </div>
            <div class="flex-row flex w-full hover:bg-gray-100 cursor-pointer" (click)="SwitchTab(5)">
                <div class="flex w-1/4" style="height: 70px;line-height: 70px;">
                    <div class="settings-avatar" [ngClass]="{ 'active':tab == 5 }">
                        <svg aria-hidden="true" [ngClass]="{ 'text-white':tab == 5, 'text-gray-400': tab != 5 }" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M21 6.375c0 2.692-4.03 4.875-9 4.875S3 9.067 3 6.375 7.03 1.5 12 1.5s9 2.183 9 4.875z" />
                            <path d="M12 12.75c2.685 0 5.19-.586 7.078-1.609a8.283 8.283 0 001.897-1.384c.016.121.025.244.025.368C21 12.817 16.97 15 12 15s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.285 8.285 0 001.897 1.384C6.809 12.164 9.315 12.75 12 12.75z" />
                            <path d="M12 16.5c2.685 0 5.19-.586 7.078-1.609a8.282 8.282 0 001.897-1.384c.016.121.025.244.025.368 0 2.692-4.03 4.875-9 4.875s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.284 8.284 0 001.897 1.384C6.809 15.914 9.315 16.5 12 16.5z" />
                            <path d="M12 20.25c2.685 0 5.19-.586 7.078-1.609a8.282 8.282 0 001.897-1.384c.016.121.025.244.025.368 0 2.692-4.03 4.875-9 4.875s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.284 8.284 0 001.897 1.384C6.809 19.664 9.315 20.25 12 20.25z" /></svg>
                    </div>
                </div>
                <div class="flex w-3/4 settings-text" [ngClass]="{ 'active':tab == 5 }">
                    {{ languageService.ReturnTranslatedLabel('label_settings_currency_and_metrics') }}
                </div>
            </div>

            <div class="flex-row flex w-full hover:bg-gray-100 cursor-pointer" (click)="SwitchTab(6)">
                <div class="flex w-1/4" style="height: 70px;line-height: 70px;">
                    <div class="settings-avatar" [ngClass]="{ 'active':tab == 6 }">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" [ngClass]="{ 'text-white':tab == 6, 'text-gray-400': tab != 6 }" class="w-5 h-5 mr-2">
                            <path d="M11.25 4.533A9.707 9.707 0 006 3a9.735 9.735 0 00-3.25.555.75.75 0 00-.5.707v14.25a.75.75 0 001 .707A8.237 8.237 0 016 18.75c1.995 0 3.823.707 5.25 1.886V4.533zM12.75 20.636A8.214 8.214 0 0118 18.75c.966 0 1.89.166 2.75.47a.75.75 0 001-.708V4.262a.75.75 0 00-.5-.707A9.735 9.735 0 0018 3a9.707 9.707 0 00-5.25 1.533v16.103z" />
                        </svg>                   
                    </div>
                </div>
                <div class="flex w-3/4 settings-text" [ngClass]="{ 'active':tab == 6 }">
                    Шаблони
                </div>
            </div>

            <div class="flex-row flex w-full hover:bg-gray-100 cursor-pointer" (click)="SwitchTab(7)">
                <div class="flex w-1/4" style="height: 70px;line-height: 70px;">
                    <div class="settings-avatar" [ngClass]="{ 'active':tab == 7 }">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" [ngClass]="{ 'text-white':tab == 7, 'text-gray-400': tab != 7 }" class="w-5 h-5 mr-2">
                            <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                            <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                        </svg>    
                    </div>
                </div>
                <div class="flex w-3/4 settings-text" [ngClass]="{ 'active':tab == 7 }">
                   Е-мейли
                </div>
            </div>

            <div class="flex-row flex w-full hover:bg-gray-100 cursor-pointer" (click)="SwitchTab(9)">
                <div class="flex w-1/4" style="height: 70px;line-height: 70px;">
                    <div class="settings-avatar" [ngClass]="{ 'active':tab == 9 }">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"  [ngClass]="{ 'text-white':tab == 9, 'text-gray-400': tab != 9 }" class="w-5 h-5 mr-2">
                            <path fill-rule="evenodd" d="M3 6a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V6Zm14.25 6a.75.75 0 0 1-.22.53l-2.25 2.25a.75.75 0 1 1-1.06-1.06L15.44 12l-1.72-1.72a.75.75 0 1 1 1.06-1.06l2.25 2.25c.141.14.22.331.22.53Zm-10.28-.53a.75.75 0 0 0 0 1.06l2.25 2.25a.75.75 0 1 0 1.06-1.06L8.56 12l1.72-1.72a.75.75 0 1 0-1.06-1.06l-2.25 2.25Z" clip-rule="evenodd" />
                        </svg>
                    </div>
                </div>
                <div class="flex w-3/4 settings-text" [ngClass]="{ 'active':tab == 9 }">
                    Интеграции
                </div>
            </div>

            <div class="flex-row flex w-full hover:bg-gray-100 cursor-pointer" (click)="SwitchTab(8)">
                <div class="flex w-1/4" style="height: 70px;line-height: 70px;">
                    <div class="settings-avatar" [ngClass]="{ 'active':tab == 8 }">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"  [ngClass]="{ 'text-white':tab == 8, 'text-gray-400': tab != 8 }" class="w-5 h-5 mr-2">
                            <path d="M4.5 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM14.25 8.625a3.375 3.375 0 116.75 0 3.375 3.375 0 01-6.75 0zM1.5 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM17.25 19.128l-.001.144a2.25 2.25 0 01-.233.96 10.088 10.088 0 005.06-1.01.75.75 0 00.42-.643 4.875 4.875 0 00-6.957-4.611 8.586 8.586 0 011.71 5.157v.003z" />
                          </svg>
                    </div>
                </div>
                <div class="flex w-3/4 settings-text" [ngClass]="{ 'active':tab == 8 }">
                    Потребители
                </div>
            </div>
        </div>
        <div class="bg-white w-1/5" style="border-right:1px solid #dbdbdb" [ngClass]="{ 'hidden':tab == 9 }">
            <div class="flex flex-col w-full" *ngIf="tab == 3">
                <div class="flex-row flex w-full hover:bg-gray-100 cursor-pointer" (click)="subtab = 1">
                    <div class="flex w-1/4" style="height: 70px;line-height: 70px;">
                        <div class="settings-avatar" [ngClass]="{ 'active':subtab == 1 }">
                            <svg aria-hidden="true" [ngClass]="{ 'text-white':subtab == 1, 'text-gray-400': subtab != 1  }" class="w-5 h-5 mr-2 group-hover:text-gray-500 " fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M11.828 2.25c-.916 0-1.699.663-1.85 1.567l-.091.549a.798.798 0 01-.517.608 7.45 7.45 0 00-.478.198.798.798 0 01-.796-.064l-.453-.324a1.875 1.875 0 00-2.416.2l-.243.243a1.875 1.875 0 00-.2 2.416l.324.453a.798.798 0 01.064.796 7.448 7.448 0 00-.198.478.798.798 0 01-.608.517l-.55.092a1.875 1.875 0 00-1.566 1.849v.344c0 .916.663 1.699 1.567 1.85l.549.091c.281.047.508.25.608.517.06.162.127.321.198.478a.798.798 0 01-.064.796l-.324.453a1.875 1.875 0 00.2 2.416l.243.243c.648.648 1.67.733 2.416.2l.453-.324a.798.798 0 01.796-.064c.157.071.316.137.478.198.267.1.47.327.517.608l.092.55c.15.903.932 1.566 1.849 1.566h.344c.916 0 1.699-.663 1.85-1.567l.091-.549a.798.798 0 01.517-.608 7.52 7.52 0 00.478-.198.798.798 0 01.796.064l.453.324a1.875 1.875 0 002.416-.2l.243-.243c.648-.648.733-1.67.2-2.416l-.324-.453a.798.798 0 01-.064-.796c.071-.157.137-.316.198-.478.1-.267.327-.47.608-.517l.55-.091a1.875 1.875 0 001.566-1.85v-.344c0-.916-.663-1.699-1.567-1.85l-.549-.091a.798.798 0 01-.608-.517 7.507 7.507 0 00-.198-.478.798.798 0 01.064-.796l.324-.453a1.875 1.875 0 00-.2-2.416l-.243-.243a1.875 1.875 0 00-2.416-.2l-.453.324a.798.798 0 01-.796.064 7.462 7.462 0 00-.478-.198.798.798 0 01-.517-.608l-.091-.55a1.875 1.875 0 00-1.85-1.566h-.344zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z" clip-rule="evenodd" />
                            </svg>
                        </div>
                    </div>
                    <div class="flex w-3/4 settings-text" [ngClass]="{ 'active':subtab == 1 }">
                        {{ languageService.ReturnTranslatedLabel('label_settings_general') }}
                    </div>
                </div>
            </div>

            <div class="flex flex-col w-full" *ngIf="tab == 1">
                <div class="flex-row flex w-full hover:bg-gray-100 cursor-pointer" (click)="subtab = 1">
                    <div class="flex w-1/4" style="height: 70px;line-height: 70px;">
                        <div class="settings-avatar" [ngClass]="{ 'active':subtab == 1 }">
                            <svg aria-hidden="true" [ngClass]="{ 'text-white':subtab == 1, 'text-gray-400': subtab != 1 }" class="w-5 h-5 mr-2 group-hover:text-gray-500 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd"></path></svg>
                        </div>
                    </div>
                    <div class="flex w-3/4 settings-text" [ngClass]="{ 'active':subtab == 1 }">
                        Основни данни
                    </div>
                </div>
                <div class="flex-row flex w-full hover:bg-gray-100 cursor-pointer" (click)="subtab = 2">
                    <div class="flex w-1/4" style="height: 70px;line-height: 70px;">
                        <div class="settings-avatar" [ngClass]="{ 'active':subtab == 2 }">
                            <svg aria-hidden="true" [ngClass]="{ 'text-white':subtab == 2, 'text-gray-400': subtab != 2 }" class="w-5 h-5 mr-2 group-hover:text-gray-500 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd"></path></svg>
                        </div>
                    </div>
                    <div class="flex w-3/4 settings-text" [ngClass]="{ 'active':subtab == 2 }">
                        Международни данни
                    </div>
                </div>
            </div>

            <div class="flex flex-col w-full " *ngIf="tab == 2">
                <div class="flex-row flex w-full hover:bg-gray-100 cursor-pointer" (click)="subtab = 1">
                    <div class="flex w-1/4" style="height: 70px;line-height: 70px;">
                        <div class="settings-avatar" [ngClass]="{ 'active':subtab == 1 }">
                            <svg aria-hidden="true" [ngClass]="{ 'text-white':subtab == 1, 'text-gray-400': tab != 1 }" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"> <path d="M5.625 3.75a2.625 2.625 0 100 5.25h12.75a2.625 2.625 0 000-5.25H5.625zM3.75 11.25a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5H3.75zM3 15.75a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75zM3.75 18.75a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5H3.75z" /></svg>
                        </div>
                    </div>
                    <div class="flex w-3/4 settings-text" [ngClass]="{ 'active':subtab == 1 }">
                        {{ languageService.ReturnTranslatedLabel('label_settings_nomenclatures') }}
                    </div>
                </div>
                <div class="flex-row flex w-full hover:bg-gray-100 cursor-pointer" (click)="subtab = 2">
                    <div class="flex w-1/4" style="height: 70px;line-height: 70px;">
                        <div class="settings-avatar" [ngClass]="{ 'active':subtab == 2 }">
                            <svg aria-hidden="true" [ngClass]="{ 'text-white':subtab == 2, 'text-gray-400': subtab != 2 }" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M21 6.375c0 2.692-4.03 4.875-9 4.875S3 9.067 3 6.375 7.03 1.5 12 1.5s9 2.183 9 4.875z" />
                                <path fill-rule="evenodd" d="M3 6a3 3 0 013-3h12a3 3 0 013 3v12a3 3 0 01-3 3H6a3 3 0 01-3-3V6zm14.25 6a.75.75 0 01-.22.53l-2.25 2.25a.75.75 0 11-1.06-1.06L15.44 12l-1.72-1.72a.75.75 0 111.06-1.06l2.25 2.25c.141.14.22.331.22.53zm-10.28-.53a.75.75 0 000 1.06l2.25 2.25a.75.75 0 101.06-1.06L8.56 12l1.72-1.72a.75.75 0 10-1.06-1.06l-2.25 2.25z" clip-rule="evenodd" />
                            </svg>
                        </div>
                    </div>
                    <div class="flex w-3/4 settings-text" [ngClass]="{ 'active':subtab == 2 }">
                        {{ languageService.ReturnTranslatedLabel('label_settings_vat_nomenclatures') }}
                    </div>
                </div>
            </div>

            <div class="flex flex-col w-full " *ngIf="tab == 4">
                <div class="flex-row flex w-full hover:bg-gray-100 cursor-pointer" (click)="subtab = 1">
                    <div class="flex w-1/4" style="height: 70px;line-height: 70px;">
                        <div class="settings-avatar" [ngClass]="{ 'active':subtab == 1 }">
                            <svg aria-hidden="true" [ngClass]="{ 'text-white':subtab == 1, 'text-gray-400': subtab != 1 }" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path></svg>                            
                        </div>
                    </div>
                    <div class="flex w-3/4 settings-text" [ngClass]="{ 'active':subtab == 1 }">
                        Сметки
                    </div>
                </div>
            </div>

            <div class="flex flex-col w-full" *ngIf="tab == 5">
                <div class="flex-row flex w-full hover:bg-gray-100 cursor-pointer" (click)="subtab = 1">
                    <div class="flex w-1/4" style="height: 70px;line-height: 70px;">
                        <div class="settings-avatar" [ngClass]="{ 'active':subtab == 1 }">
                            <svg xmlns="http://www.w3.org/2000/svg" [ngClass]="{ 'text-white':subtab == 1, 'text-gray-400': subtab != 1 }" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 mr-2 group-hover:text-gray-500">
                                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.902 7.098a3.75 3.75 0 013.903-.884.75.75 0 10.498-1.415A5.25 5.25 0 008.005 9.75H7.5a.75.75 0 000 1.5h.054a5.281 5.281 0 000 1.5H7.5a.75.75 0 000 1.5h.505a5.25 5.25 0 006.494 2.701.75.75 0 00-.498-1.415 3.75 3.75 0 01-4.252-1.286h3.001a.75.75 0 000-1.5H9.075a3.77 3.77 0 010-1.5h3.675a.75.75 0 000-1.5h-3c.105-.14.221-.274.348-.402z" clip-rule="evenodd" />
                            </svg>                              
                        </div>
                    </div>
                    <div class="flex w-3/4 settings-text" [ngClass]="{ 'active':subtab == 1 }">
                        Валути
                    </div>
                </div>
                <div class="flex-row flex w-full hover:bg-gray-100 cursor-pointer" (click)="subtab = 2">
                    <div class="flex w-1/4" style="height: 70px;line-height: 70px;">
                        <div class="settings-avatar" [ngClass]="{ 'active':subtab == 2 }">
                            <svg xmlns="http://www.w3.org/2000/svg" [ngClass]="{ 'text-white':subtab == 2, 'text-gray-400': subtab != 2 }" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 mr-2 group-hover:text-gray-500 ">
                                <path fill-rule="evenodd" d="M12 2.25a.75.75 0 01.75.75v.756a49.106 49.106 0 019.152 1 .75.75 0 01-.152 1.485h-1.918l2.474 10.124a.75.75 0 01-.375.84A6.723 6.723 0 0118.75 18a6.723 6.723 0 01-3.181-.795.75.75 0 01-.375-.84l2.474-10.124H12.75v13.28c1.293.076 2.534.343 3.697.776a.75.75 0 01-.262 1.453h-8.37a.75.75 0 01-.262-1.453c1.162-.433 2.404-.7 3.697-.775V6.24H6.332l2.474 10.124a.75.75 0 01-.375.84A6.723 6.723 0 015.25 18a6.723 6.723 0 01-3.181-.795.75.75 0 01-.375-.84L4.168 6.241H2.25a.75.75 0 01-.152-1.485 49.105 49.105 0 019.152-1V3a.75.75 0 01.75-.75zm4.878 13.543l1.872-7.662 1.872 7.662h-3.744zm-9.756 0L5.25 8.131l-1.872 7.662h3.744z" clip-rule="evenodd" />
                            </svg>                              
                        </div>
                    </div>
                    <div class="flex w-3/4 settings-text" [ngClass]="{ 'active':subtab == 2 }">
                        Мерни единици
                    </div>
                </div>
            </div>

            <div class="flex flex-col w-full" *ngIf="tab == 6">
                <div class="flex-row flex w-full hover:bg-gray-100 cursor-pointer" (click)="subtab = 1">
                    <div class="flex w-1/4" style="height: 70px;line-height: 70px;">
                        <div class="settings-avatar" [ngClass]="{ 'active':subtab == 1 }">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" [ngClass]="{ 'text-white':subtab == 1, 'text-gray-400': subtab != 1 }" class="w-5 h-5 mr-2">
                                <path d="M11.25 4.533A9.707 9.707 0 006 3a9.735 9.735 0 00-3.25.555.75.75 0 00-.5.707v14.25a.75.75 0 001 .707A8.237 8.237 0 016 18.75c1.995 0 3.823.707 5.25 1.886V4.533zM12.75 20.636A8.214 8.214 0 0118 18.75c.966 0 1.89.166 2.75.47a.75.75 0 001-.708V4.262a.75.75 0 00-.5-.707A9.735 9.735 0 0018 3a9.707 9.707 0 00-5.25 1.533v16.103z" />
                            </svg>       
                        </div>
                    </div>
                    <div class="flex w-3/4 settings-text" [ngClass]="{ 'active':subtab == 1 }">
                        Шаблон - Фактури
                    </div>
                </div>
            </div>

            <div class="flex flex-col w-full" *ngIf="tab == 7">
                <div class="flex-row flex w-full hover:bg-gray-100 cursor-pointer" (click)="subtab = 1">
                    <div class="flex w-1/4" style="height: 70px;line-height: 70px;">
                        <div class="settings-avatar" [ngClass]="{ 'active':subtab == 1 }">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" [ngClass]="{ 'text-white':subtab == 1, 'text-gray-400': subtab != 1 }" class="w-5 h-5 mr-2">
                                <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                                <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                            </svg>    
                        </div>
                    </div>
                    <div class="flex w-3/4 settings-text" [ngClass]="{ 'active':subtab == 1 }">
                        Е-мейл - Фактури
                    </div>
                </div>
                <div class="flex-row flex w-full hover:bg-gray-100 cursor-pointer" (click)="subtab = 2">
                    <div class="flex w-1/4" style="height: 70px;line-height: 70px;">
                        <div class="settings-avatar" [ngClass]="{ 'active':subtab == 2 }">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" [ngClass]="{ 'text-white':subtab == 2, 'text-gray-400': subtab != 2 }" class="w-5 h-5 mr-2">
                                <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                                <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                            </svg>    
                        </div>
                    </div>
                    <div class="flex w-3/4 settings-text" [ngClass]="{ 'active':subtab == 2 }">
                        Е-мейл - Оферти
                    </div>
                </div>
                <div class="flex-row flex w-full hover:bg-gray-100 cursor-pointer" (click)="subtab = 3">
                    <div class="flex w-1/4" style="height: 70px;line-height: 70px;">
                        <div class="settings-avatar" [ngClass]="{ 'active':subtab == 3 }">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" [ngClass]="{ 'text-white':subtab == 3, 'text-gray-400': subtab != 3 }" class="w-5 h-5 mr-2">
                                <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                                <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                            </svg>    
                        </div>
                    </div>
                    <div class="flex w-3/4 settings-text" [ngClass]="{ 'active':subtab == 3 }">
                        Е-мейл - Поръчки
                    </div>
                </div>
            </div>

            <div class="flex flex-col w-full" *ngIf="tab == 8">
                <div class="flex-row flex w-full hover:bg-gray-100 cursor-pointer">
                    <div class="flex w-1/4" style="height: 70px;line-height: 70px;">
                        <div class="user-avatar" [ngStyle]="{ 'background-image':'url(https://crm.nexa.bg/avatars/'+ ReturnAvatarSha(system.ReturnMe()) +')' }"></div>
                    </div>
                    <div class="flex flex-col w-3/4 ml-2">
                        <span class="flex w-full mt-2" style="margin-top: 6px;font-size: 1rem;">{{ system.ReturnMe().first_name }} {{ system.ReturnMe().last_name }}</span>
                        <span class="flex w-full" style="font-size: 0.8rem;">{{ system.ReturnMe().username }}</span>
                        <span class="flex w-full" style="font-size: 0.7rem;color: #333333;">Администратор</span>
                    </div>
                </div>
                <div class="flex-row flex w-full hover:bg-gray-100 cursor-pointer" *ngFor="let user of system.ReturnUsers()">
                    <div class="flex w-1/4" style="height: 70px;line-height: 70px;">
                        <div class="user-avatar" [ngStyle]="{ 'background-image':'url(https://crm.nexa.bg/avatars/'+ ReturnAvatarSha(user) +')' }"></div>
                    </div>
                    <div class="flex flex-col w-3/4 ml-2">
                        <span class="flex w-full mt-2" style="margin-top: 6px;font-size: 1rem;">{{ user.first_name }} {{ user.last_name }}</span>
                        <span class="flex w-full" style="font-size: 0.8rem;">{{ user.username }}</span>
                        <span class="flex w-full" style="font-size: 0.7rem;color: #333333;">Потребител</span>
                    </div>
                </div>
                <div class="flex-row flex w-full hover:bg-gray-100 cursor-pointer" (click)="openModal('add-user', { 'name':'', 'email':'', role:'user' })">
                    <div class="flex w-1/4" style="height: 70px;line-height: 70px;">
                        <div class="settings-avatar">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" [ngClass]="{ 'text-white':subtab == 3, 'text-gray-400': subtab != 3 }" class="w-5 h-5 mr-2">
                                <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                                <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                            </svg>    
                        </div>
                    </div>
                    <div class="flex w-3/4 settings-text">
                        Добави потребител
                    </div>
                </div>
            </div>

            <!-- <div class="flex flex-col w-full" *ngIf="tab == 9">
                <div class="flex-row flex w-full hover:bg-gray-100 cursor-pointer" (click)="subtab = 1">
                    <div class="flex w-1/4" style="height: 70px;line-height: 70px;">
                        <div class="settings-avatar" [ngClass]="{ 'active':subtab == 1 }">
                            <svg xmlns="http://www.w3.org/2000/svg" [ngClass]="{ 'text-white':subtab == 1, 'text-gray-400': subtab != 1 }" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 mr-2 group-hover:text-gray-500">
                                <path d="M2.25 2.25a.75.75 0 0 0 0 1.5h1.386c.17 0 .318.114.362.278l2.558 9.592a3.752 3.752 0 0 0-2.806 3.63c0 .414.336.75.75.75h15.75a.75.75 0 0 0 0-1.5H5.378A2.25 2.25 0 0 1 7.5 15h11.218a.75.75 0 0 0 .674-.421 60.358 60.358 0 0 0 2.96-7.228.75.75 0 0 0-.525-.965A60.864 60.864 0 0 0 5.68 4.509l-.232-.867A1.875 1.875 0 0 0 3.636 2.25H2.25ZM3.75 20.25a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM16.5 20.25a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z" />
                            </svg>
                                                     
                        </div>
                    </div>
                    <div class="flex w-3/4 settings-text" [ngClass]="{ 'active':subtab == 1 }">
                        OpenCart
                    </div>
                </div>
            </div> -->
        </div>
        <div class="bg-white flex flex-row" [ngClass]="{ 'w-4/5':tab == 9, 'w-3/5':tab != 9 }">
            <div class="p-8 w-full" *ngIf="tab == 1 && subtab == 1">
                <div class="mb-6">
                    <label for="company_name" class="block mb-2 text-sm font-medium text-gray-900 ">{{ languageService.ReturnTranslatedLabel('label_company_name') }}</label>
                    <input type="text" id="company_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " [(ngModel)]="company_settings.company_name">
                </div>
                <div class="mb-6">
                    <label for="company_bulstat" class="block mb-2 text-sm font-medium text-gray-900 ">{{ languageService.ReturnTranslatedLabel('label_tax_id') }}</label>
                    <input type="text" id="company_bulstat" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " [(ngModel)]="company_settings.company_gov_num">
                </div>
                <div class="mb-6">
                    <label for="company_vat" class="block mb-2 text-sm font-medium text-gray-900 ">{{ languageService.ReturnTranslatedLabel('label_vat') }}</label>
                    <input type="text" id="company_vat" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " [(ngModel)]="company_settings.company_vat">
                </div>
                <div class="mb-6">
                    <label for="company_address" class="block mb-2 text-sm font-medium text-gray-900 ">{{ languageService.ReturnTranslatedLabel('label_address_registration') }}</label>
                    <input type="text" id="company_address" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " [(ngModel)]="company_settings.company_address">
                </div>
                <div class="mb-6">
                    <label for="company_city" class="block mb-2 text-sm font-medium text-gray-900 ">Град на регистрация</label>
                    <input type="text" id="company_city" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " [(ngModel)]="company_settings.company_city">
                </div>
                <div class="mb-6">
                    <label for="company_owner" class="block mb-2 text-sm font-medium text-gray-900 ">{{ languageService.ReturnTranslatedLabel('label_owner') }}</label>
                    <input type="text" id="company_owner" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " [(ngModel)]="company_settings.company_owner">
                </div>
                <button [ngClass]="{ 'disabled':!ReturnDidCompanySettingsChange() }" (click)="SaveSettings()" type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">{{ languageService.ReturnTranslatedLabel('action_save') }}</button>
            </div>
            <div class="p-8 w-full" *ngIf="tab == 1 && subtab == 2">
                <div class="mb-6">
                    <label for="company_name" class="block mb-2 text-sm font-medium text-gray-900 ">{{ languageService.ReturnTranslatedLabel('label_company_name') }} (EN)</label>
                    <input type="text" id="company_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " [(ngModel)]="company_settings.company_name_en">
                </div>
                <div class="mb-6">
                    <label for="company_address" class="block mb-2 text-sm font-medium text-gray-900 ">{{ languageService.ReturnTranslatedLabel('label_address_registration') }} (EN)</label>
                    <input type="text" id="company_address" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " [(ngModel)]="company_settings.company_address_en">
                </div>
                <div class="mb-6">
                    <label for="company_city" class="block mb-2 text-sm font-medium text-gray-900 ">Град на регистрация (EN)</label>
                    <input type="text" id="company_city" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " [(ngModel)]="company_settings.company_city_en">
                </div>
                <div class="mb-6">
                    <label for="company_owner" class="block mb-2 text-sm font-medium text-gray-900 ">{{ languageService.ReturnTranslatedLabel('label_owner') }} (EN)</label>
                    <input type="text" id="company_owner" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " [(ngModel)]="company_settings.company_owner_en">
                </div>
                <button [ngClass]="{ 'disabled':!ReturnDidCompanySettingsChange() }" (click)="SaveSettings()" type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">{{ languageService.ReturnTranslatedLabel('action_save') }}</button>
            </div>

            <div class="p-8 w-full" class="p-8 w-full" *ngIf="tab == 2 && subtab == 1">
                <div class="mb-6">
                    <label for="invoice_num" class="block mb-2 text-sm font-medium text-gray-900 ">{{ languageService.ReturnTranslatedLabel('label_start_invoice_num') }}</label>
                    <input type="text" id="invoice_num" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " [(ngModel)]="company_settings.invoice_num">
                    <p class="text-red text-xs italic">{{ languageService.ReturnTranslatedLabel('label_start_invoice_num_hint') }}</p>
                </div>
                <button [ngClass]="{ 'disabled':!ReturnDidCompanySettingsChange() }" (click)="SaveSettings()" type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">{{ languageService.ReturnTranslatedLabel('action_save') }}</button>
            </div>

                    
            <div class="p-8 w-full" class="p-8 w-full" *ngIf="tab == 2 && subtab == 2">
                <table class="min-w-full text-left text-sm font-light">
                    <thead class="border-b font-medium ">
                        <tr>
                        <th scope="col" class="px-6 py-4">#</th>
                        <th scope="col" class="px-6 py-4">{{ languageService.ReturnTranslatedLabel('label_vat') }} (%)</th>
                        <th scope="col" class="px-6 py-4">{{ languageService.ReturnTranslatedLabel('label_description') }}</th>
                        <th scope="col" class="px-6 py-4"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="border-b " *ngFor="let vat of system.ReturnVats();let idx = index">
                            <td class="whitespace-nowrap px-6 py-4 font-medium">{{ idx + 1 }}</td>
                            <td class="whitespace-nowrap px-6 py-4">{{ vat.vat }}%</td>
                            <td class="whitespace-nowrap px-6 py-4">{{ vat.description }}</td>
                            <td class="whitespace-nowrap px-6 py-4">
                                <svg xmlns="http://www.w3.org/2000/svg" *ngIf="system.ReturnVats().length > 1" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-red hover:text-black" (click)="openModal('remove-vat', vat)">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>                          
                            </td>
                        </tr>
                        <tr *ngIf="system.ReturnVats().length == 0">
                            <td class="whitespace-nowrap px-6 py-4 text-center" colspan="4">Няма въведени ДДС ставки</td>
                        </tr>
                    </tbody>
                </table>
                <button (click)="openModal('add-vat', { 'vat':'', 'description':'' })" type="submit" class="text-white mt-4 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">{{ languageService.ReturnTranslatedLabel('label_add_vat') }}</button>
            </div>

            <div class="p-8 w-full" *ngIf="tab == 3 && subtab == 1">
                <div class="mb-6">
                    <label for="company_name" class="block mb-2 text-sm font-medium text-gray-900 ">{{ languageService.ReturnTranslatedLabel('label_language') }}</label>
                    <select class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " [(ngModel)]="company_settings.language">
                        <option value="BG">{{ languageService.ReturnTranslatedLabel('label_language_value_bulgarian') }}</option>
                        <option value="EN">{{ languageService.ReturnTranslatedLabel('label_language_value_english') }}</option>
                    </select>
                </div>
                <!-- <div class="mb-6">
                    <label for="company_name" class="block mb-2 text-sm font-medium text-gray-900 ">Националност</label>
                    <select class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " [(ngModel)]="me.nationality">
                        <option value="America">America</option>
                        <option value="EU">EU</option>
                    </select>
                </div> -->
                <div class="mb-6">
                    <label for="company_bulstat" class="block mb-2 text-sm font-medium text-gray-900 ">{{ languageService.ReturnTranslatedLabel('label_currency') }}</label>
                    <select class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " [(ngModel)]="company_settings.currency">
                        <option *ngFor="let currency of system.ReturnAvailableCurrencies()" value="{{ currency.name }}">{{ currency.label }} ({{ currency.name }})</option>
                    </select>
                </div>
                <button [ngClass]="{ 'disabled':!ReturnDidCompanySettingsChange() }" (click)="SaveSettings()" type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">{{ languageService.ReturnTranslatedLabel('action_save') }}</button>
            </div>

            <div class="p-8 w-full" *ngIf="tab == 4 && subtab == 1">
                <div class="flex items-center bg-blue-500 text-white text-sm font-semibold px-4 py-3 mb-4 mt-4" role="alert" *ngIf="system.ReturnBanks().length == 0">
                    <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"/></svg>
                    <p>Ако нямате въведена поне една банкова сметка, няма да може да издавате фактура по банка!</p>
                </div>
    
                <table class="min-w-full text-left text-sm font-light">
                    <thead class="border-b font-medium ">
                        <tr>
                        <th scope="col" class="px-6 py-4">#</th>
                        <th scope="col" class="px-6 py-4">Име на шаблон</th>
                        <th scope="col" class="px-6 py-4">Банка</th>
                        <th scope="col" class="px-6 py-4">BIC</th>
                        <th scope="col" class="px-6 py-4">IBAN</th>
                        <th scope="col" class="px-6 py-4"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="border-b " *ngFor="let bank of system.ReturnBanks();let idx = index">
                            <td class="whitespace-nowrap px-6 py-4 font-medium">{{ idx + 1 }}</td>
                            <td class="whitespace-nowrap px-6 py-4">{{ bank.name }}</td>
                            <td class="whitespace-nowrap px-6 py-4">{{ bank.bank }}</td>
                            <td class="whitespace-nowrap px-6 py-4">{{ bank.bic }}</td>
                            <td class="whitespace-nowrap px-6 py-4">{{ bank.iban }}</td>
                            <td class="whitespace-nowrap px-6 py-4">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-red hover:text-black" (click)="openModal('remove-bank', bank)">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>                          
                            </td>
                        </tr>
                        <tr *ngIf="system.ReturnBanks().length == 0">
                            <td class="whitespace-nowrap px-6 py-4 text-center" colspan="6">Няма въведени банкови сметки</td>
                        </tr>
                    </tbody>
                </table>
                <button (click)="openModal('add-bank', { 'name':'', 'bank':'', 'bic':'', 'iban':'' })" type="submit" class="text-white mt-4 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Добавяне на сметка</button>
            </div>

            <div class="p-8 w-full" *ngIf="tab == 5 && subtab == 1">
                <span  class="flex w-full mt-8 font-semibold mb-4">Валути</span>
    
                <table class="min-w-full text-left text-sm font-light">
                    <thead class="border-b font-medium ">
                        <tr>
                        <th scope="col" class="px-6 py-4">#</th>
                        <th scope="col" class="px-6 py-4">Наименование</th>
                        <th scope="col" class="px-6 py-4">Описание</th>
                        <th scope="col" class="px-6 py-4">Символ</th>
                        <th scope="col" class="px-6 py-4">Позиция</th>
                        <th scope="col" class="px-6 py-4">Разстояние между стойност</th>
                        <th scope="col" class="px-6 py-4"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="border-b  font-medium" *ngFor="let currency of system.ReturnAvailableCurrencies();let idx = index">
                            <td class="whitespace-nowrap px-6 py-4 font-medium">{{ idx + 1 }}</td>
                            <td class="whitespace-nowrap px-6 py-4">{{ currency.name }}</td>
                            <td class="whitespace-nowrap px-6 py-4">{{ currency.description }}</td>
                            <td class="whitespace-nowrap px-6 py-4">{{ currency.label }}</td>
                            <td class="whitespace-nowrap px-6 py-4">
                                <span *ngIf="currency.position == 'front'">Преди цената</span>
                                <span *ngIf="currency.position == 'back'">След цената</span>
                            </td>
                            <td class="whitespace-nowrap px-6 py-4">
                                <span *ngIf="currency.spacing == '1'">Да</span>
                                <span *ngIf="currency.spacing == '0'">Не</span>
                            </td>
                            <td class="whitespace-nowrap px-6 py-4">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-red hover:text-black" (click)="openModal('remove-currency', currency)">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>                          
                            </td>
                        </tr>
                        <tr *ngIf="system.ReturnAvailableCurrencies().length == 0">
                            <td class="whitespace-nowrap px-6 py-4 text-center" colspan="7">Няма въведени валути</td>
                        </tr>
                    </tbody>
                </table>
                <button (click)="openModal('add-currency', { 'name':'', 'description':'', 'symbol':'', 'position':'front', 'spacing':'0' })" type="submit" class="text-white mt-4 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Добави валута</button>
            
            </div>
    
            <div class="p-8 w-full" *ngIf="tab == 5 && subtab == 2">
                <span class="flex w-full mt-8 font-semibold mb-4">Мерни единици</span>
    
                <table class="min-w-full text-left text-sm font-light">
                    <thead class="border-b font-medium ">
                        <tr>
                        <th scope="col" class="px-6 py-4">#</th>
                        <th scope="col" class="px-6 py-4">Наименование</th>
                        <th scope="col" class="px-6 py-4">Описание</th>
                        <th scope="col" class="px-6 py-4"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="border-b  font-medium" *ngFor="let unit of system.ReturnAvailableUnits();let idx = index">
                            <td class="whitespace-nowrap px-6 py-4 font-medium">{{ idx + 1 }}</td>
                            <td class="whitespace-nowrap px-6 py-4">{{ unit.name }}</td>
                            <td class="whitespace-nowrap px-6 py-4">{{ unit.description }}</td>
                            <td class="whitespace-nowrap px-6 py-4">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-red hover:text-black" (click)="openModal('remove-metric', unit)">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>                          
                            </td>
                        </tr>
                        <tr *ngIf="system.ReturnAvailableUnits().length == 0">
                            <td class="whitespace-nowrap px-6 py-4 text-center" colspan="4">Няма въведени мерни единици</td>
                        </tr>
                    </tbody>
                </table>
                <button (click)="openModal('add-metric', { 'name':'', 'description':'' })" type="submit" class="text-white mt-4 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Добави мерна единица</button>
            </div>

            <div class="p-8 w-full" *ngIf="tab == 6 && subtab == 1">
                <div class="mb-6 flex flex-col">
                    <label for="invoice_num" class="block mb-2 text-sm font-medium text-gray-900 w-full">Шаблон за фактура</label>
                    <div class="flex flex-row w-full">
                        <div class="template-selector" [ngClass]="{ active:company_settings.template == '1' }">
                            <div class="view-btn" (click)="openModal('view-template', { 'template':'templates/invoices/1.jpg' })">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" style="margin-top:2px;margin-left:2px;" class="w-5 h-5">
                                    <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                                    <path fill-rule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z" clip-rule="evenodd" />
                                </svg>                          
                            </div>
                            <div class="img img1" (click)="company_settings.template = '1'"></div>
                            <span class="name" (click)="company_settings.template = '1'">Шаблон 1</span>
                        </div>
                        <div class="template-selector ml-2" [ngClass]="{ active:company_settings.template == '2' }">
                            <div class="view-btn" (click)="openModal('view-template', { 'template':'templates/invoices/2.jpg' })">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"  style="margin-top:2px;margin-left:2px;" class="w-5 h-5">
                                    <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                                    <path fill-rule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z" clip-rule="evenodd" />
                                </svg>                          
                            </div>
                            <div class="img img2" (click)="company_settings.template = '2'"></div>
                            <span class="name" (click)="company_settings.template = '2'">Шаблон 2</span>
                        </div>
                        <div class="template-selector ml-2" [ngClass]="{ active:company_settings.template == '3' }">
                            <div class="view-btn" (click)="openModal('view-template', { 'template':'templates/invoices/3.jpg' })">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"  style="margin-top:2px;margin-left:2px;" class="w-5 h-5">
                                    <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                                    <path fill-rule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z" clip-rule="evenodd" />
                                </svg>                          
                            </div>
                            <div class="img img3" (click)="company_settings.template = '3'"></div>
                            <span class="name" (click)="company_settings.template = '3'">Шаблон 3</span>
                        </div>
                    </div>
                </div>
                <div class="mb-6">
                    <label for="primary_color" class="block mb-2 text-sm font-medium text-gray-900 ">Основен цвят</label>
                    <input  [(colorPicker)]="company_settings.primary_color" [style.background]="company_settings.primary_color" type="text" id="primary_color" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ">
                    <p class="text-red text-xs italic">Това е основния цвят, който се използва във фактурата</p>
                </div>
                <div class="mb-6">
                    <label for="accent_color" class="block mb-2 text-sm font-medium text-gray-900 ">Вторичен цвят</label>
                    <input  [(colorPicker)]="company_settings.accent_color" [style.background]="company_settings.accent_color" type="text" id="accent_color" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ">
                    <p class="text-red text-xs italic">Този цвят се постава на по-малко важните компоненти на фактурата</p>
                </div>
                <button [ngClass]="{ 'disabled':!ReturnDidCompanySettingsChange() }" (click)="SaveSettings()" type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">{{ languageService.ReturnTranslatedLabel('action_save') }}</button>
            </div>

            <div class="p-8 w-full" *ngIf="tab == 7 && subtab == 1">
                <div class="mb-6 flex flex-col">
                    <label for="primary_color" class="flex w-full mt-8 font-semibold mb-4">Загливие на "Е-мейл фактура"</label>
                    <input  [(ngModel)]="templates.title_invoices" type="text" id="primary_color" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ">
                </div>
                <span  class="flex w-full mt-8 font-semibold mb-4">Шаблон "Е-мейл фактура"</span>
                <angular-editor [(ngModel)]="templates.invoices"></angular-editor>
    
                <button (click)="system.SetTemplates(templates)" type="submit" class="text-white mt-4 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Запис на шаблони</button>
            </div>

            <div class="p-8 w-full" *ngIf="tab == 7 && subtab == 2">
                <div class="mb-6 flex flex-col">
                    <label for="primary_color" class="flex w-full mt-8 font-semibold mb-4">Загливие на "Е-мейл оферти"</label>
                    <input  [(ngModel)]="templates.title_offers" type="text" id="primary_color" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ">
                </div>
                <span  class="flex w-full mt-8 font-semibold mb-4">Шаблон "Е-мейл оферта"</span>
                <angular-editor [(ngModel)]="templates.offers"></angular-editor>
    
                <button (click)="system.SetTemplates(templates)" type="submit" class="text-white mt-4 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Запис на шаблони</button>
            </div>

            <div class="p-8 w-full" *ngIf="tab == 7 && subtab == 3">
                <div class="mb-6 flex flex-col">
                    <label for="primary_color" class="flex w-full mt-8 font-semibold mb-4">Загливие на "Е-мейл поръчки"</label>
                    <input  [(ngModel)]="templates.title_orders" type="text" id="primary_color" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ">
                </div>
                <span  class="flex w-full mt-8 font-semibold mb-4">Шаблон "Е-мейл поръчки"</span>
                <angular-editor [(ngModel)]="templates.orders"></angular-editor>
    
                <button (click)="system.SetTemplates(templates)" type="submit" class="text-white mt-4 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Запис на шаблони</button>
            </div>

            <div class="p-8 w-full" *ngIf="tab == 8">
                <div class="user-select"></div>
                <span class="user-select-text">Моля изберете потребител от списъка</span>
            </div>

            <div class="w-full bg-white p-6" *ngIf="tab == 9 && currentIntegration.name != undefined">
                <div class="flex items-center w-1/5 cursor-pointer mb-4 text-gray-500 group" (click)="currentIntegration = {}">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2 group-hover:text-indigo-600">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                    </svg>                      
                    <span class="group-hover:text-indigo-600">Обратно към списък</span>
                </div>
                <div class="bg-white shadow rounded-lg p-6">
                    <div class="flex items-center justify-between mb-4">
                        <div class="flex items-center">
                            <div class="w-12 h-12 mr-4 {{ currentIntegration.class_logo }}"></div> <!-- Black circle placeholder -->
                            <h3 class="text-xl font-semibold">{{ currentIntegration.name }}</h3>
                        </div>
                        <!-- Slider toggle -->
                        <div class="flex items-center">
                            <span class="mr-2 text-gray-700">Активна</span>
                            <input type="checkbox" id="toggle" [(ngModel)]="ReturnCurrentIntegrationData().data.enabled" class="toggle-checkbox hidden">
                            <label for="toggle" class="toggle-label block w-10 h-6 rounded-full bg-gray-300 cursor-pointer relative">
                                <div class="absolute left-0 top-0 bottom-0 w-4 h-4 bg-white rounded-full transition-transform transform"></div>
                            </label>
                        </div>
                    </div>
                    <p class="text-gray-600 mb-6">{{ currentIntegration.description }}</p>

                    <!-- OPENCART WINDOW -->
    
                    <div class="border-b border-gray-200 mb-6" *ngIf="ReturnOpenCartIntegrations().data.enabled">
                        <nav class="-mb-px flex space-x-8">
                            <span class="hover:text-gray-700 whitespace-nowrap py-4 px-2 border-b-2 font-medium text-sm cursor-pointer" 
                            *ngFor="let tab of currentIntegration.tabs"
                            [ngClass]="{ 'text-indigo-600':currentIntegration.tab == tab.idx , 'border-indigo-500':currentIntegration.tab == tab.idx, 'text-gray-500':currentIntegration.tab != tab.idx , 'border-transparent':currentIntegration.tab != tab.idx }" 
                            (click)="currentIntegration.tab = tab.idx">{{ tab.name }}</span>
                        </nav>
                    </div>
                    
                    <div *ngIf="ReturnCurrentIntegrationData().data.enabled && currentIntegration.name == 'OpenCart'">
                        <div class="mb-6" *ngIf="ReturnOpenCartIntegrations().data.enabled && ReturnOpenCartIntegrations().data.domain_url == undefined && !ReturnOpenCartIntegrations().data.connecting || ReturnOpenCartIntegrations().data.enabled && ReturnOpenCartIntegrations().data.domain_url == '' && !ReturnOpenCartIntegrations().data.connecting">
                            <label for="url_api" class="block mb-2 text-sm font-medium text-gray-900 ">Домайн на онлайн магазин <span class="text-red-500">*</span></label>
                            <input type="text" id="url_api" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " [(ngModel)]="import_settings.import_url">
                        </div>

                        <button (click)="ConnectToUrl()" *ngIf="ReturnOpenCartIntegrations().data.enabled && ReturnOpenCartIntegrations().data.domain_url == undefined && !ReturnOpenCartIntegrations().data.connecting || ReturnOpenCartIntegrations().data.enabled && ReturnOpenCartIntegrations().data.domain_url == '' && !ReturnOpenCartIntegrations().data.connecting" type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Свъзване</button>

                        <div class="flex flex-col items-center space-y-2"  *ngIf="ReturnOpenCartIntegrations().data.connecting && !ReturnOpenCartIntegrations().data.connected">
                            <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
                            <p class="text-gray-700 text-lg font-medium">Свързване...</p>
                        </div>

                        <div class="grid grid-cols-1 md:grid-cols-1  gap-4" *ngIf="ReturnOpenCartIntegrations().data.connected && currentIntegration.tab == 1">
                            <div class="bg-gray-50 p-4 rounded-lg shadow-sm">
                                <div class="mb-2">
                                    <span class="text-sm font-medium text-gray-500">Статус</span>
                                    <span class="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-400/60 text-green-600">Свързан</span>
                                </div>
                                <div class="mb-2">
                                    <span class="text-sm font-medium text-gray-500">Домейн</span>
                                    <span class="ml-2 text-gray-900">{{ ReturnOpenCartIntegrations().data.domain_url }}</span>
                                </div>
                                <div class="mb-2">
                                    <span class="text-sm font-medium text-gray-500">Версия</span>
                                    <span class="ml-2 text-gray-900">{{ ReturnOpenCartIntegrations().data.version }}</span>
                                </div>
                                <div class="mb-2">
                                    <span class="text-sm font-medium text-gray-500">Продукти</span>
                                    <span class="ml-2 text-gray-900">{{ ReturnOpenCartIntegrations().data.product_count }}</span>
                                </div>
                                <div class="mb-2">
                                    <span class="text-sm font-medium text-gray-500">Категории</span>
                                    <span class="ml-2 text-gray-900">{{ ReturnOpenCartIntegrations().data.categories_count }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="grid grid-cols-1 md:grid-cols-1  gap-4" *ngIf="ReturnOpenCartIntegrations().data.connected && currentIntegration.tab == 2">
                            <div class="bg-gray-50 p-4 rounded-lg shadow-sm" *ngIf="import_settings.step == 1">
                                <div class="mb-6">
                                    <label for="url_api" class="block mb-2 text-sm font-medium text-gray-900 ">Данни за импортиране</label>
                                    <div class="mb-2 w-2/4 flex flex-col items-center">
                                        <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">Склад <span class="text-red-500">*</span></label>
                                        <select [(ngModel)]="import_settings.storehouse_id" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full">
                                            <option value="-1" disabled>Няма избран склад</option>
                                            <option *ngFor="let storehouse of system.ReturnStorehouses()" value="{{ storehouse.uid }}">{{ storehouse.name }}</option>
                                        </select>  
                                    </div>
                                    <div class="space-y-2">
                                      <label class="flex items-center space-x-3">
                                        <input [(ngModel)]="import_settings.import_options[0]" type="checkbox" class="form-checkbox h-5 w-5 text-blue-600">
                                        <span class="text-gray-700">Производители</span>
                                      </label>
                                      <label class="flex items-center space-x-3">
                                        <input [(ngModel)]="import_settings.import_options[1]" type="checkbox" class="form-checkbox h-5 w-5 text-blue-600">
                                        <span class="text-gray-700">Продуктови категории</span>
                                      </label>
                                      <label class="flex items-center space-x-3">
                                        <input [(ngModel)]="import_settings.import_options[2]" type="checkbox" class="form-checkbox h-5 w-5 text-blue-600">
                                        <span class="text-gray-700">Продукти</span>
                                      </label>
                                    </div>
                                </div>
                                <button (click)="ImportFromURL()" type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Импортиране</button>
                            </div>
                            
                            <div class="bg-gray-50 p-4 rounded-lg shadow-sm" *ngIf="import_settings.step == 2">
                                <div class="border-b border-gray-200 mb-4" style="position: relative;">
                                    <ul class="flex">
                                      <li class="mr-1 cursor-pointer" *ngIf="import_settings.import_options[0]">
                                        <span class="bg-white inline-block py-2 px-4 hover:text-indigo-600 font-semibold border-b-2" (click)="import_settings.selected_category = 0" [ngClass]="{ 'border-indigo-500':import_settings.selected_category == 0, 'text-indigo-500':import_settings.selected_category == 0, 'text-gray-500':import_settings.selected_category != 0, 'border-transparent':import_settings.selected_category != 0 }">Производители</span>
                                      </li>
                                      <li class="mr-1 cursor-pointer" *ngIf="import_settings.import_options[1]">
                                        <span class="bg-white inline-block py-2 px-4 hover:text-indigo-600 font-semibold border-b-2" (click)="import_settings.selected_category = 1" [ngClass]="{ 'border-indigo-500':import_settings.selected_category == 1, 'text-indigo-500':import_settings.selected_category == 1, 'text-gray-500':import_settings.selected_category != 1, 'border-transparent':import_settings.selected_category != 1  }">Продуктови категории</span>
                                      </li>
                                      <li class="mr-1 cursor-pointer" *ngIf="import_settings.import_options[2]">
                                        <span class="bg-white inline-block py-2 px-4 hover:text-indigo-600 font-semibold border-b-2" (click)="import_settings.selected_category = 2" [ngClass]="{ 'border-indigo-500':import_settings.selected_category == 2, 'text-indigo-500':import_settings.selected_category == 2, 'text-gray-500':import_settings.selected_category != 2, 'border-transparent':import_settings.selected_category != 2  }">Продукти</span>
                                      </li>
                                      <li class="import-btn" (click)="StartImportAction()">
                                        Импортирай данни
                                      </li>
                                    </ul>
                                </div>                

                                <table class="min-w-full bg-white border border-gray-200" *ngIf="import_settings.selected_category == 1">
                                    <thead>
                                        <tr>
                                        <th class="px-4 py-2 border-b text-xs text-left">Снимка</th>
                                        <th class="px-4 py-2 border-b text-xs text-left">Категория</th>
                                        <th class="px-4 py-2 border-b text-xs text-left">Основна категория</th>
                                        <th class="px-4 py-2 border-b text-xs text-left">Състояние</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let category of import_settings.categories">
                                        <td class="px-4 py-2 border-b text-xs text-left">
                                            <img *ngIf="category.image != ''" src="{{ this.ReturnOpenCartIntegrations().data.domain_url }}image/{{ category.image }}" alt="Product 1" class="w-12 h-12 object-contain rounded">
                                            <div *ngIf="category.image == ''" class="w-12 h-12 bg-gray-300 rounded-lg flex items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 ml-3">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
                                                </svg>   
                                            </div>
                                        </td>
                                        <td class="px-4 py-2 border-b text-xs text-left">{{ category.name }}</td>
                                        <td class="px-4 py-2 border-b text-xs text-left">{{ ReturnCategoryName(category.parent_id) }}</td>
                                        <td class="px-4 py-2 border-b text-xs text-left">
                                            <div class="bg-green-100 bg-opacity-50 text-green-600 font-semibold py-1 px-3 rounded-full text-center" *ngIf="category.state == 'NEW'">НОВ</div>
                                            <div class="bg-blue-100 bg-opacity-50 text-blue-600 font-semibold py-1 px-3 rounded-full text-center" *ngIf="category.state == 'OLD'">СЪЩЕСТВУВА</div>
                                        </td>
                                        </tr>
                                        <!-- Repeat similar blocks for more products -->
                                    </tbody>
                                </table>
                
                                <table class="min-w-full bg-white border border-gray-200" *ngIf="import_settings.selected_category == 2">
                                    <thead>
                                        <tr>
                                        <th class="px-4 py-2 border-b text-xs text-left">Снимка</th>
                                        <th class="px-4 py-2 border-b text-xs text-left">Сериен номер</th>
                                        <th class="px-4 py-2 border-b text-xs text-left">Име на продукт</th>
                                        <th class="px-4 py-2 border-b text-xs text-left">Категория</th>
                                        <th class="px-4 py-2 border-b text-xs text-left">Цена</th>
                                        <th class="px-4 py-2 border-b text-xs text-left">Състояние</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let product of import_settings.products">
                                        <td class="px-4 py-2 border-b text-xs text-left">
                                            <img *ngIf="product.image != '' && product.image !== undefined" src="{{ this.ReturnOpenCartIntegrations().data.domain_url }}image/{{ product.image }}" alt="Product 1" class="w-12 h-12 object-contain rounded">
                                            <div *ngIf="product.image == '' || product.image === undefined" class="w-12 h-12 bg-gray-300 rounded-lg flex items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 ml-3">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
                                                </svg>                                                  
                                            </div>
                                        </td>
                                        <td class="px-4 py-2 border-b text-xs text-left">{{ product.model }}</td>
                                        <td class="px-4 py-2 border-b text-xs text-left">{{ product.name }}</td>
                                        <td class="px-4 py-2 border-b text-xs text-left">{{  ReturnCategoryName(product.categories[0]) }}</td>
                                        <td class="px-4 py-2 border-b text-xs text-left">{{ product.price }} лв.</td>
                                        <td class="px-4 py-2 border-b text-xs text-left">
                                            <div class="bg-green-100 bg-opacity-50 text-green-600 font-semibold py-1 px-3 rounded-full text-center" *ngIf="product.state == 'NEW'">НОВ</div>
                                            <div class="bg-blue-100 bg-opacity-50 text-blue-600 font-semibold py-1 px-3 rounded-full text-center" *ngIf="product.state == 'OLD'">СЪЩЕСТВУВА</div>
                                        </td>
                                        </tr>
                                        <!-- Repeat similar blocks for more products -->
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="grid grid-cols-1 md:grid-cols-1  gap-4" *ngIf="ReturnOpenCartIntegrations().data.connected && currentIntegration.tab == 3">
                            <div class="bg-gray-50 p-4 rounded-lg shadow-sm">
                                <div class="mb-6">
                                    <div class="space-y-2">
                                      <label class="flex items-center space-x-3">
                                        <input [(ngModel)]="ReturnOpenCartIntegrations().data.sold_out" type="checkbox" class="form-checkbox h-5 w-5 text-blue-600">
                                        <span class="text-gray-700">Забраняване на продукти след изчервпане на количество</span>
                                      </label>
                                    </div>
                                </div>
                                <button (click)="AddOpenCartIntegration" type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Запис</button>
                            </div>
                        </div>
                    </div>

                    <!-- OPENCART WINDOW ENDS -->
                     <!-- ECONT WINDOW -->
                    <div *ngIf="ReturnCurrentIntegrationData().data.enabled && currentIntegration.name == 'ECONT'">
                        <div class="mb-6" *ngIf="ReturnCurrentIntegrationData().data.enabled && !ReturnCurrentIntegrationData().data.connected && !ReturnCurrentIntegrationData().data.connecting">
                            <label for="username" class="block mb-2 text-sm font-medium text-gray-900 ">Потребител <span class="text-red-500">*</span></label>
                            <input type="text" id="username" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " [(ngModel)]="ReturnCurrentIntegrationData().data.username">
                        </div>

                        <div class="mb-6" *ngIf="ReturnCurrentIntegrationData().data.enabled && !ReturnCurrentIntegrationData().data.connected && !ReturnCurrentIntegrationData().data.connecting">
                            <label for="password" class="block mb-2 text-sm font-medium text-gray-900 ">Парола <span class="text-red-500">*</span></label>
                            <input type="text" id="password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " [(ngModel)]="ReturnCurrentIntegrationData().data.password">
                        </div>

                        <button (click)="ConnectToEcont()" *ngIf="ReturnCurrentIntegrationData().data.enabled && !ReturnCurrentIntegrationData().data.connected && !ReturnCurrentIntegrationData().data.connecting" type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Свъзване</button>

                        <div class="flex flex-col items-center space-y-2"  *ngIf="ReturnCurrentIntegrationData().data.connecting && !ReturnCurrentIntegrationData().data.connected">
                            <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
                            <p class="text-gray-700 text-lg font-medium">Свързване...</p>
                        </div>

                        <div class="grid grid-cols-1 md:grid-cols-1  gap-4" *ngIf="ReturnCurrentIntegrationData().data.connected">
                            <div class="bg-gray-50 p-4 rounded-lg shadow-sm">
                                <div class="mb-2">
                                    <span class="text-sm font-medium text-gray-500">Статус</span>
                                    <span class="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-400/60 text-green-600">Свързан</span>
                                </div>
                                <div class="mb-2">
                                    <span class="text-sm font-medium text-gray-500">Потребител</span>
                                    <span class="ml-2 text-gray-900">{{ ReturnCurrentIntegrationData().data.username }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- ECONT WINDOW ENDS -->
                     <!-- WORDPRESS WINDOW -->
                     <div *ngIf="ReturnCurrentIntegrationData().data.enabled && currentIntegration.name == 'WordPress'">
                        <div class="mb-6" *ngIf="ReturnWordPressIntegrations().data.enabled && ReturnWordPressIntegrations().data.domain_url == undefined && !ReturnWordPressIntegrations().data.connecting || ReturnWordPressIntegrations().data.enabled && ReturnWordPressIntegrations().data.domain_url == '' && !ReturnWordPressIntegrations().data.connecting">
                            <label for="url_api" class="block mb-2 text-sm font-medium text-gray-900 ">Домайн на онлайн магазин <span class="text-red-500">*</span></label>
                            <input type="text" id="url_api" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " [(ngModel)]="import_settings.import_url">
                        </div>

                        <button (click)="ConnectToUrl()" *ngIf="ReturnWordPressIntegrations().data.enabled && ReturnWordPressIntegrations().data.domain_url == undefined && !ReturnWordPressIntegrations().data.connecting || ReturnWordPressIntegrations().data.enabled && ReturnWordPressIntegrations().data.domain_url == '' && !ReturnWordPressIntegrations().data.connecting" type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Свръзване</button>

                        <div class="flex flex-col items-center space-y-2"  *ngIf="ReturnWordPressIntegrations().data.connecting && !ReturnWordPressIntegrations().data.connected">
                            <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
                            <p class="text-gray-700 text-lg font-medium">Свързване...</p>
                        </div>
                    </div>

                    <!-- WORDPRESS WINDOW ENDS-->
                     <!-- PrestaShop WINDOW-->
                        <div *ngIf="ReturnCurrentIntegrationData().data.enabled && currentIntegration.name == 'PrestaShop'">
                            <div class="mb-6" *ngIf="ReturnPrestaShopIntegrations().data.enabled && ReturnPrestaShopIntegrations().data.domain_url == undefined && !ReturnPrestaShopIntegrations().data.connecting || ReturnPrestaShopIntegrations().data.enabled && ReturnPrestaShopIntegrations().data.domain_url == '' && !ReturnPrestaShopIntegrations().data.connecting">
                                <label for="url_api" class="block mb-2 text-sm font-medium text-gray-900 ">Домайн на онлайн магазин <span class="text-red-500">*</span></label>
                                <input type="text" id="url_api" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " [(ngModel)]="import_settings.import_url">
                            </div>

                            <button (click)="ConnectToUrl()" *ngIf="ReturnPrestaShopIntegrations().data.enabled && ReturnPrestaShopIntegrations().data.domain_url == undefined && !ReturnPrestaShopIntegrations().data.connecting || ReturnPrestaShopIntegrations().data.enabled && ReturnPrestaShopIntegrations().data.domain_url == '' && !ReturnPrestaShopIntegrations().data.connecting" type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Свръзване</button>

                            <div class="flex flex-col items-center space-y-2"  *ngIf="ReturnPrestaShopIntegrations().data.connecting && !ReturnWordPressIntegrations().data.connected">
                                <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
                                <p class="text-gray-700 text-lg font-medium">Свързване...</p>
                            </div>
                        </div>

                     <!-- PrestaShop WINDOW ENDS-->
                </div>
            </div>

            <!-- Main content -->
            <div class="w-full bg-gray-50 p-6" *ngIf="tab == 9 && currentIntegration.name == undefined">
                <div class="flex justify-between items-center mb-6">
                    <h2 class="text-2xl font-bold">Интеграции</h2>
                </div>

                <div class="mb-4">
                    <nav class="flex space-x-4">
                        <span class="py-2 px-2 rounded-lg text-sm font-medium cursor-pointer" (click)="filter_integrations = 'all'" [ngClass]="{ 'bg-indigo-600':filter_integrations == 'all', 'text-white':filter_integrations == 'all', 'text-gray-500':filter_integrations != 'all', 'hover:text-gray-700':filter_integrations != 'all' , 'hover:border-gray-300':filter_integrations != 'all' }">Всички интеграции</span>
                        <span class="py-2 px-1 rounded-lg text-sm font-medium cursor-pointer" (click)="filter_integrations = 'online-store'" [ngClass]="{ 'bg-indigo-600':filter_integrations == 'online-store', 'text-white':filter_integrations == 'online-store', 'text-gray-500':filter_integrations != 'online-store', 'hover:text-gray-700':filter_integrations != 'online_store' , 'hover:border-gray-300':filter_integrations != 'online-store' }">Онлайн магазини</span>
                        <span class="py-2 px-1 rounded-lg text-sm font-medium cursor-pointer" (click)="filter_integrations = 'shipment'" [ngClass]="{ 'bg-indigo-600':filter_integrations == 'shipment', 'text-white':filter_integrations == 'shipment', 'text-gray-500':filter_integrations != 'shipment', 'hover:text-gray-700':filter_integrations != 'shipment' , 'hover:border-gray-300':filter_integrations != 'shipment' }">Доставки</span>
                    </nav>
                </div>

                <div class="bg-white p-4 shadow rounded-lg">

                    <div>
                        <div class="grid grid-cols-1 gap-4">
                            <!-- Integration Item -->
                            <div class="flex items-center justify-between p-4 bg-gray-50 border border-gray-200 rounded-lg" *ngFor="let available_integration of ReturnFilteredIntegrations()">
                                <div class="flex items-center">
                                    <div class="w-12 h-12 mr-4 {{ available_integration.class_logo }}"></div> <!-- Black circle placeholder -->
                                    <div>
                                        <h4 class="font-medium text-gray-900">{{ available_integration.name }}</h4>
                                        <p class="text-gray-500 text-sm">{{ available_integration.description }}</p>
                                    </div>
                                </div>
                                <button class="bg-indigo-600 hover:bg-indigo-500 text-white px-4 py-2 rounded-full text-sm" (click)="SelectIntegration(available_integration)" *ngIf="available_integration.available">Настройка</button>
                                <button class="bg-indigo-600 hover:bg-indigo-500 text-white px-4 py-2 rounded-full text-sm disabled" (click)="SelectIntegration(available_integration)" *ngIf="!available_integration.available">Идва скоро!</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>