<!-- Modal -->
<div *ngIf="modal.open"  style=" background-color: rgba(0, 0, 0, 0.8)" class="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" x-show.transition.opacity="openModal">
    <div class="p-4 max-w-xl mx-auto relative absolute left-0 right-0 overflow-hidden mt-24"  *ngIf="modal.cntx == 'remove-order'">
        <div class="shadow absolute right-0 top-0 w-10 h-10 rounded-full bg-white text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer"
            (click)="modal.open = false">
            <svg class="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                    d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
            </svg>
        </div>

        <div class="shadow w-full rounded-lg bg-white overflow-hidden w-full block p-8">
            
            <h2 class="font-bold text-2xl mb-6 text-gray-800 border-b pb-2">Премахване на поръчка</h2>

            <div class="flex">
                <span>Сигурни ли сте, че искате да премахнете поръчката ?</span>
            </div>

            <div class="mt-8 text-right">
                <button type="button" (click)="modal.open = false" class="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded shadow-sm mr-2">
                    Не
                </button>	
                <button (click)="RemoveOrder()" type="button" class="bg-gray-800 hover:bg-gray-700 text-white font-semibold py-2 px-4 border border-gray-700 rounded shadow-sm">
                    Да
                </button>	
            </div>
        </div>
    </div>

    <div class="modal-xl"  *ngIf="modal.cntx == 'add-order'">
        <div class="modal-left-holder" [ngClass]="{ 'full':modal.obj.shipping_method == 'standard' }">
            <span class="modal-title">Добавяне на поръчка</span>
            <div class="absolute w-12 h-12 bg-gray-400 cursor-pointer hover:bg-positive-hover rounded-md" (click)="closeModal()" style="right:0.7rem;top:0.3rem;color:white;">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 ml-2 mt-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>                      
            </div>

            <div class="flex justify-between w-full">
                <div class="flex flex-col bg-white p-4 " style="width:25%">
                    <span class="block uppercase tracking-wide text-grey-darker text-xs font-bold">Номер на поръчка</span>
                    <input [(ngModel)]="modal.obj.order_id"  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mt-4 focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" readonly type="text" placeholder="" x-model="invoiceNumber">
                </div>
                <div class="flex flex-col bg-white p-4 ml-2 " style="width:25%">
                    <div class="flex flex-col w-full">
                        <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold">Дата на поръчка</label>
                        <input  [(ngModel)]="modal.obj.order_date"  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mt-4 focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" readonly type="text" placeholder="" x-model="invoiceNumber">
                    </div>
                </div>
                <div class="flex flex-col bg-white p-4 ml-2 " style="width:50%">
                    <div class="flex flex-row w-full">
                        <div class="flex flex-col w-full mr-2 relative">
                            <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold">Име на клиент</label>
                            <input (keyup)="RetrieveClientForOrder('name')" (focus)="focusClientName()" (focusout)="clearFocusClientName()" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mt-4 focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" [(ngModel)]="modal.obj.client_name" type="text" placeholder="Въведе име на клиент" x-model="invoiceNumber">
                            <div class="auto-fill-dropdown" *ngIf="modal.obj.client_select">
                                <span class="auto-fill-dropdown-item client" *ngFor="let client of modal.obj.client_suggestions" (click)="selectClient(client)">
                                    <span>{{ client.name }}</span>
                                    <span>{{ client.phone }}</span>
                                </span>
                            </div>
                        </div>
                        <div class="flex flex-col w-full relative">
                            <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold">Телефон на клиент</label>
                            <input (keyup)="RetrieveClientForOrder('phone')" (focus)="focusClientPhone()" (focusout)="clearFocusClientPhone()" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mt-4 focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" [(ngModel)]="modal.obj.client_phone" type="text" placeholder="Въведете телефон на клиент" x-model="invoiceNumber">
                            <div class="auto-fill-dropdown" *ngIf="modal.obj.client_phone_select">
                                <span class="auto-fill-dropdown-item client" *ngFor="let client of modal.obj.client_suggestions" (click)="selectClient(client)">
                                    <span>{{ client.name }}</span>
                                    <span>{{ client.phone }}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex border-b items-center py-1 ml-4 mr-4">
                <div class="w-20 px-1">
                    <p class="text-black uppercase tracking-wide text-xs font-bold">#</p>
                </div>
    
                <div class="flex-1">
                    <p class="text-black uppercase tracking-wide text-xs font-bold">{{ languageService.ReturnTranslatedLabel('label_description') }}</p>
                </div>
    
                <div class="px-1 w-20 text-left">
                    <p class="text-black uppercase tracking-wide text-xs font-bold">{{ languageService.ReturnTranslatedLabel('label_quantity_short') }}</p>
                </div>
    
    
                <div class="px-1 w-20 text-left">
                    <p class="text-black uppercase tracking-wide text-xs font-bold">{{ languageService.ReturnTranslatedLabel('label_metric') }}</p>
                </div>
    
                <div class="px-1 w-20 text-left">
                    <p class="text-black uppercase tracking-wide text-xs font-bold">{{ languageService.ReturnTranslatedLabel('label_vat') }} (%)</p>
                </div>
    
    
                <div class="px-1 w-32 text-left">
                    <p class="leading-none">
                        <span class="block uppercase tracking-wide text-xs font-bold text-black">{{ languageService.ReturnTranslatedLabel('label_single_price') }}</span>
                        <span class="font-medium text-xs text-gray-500">{{ languageService.ReturnTranslatedLabel('label_without_vat_hint') }}</span>
                    </p>
                </div>
    
                <div class="px-1 w-32 text-right">
                    <p class="leading-none">
                        <span class="block uppercase tracking-wide text-xs font-bold text-black">{{ languageService.ReturnTranslatedLabel('label_total') }}</span>
                        <span class="font-medium text-xs text-gray-500">{{ languageService.ReturnTranslatedLabel('label_with_vat_hint') }}</span>
                    </p>
                </div>
            </div>
            <div  :key="order.id">
                <div class="flex border-b items-center py-1 ml-4 mr-4 relative" *ngFor="let item of modal.obj.items;let idx = index">
                    <div class="items-dropdown" *ngIf="item.selecting && modal.obj.results.length > 0">
                        <!-- (click)="selectResult(item, result)" -->
                        <div *ngFor="let result of modal.obj.results" (click)="selectResult(item, result)" class="flex">
                            <div class="w-20">
                                <div *ngIf="result.image != ''" style="float:left;width:50px;height:50px;background-image:url('{{ result.image }}');background-size:contain;background-position: center;background-repeat:no-repeat;margin-right:10px;"></div>
                                <div *ngIf="result.image == ''" style="float:left;width:50px;height:50px;margin-right:10px;" class="w-12 h-12 bg-gray-300 rounded-lg">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 ml-3 mt-3">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
                                    </svg>   
                                </div>
                            </div>
                            <div class="flex-1 relative text-overflow-ellipsis">
                                {{ result.name }} - {{ result.description }}
                            </div>
            
                            <div class="px-1 w-20 text-left">
                                1
                            </div>
            
                            <div class="px-1 w-20 text-left">
                                {{ result.metric }}
                            </div>
            
                            <div class="px-1 w-20 text-left">
                                {{ result.vat }}
                            </div>
            
                            <div class="px-1 w-32 text-left">
                                {{ returnFixed(result.price / 1.2) }}
                            </div>
            
                            <div class="px-1 w-32 text-right">
                                {{ returnFixed((((result.price / 1.2) * 1) * (1 + (result.vat / 100)))) }}
                            </div>
                        </div>
                    </div>
                    <div class="w-20 px-1">
                        <div *ngIf="item.image != ''" style="float:left;width:50px;height:50px;background-image:url('{{ item.image }}');background-size:contain;background-position: center;background-repeat:no-repeat;margin-right:10px;"></div>
                        <div *ngIf="item.image == ''" style="float:left;width:50px;height:50px;margin-right:10px;" class="w-12 h-12 bg-gray-300 rounded-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 ml-3 mt-3">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
                            </svg>   
                        </div>
                        <!-- <p class="text-gray-800 uppercase tracking-wide text-sm font-bold" style="line-height:36px;">{{ idx + 1 }}</p> -->
                    </div>
    
                    <div class="flex-1 relative">
                        <!-- (blur)="clearSelect(item)" (focus)="focusSelect(item)" -->
                        <input (keyup)="changedRow(idx, item)" (blur)="clearSelect(item)" (focus)="focusSelect(item)"  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" type="text" [(ngModel)]="item.name" placeholder="Въведете описание на продукта" />
                    </div>
    
                    <div class="px-1 w-20 text-right">
                        <input (keyup)="changedRow(idx, item)" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" type="number" [(ngModel)]="item.quantity" placeholder="К-во" />
                    </div>
    
                    <div class="px-1 w-20 text-right">
                        <select class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" type="number" [(ngModel)]="item.unit">
                            <option *ngFor="let unit of system.ReturnAvailableUnits()">{{ unit.name }}</option>
                        </select>
                    </div>
    
                    <div class="px-1 w-20 text-right">
                        <select class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" (change)="changedRow(idx, item)" [(ngModel)]="item.vat">
                            <option *ngFor="let vat of system.ReturnVats()" value="{{ vat.vat }}">{{ vat.vat }}%</option>
                        </select>
                        <!-- <input (keyup)="changedRow(idx, item)" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" type="number" [(ngModel)]="item.vat" placeholder="ДДС %" /> -->
                    </div>
    
                    <div class="px-1 w-32 text-right">
                        <input (keyup)="changedRow(idx, item)" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" type="number" [(ngModel)]="item.price" placeholder="Ед.цена" />
                    </div>
    
                    <div class="px-1 w-32 text-right">
                        <p class="text-gray-800" x-text="numberFormat(order.total)" style="line-height:36px;">{{ returnFixed((((item.price * item.quantity) * (1 + (item.vat / 100))))) }}</p>
                    </div>
    
                    <!-- <div class="px-1 w-32 text-right cursor-pointer">
                        <span *ngIf="modal.obj.items.length > 1"  style="line-height:36px;" href="#" class="text-red-500 hover:text-red-600 text-sm font-semibold cursor-pointer" (click)="removeItemFromOrder(idx)">{{ languageService.ReturnTranslatedLabel('item_action_remove') }}</span>
                    </div> -->
                </div>
            </div>

            <div class="modal-left-holder-bottom">
                <div class="flex flex-row justify-between bg-white mt-2 mb-2">
                    <div class="flex-col flex w-1/4">
                        <span class="text-left p-2 text-xs font-semibold">{{ languageService.ReturnTranslatedLabel('label_subtotal') }}: {{ returnStandardTotal() }} лв.</span>
                        <span class="text-left p-2 text-xs font-semibold">{{ languageService.ReturnTranslatedLabel('label_vat') }}:  {{ returnVatTotal() }} лв.</span>
                        <span class="text-left p-2 text-sm font-semibold">{{ languageService.ReturnTranslatedLabel('label_total_with_vat') }}:  {{ returnTotal() }} лв.</span>
                    </div>
                    <div>
                        <div *ngIf="modal.obj.shipping_method == 'econt' && !modal.obj.generated" class="bg-blue-100 border border-blue-300 text-blue-700 px-4 py-1 rounded-lg flex items-center max-w-md mx-auto text-xs" style="margin-right:10px;width:360px;" role="alert">
                            <!-- Info Icon -->
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6 mr-3">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M13 16h-1v-4h-1m0-4h.01M12 2a10 10 0 110 20 10 10 0 010-20z" />
                            </svg>
                            <!-- Info Text -->
                            <div>
                                Трябва да генерирате товарителница
                            </div>
                        </div>
                        <div class="order-accept-btn" (click)="CheckOrderBeforeAdd()">Създаване</div>
                        <div class="shipment-method-row">
                            <span>Метод на доставка</span>
                            <select [(ngModel)]="modal.obj.shipping_method">
                                <option value="standard">На място</option>
                                <option value="econt">ЕКОНТ</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-right-holder" *ngIf="modal.obj.shipping_method != 'standard'">
            <span class="modal-subtitle">ЕКОНТ Товарителница <div class="generate-econt-btn" (click)="GenerateShipment()" [ngClass]="{ 'disabled':!modal.obj.allowed_generate }" *ngIf="!modal.obj.generated">Генериране</div> </span>

            <div class="customer-ill" *ngIf="modal.obj.client_name.length == 0 || modal.obj.client_phone.length == 0"></div>
            <span class="customer-ill-text" *ngIf="modal.obj.client_name.length == 0 || modal.obj.client_phone.length == 0">Моля изберете клиент, за да продължите с генериране на товарителница</span>
        
            <div class="title-container" *ngIf="modal.obj.client_name.length > 0 && modal.obj.client_phone.length > 0 && !modal.obj.generating">
                <h1 class="title">Получател</h1>
                <div class="line"></div>
            </div>

            <div class="flex justify-between w-full" *ngIf="modal.obj.client_name.length > 0 && modal.obj.client_phone.length > 0 && !modal.obj.generating">
                <div class="flex flex-col bg-white p-4 ">
                    <span class="block uppercase tracking-wide text-grey-darker text-xs font-bold">Име</span>
                    <input [(ngModel)]="modal.obj.receipient_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mt-4 focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" readonly type="text" placeholder="" x-model="invoiceNumber">
                </div>
                <div class="flex flex-col bg-white p-4 ml-2 ">
                    <div class="flex flex-col w-full">
                        <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold">Телефон</label>
                        <input [(ngModel)]="modal.obj.receipient_phone" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mt-4 focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" readonly type="text" placeholder="" x-model="invoiceNumber">
                    </div>
                </div>
            </div>

            <!-- <div class="title-container" *ngIf="modal.obj.client_name.length > 0 && modal.obj.client_phone.length > 0">
                <h1 class="title">Адрес на получател</h1>
                <div class="line"></div>
            </div> -->

            <div class="flex justify-between w-full" *ngIf="modal.obj.client_name.length > 0 && modal.obj.client_phone.length > 0 && !modal.obj.generating">
                <div class="flex flex-col bg-white p-4 relative" style="padding-top:0px;">
                    <span class="block uppercase tracking-wide text-grey-darker text-xs font-bold">Град</span>
                    <input autocomplete="off" [(ngModel)]="modal.obj.receipient_city" (focus)="focusCity()" (focusout)="clearFocusCity()"  (keyup)="CalculateShipment('city')" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mt-4 focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="text" placeholder="" x-model="invoiceNumber">
                    <div class="auto-fill-dropdown" *ngIf="modal.obj.receipient_city_select" style="top:67px;">
                        <span class="auto-fill-dropdown-item client" *ngFor="let city of ReturnFilteredCity()" (click)="SelectCity(city)">
                            <span>{{ city.name }}</span>
                            <span>{{ city.postCode }}</span>
                        </span>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6 after-icon" *ngIf="modal.obj.receipient_city_selected">
                        <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
                    </svg>     
                </div>
                <div class="flex flex-col bg-white p-4 ml-2 " style="padding-top:0px;">
                    <div class="flex items-center space-x-4 mt-8">
                        <label class="inline-flex items-center text-xs">
                          <input
                            type="radio"
                            name="options"
                            [(ngModel)]="modal.obj.recipient_type"
                            (change)="RetrieveOffices()"
                            value="office"
                            class="form-radio text-blue-600"
                          />
                          <span class="ml-2">До офис</span>
                        </label>
                      
                        <label class="inline-flex items-center text-xs">
                          <input
                            type="radio"
                            name="options"
                            [(ngModel)]="modal.obj.recipient_type"
                            (change)="RetrieveStreets()"
                            value="address"
                            class="form-radio text-blue-600"
                          />
                          <span class="ml-2">До адрес</span>
                        </label>
                      </div>
                </div>
            </div>

            <div class="flex justify-between w-full" *ngIf="modal.obj.client_name.length > 0 && modal.obj.client_phone.length > 0  && !modal.obj.generating">
                <div class="flex flex-col bg-white p-4 w-full relative" style="padding-top:0px!important;" *ngIf="modal.obj.recipient_type == 'office'">
                    <span class="block uppercase tracking-wide text-grey-darker text-xs font-bold">Офис</span>
                    <input autocomplete="off" [(ngModel)]="modal.obj.receipient_office" (focus)="focusOffice()" (focusout)="clearFocusOffice()" (keyup)="CalculateShipment('office')" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mt-4 focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="text" placeholder="" x-model="invoiceNumber">
                    <div class="auto-fill-dropdown" *ngIf="modal.obj.receipient_office_select" style="top:67px;">
                        <span class="auto-fill-dropdown-item client" *ngFor="let office of ReturnFilteredOffices()" (click)="SelectOffice(office)">
                            <span>{{ office.name }} </span>
                            <span class="text-overflow-ellipsis">{{ office.address.fullAddress }}</span>
                        </span>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6 after-icon" *ngIf="modal.obj.receipient_office_selected">
                        <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
                    </svg>                      
                </div>

                <div class="flex flex-col bg-white p-4 w-3/4 relative" style="padding-top:0px!important;" *ngIf="modal.obj.recipient_type == 'address'">
                    <span class="block uppercase tracking-wide text-grey-darker text-xs font-bold">Улица</span>
                    <input autocomplete="off"  [(ngModel)]="modal.obj.receipient_street" (focus)="focusStreet()" (focusout)="clearFocusStreet()" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mt-4 focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="text" placeholder="" x-model="invoiceNumber">
                    <div class="auto-fill-dropdown" *ngIf="modal.obj.receipient_street_select" style="top:67px;">
                        <span class="auto-fill-dropdown-item client" *ngFor="let street of ReturnFilteredStreets()"  (click)="SelectStreet(street)">
                            <span>{{ street.name }} </span>
                        </span>
                    </div>
                </div>
                <div class="flex flex-col bg-white p-4 w-1/4" style="padding-top:0px!important;" *ngIf="modal.obj.recipient_type == 'address'">
                    <span class="block uppercase tracking-wide text-grey-darker text-xs font-bold">№</span>
                    <input [(ngModel)]="modal.obj.receipient_street_num" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mt-4 focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="text" placeholder="" x-model="invoiceNumber">
                </div>
            </div>

            <div class="title-container relative" *ngIf="modal.obj.client_name.length > 0 && modal.obj.client_phone.length > 0  && !modal.obj.generating">
                <h1 class="title">Товар</h1>
                <div class="line"></div>
                <div class="template-holder">
                    <div class="template-text" (click)="OpenTemplateList()">От шаблон</div>
                    <div class="template-arrow" (click)="OpenTemplateList()">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4" style="margin-left: 3px;margin-top: 3px;">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                        </svg>                          
                    </div>
                </div>
                <div class="template-add" (click)="AddTemplate()">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4" style="margin-left: 3px;margin-top: 3px;">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>                                                
                </div>
                <div class="template-list" *ngIf="modal.obj.template_open" (clickOutside)="CloseTemplateList()">
                    <div class="template-list-header">
                        <div>Описание</div>
                        <div>Размер</div>
                        <div>Тежест</div>
                        <div>НП</div>
                    </div>
                    <div class="template-list-body">
                        <div class="template-list-row" style="background:white!important" *ngIf="modal.obj.econt_templates.length == 0">
                            <div style="width:100%;text-align:center;">Няма добавени шаблони</div>
                        </div>
                        <div class="template-list-row" (click)="SelectTemplate(template)" *ngFor="let template of modal.obj.econt_templates">
                            <div>{{ template.description }}</div>
                            <div>{{ template.data.size }}</div>
                            <div>{{ template.data.weight }} кг.</div>
                            <div>{{ template.data.cd_payment }} лв.</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex justify-between w-full" *ngIf="modal.obj.client_name.length > 0 && modal.obj.client_phone.length > 0  && !modal.obj.generating">
                <div class="flex flex-col bg-white p-4 w-2/3">
                    <span class="block uppercase tracking-wide text-grey-darker text-xs font-bold">Размери</span>
                    <div class="flex justify-between w-full">
                        <input style="height: 40px;" [(ngModel)]="modal.obj.cargo_x" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mt-2 focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-1/3" id="inline-full-name" type="text" placeholder="Д" x-model="invoiceNumber">
                        <span style="line-height: 55px;margin-right: 5px;margin-left:5px;color: gray;">x</span>
                        <input style="height: 40px;"  [(ngModel)]="modal.obj.cargo_y" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mt-2 focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-1/3" id="inline-full-name" type="text" placeholder="Ш" x-model="invoiceNumber">
                        <span style="line-height: 55px;margin-right: 5px;margin-left:5px;color: gray;">x</span>
                        <input style="height: 40px;"  [(ngModel)]="modal.obj.cargo_z" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mt-2 focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-1/3" id="inline-full-name" type="text" placeholder="В" x-model="invoiceNumber">
                    </div>
                </div>
                <div class="flex flex-col bg-white p-4 w-1/3">
                    <span class="block uppercase tracking-wide text-grey-darker text-xs font-bold">Тегло (кг.)</span>
                    <input [(ngModel)]="modal.obj.cargo_weight" (keyup)="CalculateShipment('weight')" style="margin-top:10px;" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mt-4 focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="number" placeholder="" x-model="invoiceNumber">
                </div>
            </div>

            <div class="flex justify-between w-full" *ngIf="modal.obj.client_name.length > 0 && modal.obj.client_phone.length > 0  && !modal.obj.generating">
                <div class="flex flex-col bg-white p-4 w-full " style="padding-top:0px!important;">
                    <span class="block uppercase tracking-wide text-grey-darker text-xs font-bold">Описание</span>
                    <input [(ngModel)]="modal.obj.cargo_description" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mt-4 focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="text" placeholder="" x-model="invoiceNumber">
                </div>
            </div>

            <div class="title-container" *ngIf="modal.obj.client_name.length > 0 && modal.obj.client_phone.length > 0 && !modal.obj.generating">
                <h1 class="title">Допълнителни услуги</h1>
                <div class="line"></div>
            </div>

            <div class="flex justify-between w-full" *ngIf="modal.obj.client_name.length > 0 && modal.obj.client_phone.length > 0  && !modal.obj.generating">
                <div class="flex flex-col bg-white p-4 w-2/3">
                    <span class="block uppercase tracking-wide text-grey-darker text-xs font-bold">SMS Известяване</span>
                    <input [(ngModel)]="modal.obj.sms_addon" (change)="CalculateShipment('sms_notification')" id="checkbox" style="width: 24px; height: 24px; margin-top: 15px;" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500">
                </div>
                <div class="flex flex-col bg-white p-4 w-2/4" lang="en-US">
                    <span class="block uppercase tracking-wide text-grey-darker text-xs font-bold">Наложен платеж</span>
                    <input [(ngModel)]="modal.obj.cd_payment_price" (keyup)="CalculateShipment('cd_payment')" style="margin-top:10px;" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mt-4 focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" step="0.01" id="inline-full-name" type="number">
                </div>
            </div>


            <div class="title-container" *ngIf="modal.obj.client_name.length > 0 && modal.obj.client_phone.length > 0 && !modal.obj.generating">
                <h1 class="title">Калкулатор</h1>
                <div class="line"></div>
            </div>

            <div class="flex justify-between w-full" *ngIf="modal.obj.client_name.length > 0 && modal.obj.client_phone.length > 0  && !modal.obj.generating">
                <div class="flex flex-col bg-white p-4 w-full " style="padding-top:0px!important;">
                  <div class="flex flex-row text-xs"  *ngFor="let service of modal.obj.services">
                    <span class="flex w-3/4" *ngIf="service.type == 'C'">Куриерска услуга</span>
                    <span class="flex w-3/4" *ngIf="service.type == 'CD'">Такса наложен платеж</span>
                    <span class="flex w-3/4" *ngIf="service.type != 'CD' && service.type != 'C'">{{ service.description }}</span>
                    <span class="flex w-1/4 font-bold">{{ returnFixed(service.price) }} лв.</span>
                  </div>
                </div>
            </div>

            <div class="title-container" *ngIf="modal.obj.client_name.length > 0 && modal.obj.client_phone.length > 0">
                <div class="line"></div>
            </div>

            <div class="flex justify-between w-full" *ngIf="modal.obj.client_name.length > 0 && modal.obj.client_phone.length > 0  && !modal.obj.generating">
                <div class="flex flex-col bg-white p-4 w-full " style="padding-top:10px!important;">
                    <div class="flex flex-row text-xs font-bold">
                        <span class="flex w-3/4">Тотал с отстъпка ({{ modal.obj.discountDescription }}):</span>
                        <span class="flex w-1/4">{{ returnFixed(ReturnTotalDelivery()) }}  лв.</span>
                    </div>
                    <div class="flex flex-row text-xs font-bold">
                        <span class="flex w-3/4">Тотал без отстъпка:</span>
                        <span class="flex w-1/4">{{ returnFixed(ReturnTotalDelivery() - modal.obj.discountAmount) }} лв.</span>
                    </div>
                </div>
            </div>

            <div class="universal-svg" *ngIf="modal.obj.generating && !modal.obj.generated"></div>
            <span class="shipment-hint-title" *ngIf="modal.obj.generating && !modal.obj.generated">Генериране на товарителница</span>

            <div class="shipment-success" *ngIf="modal.obj.generated"></div>
            <span class="shipment-hint-title" *ngIf="modal.obj.generated">Генрирана товарителница номер #{{ modal.obj.generated_label }}</span>
        </div>
    </div>
</div>
<!-- /Modal -->

<!-- SIDE MODAL -->
<div class="side-modal" *ngIf="sideModal.open">
    <div class="backdrop"></div>

    <!--  -->
    <div class="modal lg" [ngClass]="{ 'active':sideModal.open && sideModal.cntx == 'add-order' }">
        <div class="flex flex-col justify-between p-4 bg-positive">
            <h1 class="text-lg font-bold  text-white">Добавяне на поръчка</h1>
            <div class="absolute w-12 h-12 bg-positive-smooth cursor-pointer hover:bg-positive-hover rounded-md" (click)="closeSideModal()" style="right:0.7rem;top:0.3rem;color:white;">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 ml-2 mt-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>                      
            </div>
        </div>
    </div>
    <!--  -->
    <!-- SEND ORDER MODAL -->
    <div class="modal" [ngClass]="{ 'active':sideModal.open && sideModal.cntx == 'send-order' }">
        <div class="flex flex-col justify-between p-4 bg-positive" *ngIf="!this.sideModal.obj.order_found">
            <h1 class="text-lg font-bold  text-white">Изпращане на поръчка</h1>
            <div class="absolute w-12 h-12 bg-positive-smooth cursor-pointer hover:bg-positive-hover rounded-md" (click)="closeSideModal()" style="right:0.7rem;top:0.3rem;color:white;">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 ml-2 mt-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>                      
            </div>
        </div>

        <div class="flex flex-col justify-between p-4 bg-positive" *ngIf="this.sideModal.obj.order_found">
            <h1 class="flex flex-rowtext-lg font-bold text-white">
                <span>#{{ sideModal.obj.order.uid }}</span>
                <div class="absolute w-12 h-12 bg-positive-smooth cursor-pointer hover:bg-positive-hover rounded-md" (click)="closeSideModal()" style="right:0.7rem;top:0.7rem;">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 ml-2 mt-2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>                      
                </div>
            </h1>
            <h1 class="text-xs font-medium  text-white">Детайли за поръчка</h1>
        </div>

        <div style="float: left;width: 100%;height: calc(100% - 140px);overflow: auto;padding-top: 20px;">

            <div class="search loading" *ngIf="sideModal.obj.searching">
                <div class="search__circle"></div>
                <div class="search__rectangle"></div>
            </div>
            <div class="mb-2 mt-2 w-full flex flex-col px-4" *ngIf="!sideModal.obj.searching && !sideModal.obj.order_found">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 mr-2" >Товарителница <span class="text-red-500">*</span></label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.shipping_number" #shippingInput (keydown.enter)="ScannedTracking()" (keydown.tab)="ScannedTracking()" [ngClass]="{ 'disabled':this.sideModal.obj.order_found }" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="text">
                </div>
            </div>

            <div class="text-gray-400 mb-4 flex flex-row ml-4 mr-4 border-b-2 pb-2" *ngIf="this.sideModal.obj.order_found">
                <div class="flex w-full flex-col w-1/2">
                    <p class="text-black font-bold text-sm">Създадена на:</p>
                    <span class="text-black text-sm">{{ returnFormattedDateAndTime(sideModal.obj.order.date_added) }}</span>
                </div>
    
                <div class="w-1/2 text-right">
                    <span class="px-2 py-1 text-yellow-500 bg-yellow-100/60 font-medium text-sm text-xs rounded"  *ngIf="sideModal.obj.order.status == 0">{{ languageService.ReturnTranslatedLabel('label_waiting_orders') }}</span>
                    <span class="px-2 py-1 text-blue-500 bg-blue-100/60 font-medium text-sm text-xs rounded"  *ngIf="sideModal.obj.order.status == 1">{{ languageService.ReturnTranslatedLabel('label_complete_orders') }}</span>
                    <span class="px-2 py-1 text-green-500 bg-green-100/60 font-medium text-sm text-xs rounded"  *ngIf="sideModal.obj.order.status == 2">{{ languageService.ReturnTranslatedLabel('label_sent_orders') }}</span>
                </div>

            </div>
            <div class="mb-4 ml-4 mr-4 border-b-2 pb-2" *ngIf="this.sideModal.obj.order_found">
                <h2 class="text-lg font-semibold mb-2">Клиент</h2>
                <p>{{ sideModal.obj.client.name }}</p>
                <p>{{ sideModal.obj.client.phone }}</p>
                <p>{{ sideModal.obj.client.address }}</p>
            </div>
            <div class="mb-4 ml-4 mr-4 border-b-2 pb-2 view-order-articles" *ngIf="this.sideModal.obj.order_found">
                <h2 class="text-lg font-semibold mb-2">Артикули</h2>
                <div class="flex items-center justify-between mb-2" *ngFor="let product of sideModal.obj.new_products">
                    <div class="flex items-center">
                        <div class="w-12 h-12 mr-2" style="background-size:contain;background-position: center;background-repeat:no-repeat;"  [ngStyle]="{ 'background-image':'url('+ product.image +')' }"></div>
                        <div class="flex flex-col ">
                            <span>{{ product.name }}</span>
                            <span class="text-xs">Баркод: {{ product.barcode }} Модел: {{ product.sku }}</span>
                        </div>
                    </div>
                    <span style="width:110px;">{{ ReturnPriceFixed(product.price) }} лв.</span>
                </div>
                <div class="w-full">
                    <div class="relative">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3">
                            <div class="background-scan w-6 h-6"></div>
                        </div>
                        <input type="text" class="bg-gray-100/60 text-black border border-gray-300 rounded-lg w-full pl-12 py-2 ng-valid ng-touched ng-dirty" #barcodeInput type="text" [(ngModel)]="sideModal.obj.barcode" (keydown)="ChangedProductField()"  (keydown.enter)="ScannedProduct()" (keydown.tab)="ScannedProduct()" (focus)="focusedSearch = true" (focusout)="ClearProductSearch()" >
                        <div class="searched_products" *ngIf="focusedSearch && sideModal.obj.barcode.length > 0">
                            <div class="searched_products_item" (click)="SelectProduct(searched_product)" *ngFor="let searched_product of searched_products">
                                <i class="searched_product_avatar" style="background-size:contain;background-position: center;background-repeat:no-repeat;"  [ngStyle]="{ 'background-image':'url('+ searched_product.image +')' }"></i>
                                <div class="searched_product_info">
                                    <span>{{ searched_product.name }}</span>
                                    <span>Баркод: {{ searched_product.barcode }} Модел: {{ searched_product.sku }}</span>
                                </div>
                            </div>
                            <span *ngIf="searched_products.length == 0">Няма намерени артикули с тези параметри</span>
                        </div>
                    </div>
                    <p class="text-gray-500 text-xs mt-1">Въведете баркод за да добавите продукт</p>
                </div>
            </div>

            <div class="mb-4 ml-4 mr-4 border-b-2 pb-2" *ngIf="this.sideModal.obj.order_found">
                <div class="flex justify-between text-sm font-medium">
                    <span>Данъчна основа</span>
                    <span>{{ ReturnTotalWithoutVAT() }} лв.</span>
                </div>
                <div class="flex justify-between text-sm font-medium">
                    <span>ДДС</span>
                    <span>{{ ReturnTotalVAT() }} лв.</span>
                </div>
                <div class="flex justify-between text-sm font-bold">
                    <span>Тотал с ДДС</span>
                    <span>{{ ReturnTotal() }} лв.</span>
                </div>
            </div>

            
            <div class="mb-4 ml-4 mr-4 border-b-2 pb-2" *ngIf="this.sideModal.obj.order_found">
                <div class="flex justify-between text-sm font-bold relative cursor-pointer" (click)="sideModal.obj.extended_delivery = !sideModal.obj.extended_delivery">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style="left: -15px;top: 3px;" class="w-4 h-4 absolute">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                    </svg>                  
                    <span>Доставка (ЕКОНТ)</span>
                    <span>{{ sideModal.obj.delivery_price }} лв.</span>
                </div>
                <div class="extendable" [ngClass]="{ 'extended':sideModal.obj.extended_delivery }">
                    <div class="flex justify-between text-sm font-medium" *ngFor="let service of sideModal.obj.delivery_prices">
                        <span style="width:80%;">{{ service.description }}</span>
                        <span>{{ service.price }} лв.</span>
                    </div>
                </div>
                <div class="flex justify-between text-sm font-bold">
                    <span>Тотал с доставка</span>
                    <span>{{ ReturnTotalWithDelivery() }} лв.</span>
                </div>
            </div>
        </div>

        <div (click)="SendOrder()" class="ml-4 mr-4 w-full bg-positive text-white text-center h-12 rounded-md cursor-pointer hover:bg-positive-hover" style="line-height:3rem;width: 94%;float:left;">
            Изпрати поръчка
        </div>
    </div>
    <!-- EXIT SEND ORDER MODAL -->

    <!-- TRACK ORDER MODAL -->
    <div class="modal" [ngClass]="{ 'active':sideModal.open && sideModal.cntx == 'track-order' }">
        <div class="flex flex-col justify-between p-4 bg-positive">
            <h1 class="text-lg font-bold  text-white">Проследяване на пратка</h1>
            <div class="absolute w-12 h-12 bg-positive-smooth cursor-pointer hover:bg-positive-hover rounded-md" (click)="closeSideModal()" style="right:0.7rem;top:0.3rem;color:white;">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 ml-2 mt-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>                      
            </div>
        </div>

        <div class="container mx-auto p-4" style="overflow: scroll;max-height: calc(100% - 60px);">
            <div class="event-row" *ngFor="let ev of sideModal.obj.events; let i = index">
                <span class="time">{{ ReturnTimeConverter(ev.time) }}</span>
                <div class="circle" [ngClass]="{ 'passed':i < sideModal.obj.events.length - 1, 'active':i == sideModal.obj.events.length - 1 }">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8" style="margin-left: 2px;margin-top: 3px;color: #60b515;" *ngIf="i < sideModal.obj.events.length - 1">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                    </svg>                      
                </div>
                <div class="event-row-info">
                    <span *ngIf="ev.destinationType == 'prepared'">Очаква предаване към Еконт</span>
                    <span *ngIf="ev.destinationType == 'office'">Пристигнал в офис</span>
                    <span *ngIf="ev.destinationType == 'courier_direction'">Натоварена на линия</span>
                    <span *ngIf="ev.destinationType == 'client'">Получено от</span>
                    <span *ngIf="ev.destinationType == 'office'">Пристигнал в офис - {{ ev.destinationDetails }}</span>
                    <span *ngIf="ev.destinationType == 'courier_direction'">Натоварена на линия - {{ ev.destinationDetails }}</span>
                    <span *ngIf="ev.destinationType == 'client'">Получено от  - {{ ev.destinationDetails }}</span>
                </div>
            </div>
        </div> 
    </div>
    <!-- EXIT TRACK ORDER MODAL -->
</div>
<!-- SIDE MODAL - EXIT -->

<section class="min-h-screen custom-min-h-screen bg-nexa text-gray-800">
    <div class="flex flex-col p-4">
        <div class="float-left w-full p-2">
            <div class="relative float-left font-bold text-black" style="font-size:24px;">{{ languageService.ReturnTranslatedLabel('menu_orders') }}</div>

            <div class="flex items-center float-right gap-x-3">

                
                <button class="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-positive rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-positive-hover" (click)="openSideModal('send-order', { 'shipping_number':'', 'new_products':[] })">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 18.75a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 0 1-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
                    </svg>
                                           
    
                    <span>Изпращане на поръчка</span>
                </button>
<!-- 
                <button class="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-positive rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-positive-hover" routerLink="/add-order">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>                      
    
                    <span>{{ languageService.ReturnTranslatedLabel('add_order') }}</span>
                </button> -->

                <button class="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-positive rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-positive-hover" (click)="openModal('add-order', { 'order_id':'1', 'order_date':'17.08.2024', 'shipping_method':'standard', 'client_name':'', 'client_phone':'', 'receipient_name':'', 'receipient_phone':'', 'receipient_city':'', 'recipient_type':'office', 'receipient_office':'', 'receipient_office_code':'', 'offices':[], 'discountAmount':0.00, 'discountDescription':'', 'cargo_weight':1, 'cd_payment_price':0.00, 'sms_addon':false, 'services':[], 'allowed_generate':false, 'generating':false, 'econt_templates':[], 'cargo_x':'', 'cargo_y':'', 'cargo_z':'', items:[{ id:1, name:'', quantity:1, vat:20, unit:'бр.', price:0.00, 'image':[] }] })">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>                      
    
                    <span>{{ languageService.ReturnTranslatedLabel('add_order') }}</span>
                </button>
            </div>
        </div>
        <div class="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 mt-2" style="padding-top:0px;padding-bottom:0px">
                <div class="p-3 bg-white md:rounded-lg h-14 border border-gray-200">
                    <div class="float-left" style="width:240px;">
                        <input type="text" class="h-8 p-2 font-semibold text-xs text-black rounded-lg search-field" placeholder="{{ languageService.ReturnTranslatedLabel('label_search') }}" />
                        <div class="btn float-right rounded-lg bg-positive text-white text-center search-btn">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                            </svg>                              
                        </div>
                    </div>
                    <div class="float-left ml-2">
                        <select [(ngModel)]="status" (change)="switchType()" class="h-8 p-2 font-semibold text-xs text-black rounded-lg">
                            <option value="-1">{{ languageService.ReturnTranslatedLabel('label_all_orders') }}</option>
                            <option value="0">{{ languageService.ReturnTranslatedLabel('label_waiting_orders') }}</option>
                            <option value="1">{{ languageService.ReturnTranslatedLabel('label_complete_orders') }}</option>
                            <option value="2">{{ languageService.ReturnTranslatedLabel('label_sent_orders') }}</option>
                        </select>
                    </div>

                    <span class="float-right">
                        <div class="float-left ml-2">
                            <span class="mr-2 text-xs font-bold text-gray-600">{{ languageService.ReturnTranslatedLabel('label_results_per_page') }}</span>
                            <select (change)="switchLimit()" [(ngModel)]="limit" class="h-8 p-2 font-semibold text-xs text-black rounded-lg">
                                <option>10</option>
                                <option>20</option>
                                <option>50</option>
                                <option>100</option>
                            </select>
                        </div>
                        <span class="page-btn" (click)="StartPage()"  [ngClass]="{ 'disabled':system.ReturnCurrentOrdersPages() < 2 }">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
                            </svg>                                                      
                        </span>
                        <span class="page-btn" (click)="PrevPage()"  [ngClass]="{ 'disabled':system.ReturnCurrentOrdersPages() < 2 }">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                              </svg>                              
                        </span>
                        <span class="page-btn hover:bg-positive hover:text-white" *ngIf="system.ReturnCurrentOrdersPages() > 1" (click)="goToPage( system.ReturnCurrentOrdersPages() - 1)">{{ system.ReturnCurrentOrdersPages() - 1 }}</span>
                        <span class="page-btn bg-positive text-white hover:bg-positive hover:text-white" (click)="goToPage( system.ReturnCurrentOrdersPages())">{{ system.ReturnCurrentOrdersPages() }}</span>
                        <span class="page-btn hover:bg-positive hover:text-white" *ngIf="system.ReturnTotalOrdersPages() > system.ReturnCurrentOrdersPages()" (click)="goToPage( system.ReturnCurrentOrdersPages() + 1)">{{ system.ReturnCurrentOrdersPages() + 1 }}</span>
                        <span class="page-btn" (click)="NextPage()" [ngClass]="{ 'disabled':system.ReturnCurrentOrdersPages() == system.ReturnTotalOrdersPages() }">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>                              
                        </span>
                        <span class="page-btn" (click)="LastPage()" [ngClass]="{ 'disabled':system.ReturnCurrentOrdersPages() == system.ReturnTotalOrdersPages() }">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
                            </svg>                              
                        </span>
                    </span>
                </div>
            </div>
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 mt-2" style="padding-top:0px;padding-bottom:0px">
                <div class="border border-gray-200  md:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="py-3.5 px-4 max-w-36 text-sm font-normal text-left rtl:text-right text-gray-500">
                                    <button class="flex items-center gap-x-3 focus:outline-none">
                                        <span>{{ languageService.ReturnTranslatedLabel('label_order') }}</span>

                                        <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                            <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                            <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                        </svg>
                                    </button>
                                </th>

                                <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                    {{ languageService.ReturnTranslatedLabel('label_status') }}
                                </th>

                                <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                    {{ languageService.ReturnTranslatedLabel('label_information') }}
                                </th>
                                
                                <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                    {{ languageService.ReturnTranslatedLabel('label_product_quantity') }}
                                </th>

                                <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">{{ languageService.ReturnTranslatedLabel('label_price') }}</th>

                                <th scope="col" class="relative py-3.5 px-4">
                                    <span class="sr-only">Edit</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr *ngIf="system.ReturnOrders().length == 0">
                                <td class="text-center p-4 bg-gray-100" colspan="6">{{ languageService.ReturnTranslatedLabel('label_no_orders') }}</td>
                            </tr>
                            <tr *ngFor="let order of system.ReturnOrders()">
                                <td class="px-4 py-4 max-w-36 text-sm font-medium whitespace-nowrap" style="max-width: 300px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
                                    <div>
                                        <h2 class="font-medium text-md text-gray-800">{{ order.client_name }}</h2>
                                        <p class="text-sm font-normal text-gray-600" style="max-width: 300px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">{{ order.short_description }}</p>
                                        <div class="text-xs font-normal text-gray-600" *ngIf="order.shipping_method == 'econt'" (click)="OpenTrackOrder(order)"> <div class="econt-logo" style="float: left;
                                            width: 40px;
                                            height: 20px;
                                            margin-right: 5px;"></div> <span *ngIf="order.shipping_method == 'econt'" class="cursor-pointer" style="margin-top:2px;float:left;
    text-decoration: underline;
    color: #1d4179;">{{ order.shipping_number }}</span></div>
                                    </div>
                                </td>
                                <td class="px-12 py-4 text-sm font-medium whitespace-nowrap" style="position:relative;">
                                    <div class="inline px-3 py-1 text-sm font-bold rounded-full text-yellow-500 gap-x-2 bg-yellow-100/60" (click)="openStatus(order)" *ngIf="order.status == -1">
                                        Чака потвърждение
                                        <button type="button" class="inline-flex items-center p-1 text-sm bg-transparent rounded-sm text-yellow-500" data-dismiss-target="#badge-dismiss-default" aria-label="Remove">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                            </svg>                                              
                                            <span class="sr-only">Remove badge</span>
                                        </button> 
                                    </div>
                                    <div class="inline px-3 py-1 text-sm font-bold rounded-full text-yellow-500 gap-x-2 bg-yellow-100/60" (click)="openStatus(order)" *ngIf="order.status == 0">
                                        {{ languageService.ReturnTranslatedLabel('label_waiting_orders') }}
                                        <button type="button" class="inline-flex items-center p-1 text-sm bg-transparent rounded-sm text-yellow-500" data-dismiss-target="#badge-dismiss-default" aria-label="Remove">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                            </svg>                                              
                                            <span class="sr-only">Remove badge</span>
                                        </button> 
                                    </div>
                                    <div class="inline px-3 py-1 text-sm font-bold rounded-full text-blue-500 gap-x-2 bg-blue-100/60" (click)="openStatus(order)" *ngIf="order.status == 1">
                                        {{ languageService.ReturnTranslatedLabel('label_complete_orders') }}
                                        <button type="button" class="inline-flex items-center p-1 text-sm bg-transparent rounded-sm text-blue-500" data-dismiss-target="#badge-dismiss-default" aria-label="Remove">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                            </svg>                                              
                                            <span class="sr-only">Remove badge</span>
                                        </button>
                                    </div>
                                    <div class="inline px-3 py-1 text-sm font-bold rounded-full text-green-500 gap-x-2 bg-green-100/60" (click)="openStatus(order)" *ngIf="order.status == 2">
                                        {{ languageService.ReturnTranslatedLabel('label_sent_orders') }}
                                        <button type="button" class="inline-flex items-center p-1 text-sm bg-transparent rounded-sm text-green-500" data-dismiss-target="#badge-dismiss-default" aria-label="Remove">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                            </svg>                                              
                                            <span class="sr-only">Remove badge</span>
                                        </button>
                                    </div>
                                    <div class="inline px-3 py-1 text-sm font-bold rounded-full text-indigo-500 gap-x-2 bg-indigo-100/60" (click)="openStatus(order)" *ngIf="order.status == 3">
                                        {{ languageService.ReturnTranslatedLabel('label_delivered_orders') }}
                                        <button type="button" class="inline-flex items-center p-1 text-sm bg-transparent rounded-sm text-indigo-500" data-dismiss-target="#badge-dismiss-default" aria-label="Remove">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                            </svg>                                              
                                            <span class="sr-only">Remove badge</span>
                                        </button>
                                    </div>
                                    <div style="text-transform: uppercase;font-size: 10px;" (click)="openStatus(order)" class="inline-flex px-3 py-1 text-sm font-bold rounded-full text-red-500 gap-x-2 bg-red-100/60 " *ngIf="order.status == '4'">
                                        <span>Отказана</span>
                                        <button type="button" class="inline-flex items-center p-1 text-sm bg-transparent rounded-sm text-red-500" data-dismiss-target="#badge-dismiss-default" aria-label="Remove">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                            </svg>                                              
                                            <span class="sr-only">Remove badge</span>
                                          </button>
                                    </div>
                                    <div class="status-dropdown" *ngIf="order.open_status" (clickOutside)="closeOpenStatus(order, $event)">
                                        <div class="status-dropdown-item" (click)="changeOrderStatus(order, 4)">Отказана</div>
                                        <div class="status-dropdown-item" (click)="changeOrderStatus(order, 5)">Потвърдена</div>
                                        <div class="status-dropdown-item" (click)="changeOrderStatus(order, 0)">{{ languageService.ReturnTranslatedLabel('label_waiting_orders') }}</div>
                                        <div class="status-dropdown-item" (click)="changeOrderStatus(order, 1)">{{ languageService.ReturnTranslatedLabel('label_complete_orders') }}</div>
                                        <div class="status-dropdown-item" (click)="changeOrderStatus(order, 2)">{{ languageService.ReturnTranslatedLabel('label_sent_orders') }}</div>
                                        <div class="status-dropdown-item" (click)="changeOrderStatus(order, 3)">{{ languageService.ReturnTranslatedLabel('label_delivered_orders') }}</div>
                                        
                                    </div>
                                </td>
                                <td class="px-4 py-4 text-sm whitespace-nowrap">
                                    <div>
                                        <h4 class="text-gray-700">{{ languageService.ReturnTranslatedLabel('label_date_of_order') }} -  {{ returnFormattedDate(order.date_added) }}</h4>
                                        <p class="text-gray-500">{{ languageService.ReturnTranslatedLabel('label_date_of_execution') }} - .....</p>
                                    </div>
                                </td>
                                <td class="px-4 py-4 text-sm whitespace-nowrap">
                                    <div class="flex item-center">
                                        {{ returnProductCount(order) - 1 }} {{ languageService.ReturnTranslatedLabel('label_pieces') }}
                                    </div>
                                </td>

                                <td scope="col" class="px-4 py-4 text-sm whitespace-nowrap">
                                    {{ returnFixed(order.total) }} лв.
                                </td>

                                <td class="px-4 py-4 text-sm whitespace-nowrap text-right">
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2 group relative" routerLink="/view-order" [queryParams]="{id: order.uid }">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                                            <path fill-rule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z" clip-rule="evenodd" />
                                        </svg>   
                                        
                                        <!-- Tooltip -->
                                        <div style="padding-left:10px;padding-right:10px;" class="opacity-0 bg-black text-white text-sm rounded-md py-2 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-100">
                                            {{ languageService.ReturnTranslatedLabel('label_hint_view_order') }}
                                        </div>
                                    </button>

                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2 group relative" (click)="OpenSendOrder(order)">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path d="M3.375 4.5C2.339 4.5 1.5 5.34 1.5 6.375V13.5h12V6.375c0-1.036-.84-1.875-1.875-1.875h-8.25ZM13.5 15h-12v2.625c0 1.035.84 1.875 1.875 1.875h.375a3 3 0 1 1 6 0h3a.75.75 0 0 0 .75-.75V15Z" />
                                            <path d="M8.25 19.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0ZM15.75 6.75a.75.75 0 0 0-.75.75v11.25c0 .087.015.17.042.248a3 3 0 0 1 5.958.464c.853-.175 1.522-.935 1.464-1.883a18.659 18.659 0 0 0-3.732-10.104 1.837 1.837 0 0 0-1.47-.725H15.75Z" />
                                            <path d="M19.5 19.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z" />
                                          </svg>
                                        <!-- Tooltip -->
                                        <div style="padding-left:10px;padding-right:10px;" class="opacity-0 bg-black text-white text-sm rounded-md py-2 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-100">
                                            Изпращане на поръчка
                                        </div>
                                    </button>

          
                                      
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2 group relative" routerLink="/edit-order/{{ order.uid }}">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                                            <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                                        </svg>  
                                        
                                        <!-- Tooltip -->
                                        <div style="padding-left:10px;padding-right:10px;" class="opacity-0 bg-black text-white text-sm rounded-md py-2 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-100">
                                            {{ languageService.ReturnTranslatedLabel('label_hint_edit_order') }}
                                        </div>
                                    </button>
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2 group relative" routerLink="/add-invoice/order/{{order.uid}}">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path fill-rule="evenodd" d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zM12.75 12a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V18a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V12z" clip-rule="evenodd" />
                                            <path d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z" />
                                        </svg>

                                        <!-- Tooltip -->
                                        <div style="padding-left:10px;padding-right:10px;" class="opacity-0 bg-black text-white text-sm rounded-md py-2 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-100">
                                            {{ languageService.ReturnTranslatedLabel('label_hint_generate_invoice') }}
                                        </div>
                                    </button>
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2 group relative">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                                            <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                                        </svg>     
                                        
                                        <!-- Tooltip -->
                                        <div style="padding-left:10px;padding-right:10px;" class="opacity-0 bg-black text-white text-sm rounded-md py-2 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-100">
                                            {{ languageService.ReturnTranslatedLabel('label_hint_send_order') }}
                                        </div>
                                    </button>
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 group relative" (click)="openModal('remove-order', order)">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clip-rule="evenodd" />
                                        </svg>  
                                        
                                        <!-- Tooltip -->
                                        <div style="padding-left:10px;padding-right:10px;" class="opacity-0 bg-black text-white text-sm rounded-md py-2 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-100">
                                            {{ languageService.ReturnTranslatedLabel('label_hint_remove_order') }}
                                        </div>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>