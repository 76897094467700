import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, ElementRef, HostListener } from '@angular/core';
import { LanguageServiceService } from '../language-service.service';
import { SystemService, Product } from '../system.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent {
  @ViewChild('barcodeInput') barcodeInput: ElementRef;
  @ViewChild('shippingInput') shippingInput: ElementRef;

  @HostListener("window:beforeunload", ['$event'])
  onBeforeUnload(event:Event):void {
    event.preventDefault();
    if(this.modal.open && this.modal.cntx == 'edit-order') {
      alert("Има отворена поръчка, моля затворете я преди да излезнете!");
      delete this.modal.obj.cities;
      delete this.modal.obj.offices;
      this.modal.obj.processed_by = "";
      this.http.put("https://api.nexa.bg/orders/" + this.modal.obj.uid, { 'order':this.modal.obj}, {
        withCredentials: true
      })
      .subscribe(response => {});
    }
  }

  searched_products:any = [];
  focusedSearch:any = false;
  allow_close_product_search:any = true;
  allow_close_product_search_timeout:any;
  fetch_order_interval:any;
  
  status:any = "all";
  offers:any = [];
  page:any = 1;
  limit:any = "10";
  timeFrame:any = "today";
  max_num = -1;

  modal:any = { open:false, 'cntx':'', obj:{} }
  sideModal:any = { open:false, 'cntx':'', obj:{} }
  constructor(public languageService:LanguageServiceService,public system:SystemService, public http:HttpClient){
  }

  trackId(index : number, order: any) {
    return order ? order.uid : undefined;
  };

  trackName(index : number, product: any) {
    return product ? product.name : undefined;
  };

  openModal(cntx, obj) { 
    this.modal = { open:true, 'cntx':cntx, 'obj':JSON.parse(JSON.stringify(obj)) };
     
    if(cntx == 'mark-accept') {
      this.modal.obj.advance_value = "10";
    } else if(cntx == 'add-order'){
      this.RetrieveCity();
      this.LoadEcontTemplates();
      let dt = new Date();
      this.modal.obj.order_date = this.returnPaddedZero(dt.getDate(), 2) + "." + this.returnPaddedZero(dt.getMonth() + 1, 2) + "." + dt.getFullYear();
      this.http.get("https://api.nexa.bg/orders/max-num", {
        withCredentials: true
      })
      .subscribe(response => { this.modal.obj.order_id = response['max_num']; });
  
    } else if(cntx == 'edit-order') {
      if(this.modal.obj.processed_by == '' || this.modal.obj.processed_by == 'null') {
        this.modal.obj.processed_by = this.system.ReturnMe().first_name + " " + this.system.ReturnMe().last_name;
        this.http.put("https://api.nexa.bg/orders/" + this.modal.obj.uid, { 'order':this.modal.obj}, {
          withCredentials: true
        })
        .subscribe(response => {});
        this.modal.obj.items = [];
        let products_split = this.modal.obj.products.split(";");
        let price_total = 0.00;
        for(var pi = 0; pi < products_split.length - 1; pi++) {
          let product_split = products_split[pi].split("|");
          this.modal.obj.items.push({ "name":product_split[0], "price":parseFloat(product_split[1]), "unit":product_split[2], "quantity":product_split[3], "vat":parseInt(product_split[4]) })
          price_total += parseFloat(product_split[1]) * (1 + (parseInt(product_split[4]) / 100));
        }
  
        this.modal.obj.receipient_name = this.modal.obj.client_name;
        this.modal.obj.receipient_phone = this.modal.obj.phone;
        this.modal.obj.receipient_city = "";
        this.modal.obj.recipient_type = "office";
        this.modal.obj.receipient_office = '';
        this.modal.obj.receipient_office_code = '';
        this.modal.obj.offices = [];
        this.modal.obj.discountAmount = 0.00;
        this.modal.obj.discountDescription = '';
        this.modal.obj.cargo_weight = 1;
        this.modal.obj.cd_payment_price = this.returnFixed(price_total);
        this.modal.obj.sms_addon = false;
        this.modal.obj.services = [];
        this.modal.obj.allowed_generate = false;
        this.modal.obj.generating = false;
        this.modal.obj.econt_templates = [];
        this.modal.obj.cargo_x = '';
        this.modal.obj.cargo_y = '';
        this.modal.obj.cargo_z = '';
        this.modal.obj.cargo_description = ' ';
        this.modal.obj.client_phone = this.modal.obj.phone;
        this.modal.obj.order_id = this.modal.obj.uid;
        this.modal.obj.shipping_method = "econt";
        let new_date = this.modal.obj.date_added;
        let split_date = new_date.split("T")[0].split("-");
        this.modal.obj.order_date = split_date[2] + "." + split_date[1] + "." + split_date[0];
        this.RetrieveCity();
        this.LoadEcontTemplates();
      }else{
        this.modal.open = false;
        alert('Не може да редактирате поръчката докато тя се обработва от друг потребител!');
      }
     
    }
  }
  closeModal() {
    if(this.modal.cntx == "edit-order"){
      delete this.modal.obj.cities;
      delete this.modal.obj.offices;
      this.modal.obj.processed_by = "";
      this.http.put("https://api.nexa.bg/orders/" + this.modal.obj.uid, { 'order':this.modal.obj}, {
        withCredentials: true
      })
      .subscribe(response => {});
    }
    this.modal.open = false;

  }
  
  switchType() { 
    this.system.GetOrders(this.status, this.page, this.limit, this.timeFrame);
  }

  switchLimit() {
    this.system.GetOrders(this.status, this.page, this.limit, this.timeFrame);
  }


  ngOnInit() {
    this.system.GetOrders(this.status, this.page, this.limit, this.timeFrame);
    clearInterval(this.fetch_order_interval);
    this.fetch_order_interval = setInterval(()=>{
      // let previous_orders = JSON.parse(JSON.stringify(this.system.ReturnOrders()));
      this.system.GetOrders(this.status, this.page, this.limit, this.timeFrame);
      this.http.get("https://api.nexa.bg/orders/max-num" , {
        withCredentials: true
      }).subscribe(response => {
        if(this.max_num != -1 && this.max_num < response['max_num']) {
          this.system.AddNotification("Нова поръчка", "cart", 3);
        }
        this.max_num = response['max_num'];
      });
    }, 4000);
  }

  ngOnDestroy() {
    clearInterval(this.fetch_order_interval);
  }

  closeOpenStatus(order,ev) {
    if(order.allowed_close) { order.open_status = false; }
  }

  changeOrderStatus(order, status) {
    order.status = status;
    this.http.put("https://api.nexa.bg/orders/" + order.uid, { 'order': order } , {
      withCredentials: true
    })
    .subscribe(response => { this.system.GetOrders(this.status, this.page, this.limit, this.timeFrame); });
  }

  OpenSendOrder(order) {
    if(order.shipping_number != '') {
      this.openSideModal('send-order', { 'order':order, 'shipping_order':'', 'new_products':[], 'shipping_number':order.shipping_number  });
      setTimeout(()=>{
        this.GetEcontData();
        let split_products = this.sideModal.obj.order.products.split("+");
        for(var si = 0; si < split_products.length;si++) {
          this.GetProduct(split_products[si]);
        }
        console.log(this.sideModal.obj.order);
      }, 250);
    } else {
      this.openSideModal('send-order', { 'order':order, 'shipping_order':'', 'new_products':[]  });
      setTimeout(()=>{
        this.shippingInput.nativeElement.focus();
      }, 400);
    }

    let products_split = this.sideModal.obj.order.products.split(";");
    let product_split = products_split[0].split("|");
    this.sideModal.obj.product_general = product_split[0];
    this.sideModal.obj.order_found = true;
    this.GetClient(this.sideModal.obj.order.client_id);
  }

  GetProduct(product){
    let split_product = product.split("|");
    this.http.post("https://api.nexa.bg/storage-items/get-by-key-value", { data: { 'keys':['name'], 'values':['%' + split_product[0] + '%'], 'conditions':['WHERE'], 'signs':['LIKE'] } } , {
      withCredentials: true
    }).subscribe(response => { 
      let product = response['storage_items'][0];
      this.sideModal.obj.new_products.push({ 'uid':product.uid, 'name':product.name, 'image':product.image, 'sku':product.sku, 'barcode':product.ean, 'price':product.price, 'shop_item_id':product.shop_item_id, 'quantity':product.quantity });
      this.sideModal.obj.barcode = '';
      this.barcodeInput.nativeElement.focus();
    });
  }

  OpenTrackOrder(order) {
    this.openSideModal('track-order', { 'order':order, 'shipping_number':order.shipping_number  });
    setTimeout(()=>{
      this.GetEcontData();
    }, 250);
  }

  ScannedTracking() {
    this.sideModal.obj.searching = true;
    this.http.post("https://api.nexa.bg/orders/get-by-key-value", { data: { 'keys':['shipping_number'], 'values':[this.sideModal.obj.shipping_number], 'conditions':['WHERE'], 'signs':['='] } } , {
      withCredentials: true
    }).subscribe(response => { 
      if(response['orders'].length > 0) {
        this.sideModal.obj.order_found = true;
        this.sideModal.obj.order = response['orders'][0];

        let products_split = this.sideModal.obj.order.products.split(";");
        let product_split = products_split[0].split("|");
        this.sideModal.obj.product_general = product_split[0];
        this.GetClient(this.sideModal.obj.order.client_id);
        this.GetEcontData();
      }
      this.sideModal.obj.searching = false;
      setTimeout(()=>{
        this.barcodeInput.nativeElement.focus();
      }, 200);

    });
    // alert(this.sideModal.obj.shipping_number);
  }
// be-ni13@abv.bg 123456789Beni
  GetEcontData() {
    this.http.get("https://crm.nexa.bg/api/econt/econt_api.php?username=hi_tech&password=960828&shipment_number="+ this.sideModal.obj.shipping_number +"&action=track_shipment", {
      withCredentials: true
    }).subscribe(response => { 
      console.log(response['data']['info']['shipmentStatuses']);
      // if(response['data']['logged']) {
      if(this.sideModal.cntx == 'send-order') {
        this.sideModal.obj.delivery_price = response['data']['info']['shipmentStatuses']['0']['status']['totalPrice'];
        this.sideModal.obj.delivery_prices = response['data']['info']['shipmentStatuses']['0']['status']['services'];
      } else if(this.sideModal.cntx == 'track-order') {
        this.sideModal.obj.events = response['data']['info']['shipmentStatuses']['0']['status']['trackingEvents'];
      }
      // }
      console.log(response);
    }, (error) => { 
    });
    
  }

  ScannedProduct() {
    this.http.post("https://api.nexa.bg/storage-items/get-by-key-value", { data: { 'keys':['ean'], 'values':[this.sideModal.obj.barcode], 'conditions':['WHERE'], 'signs':['='] } } , {
      withCredentials: true
    }).subscribe(response => { 
      let product = response['storage_items'][0];
      this.sideModal.obj.new_products.push({ 'uid':product.uid, 'name':product.name, 'image':product.image, 'sku':product.sku, 'barcode':product.ean, 'price':product.price, 'shop_item_id':product.shop_item_id, 'quantity':product.quantity });
      this.sideModal.obj.barcode = '';
      this.barcodeInput.nativeElement.focus();
    });
  }

  ChangedProductField() {
    this.http.post("https://api.nexa.bg/storage-items/get-by-key-value", { data: { 'keys':['ean', 'sku', 'name'], 'values':['%' + this.sideModal.obj.barcode + '%', '%' + this.sideModal.obj.barcode + '%', '%' + this.sideModal.obj.barcode + '%'], 'conditions':['WHERE', 'OR', 'OR'], 'signs':['LIKE', 'LIKE', 'LIKE'] } } , {
      withCredentials: true
    }).subscribe(response => { 
      this.searched_products = response['storage_items'];
      console.log(response);
    });
  }

  SelectProduct(product) {
    this.sideModal.obj.new_products.push({ 'uid':product.uid, 'name':product.name, 'image':product.image, 'sku':product.sku, 'barcode':product.ean, 'price':product.price, 'shop_item_id':product.shop_item_id, 'quantity':product.quantity });
    this.sideModal.obj.barcode = '';
    setTimeout(()=>{
      this.barcodeInput.nativeElement.focus();
    }, 280);
  }

  GetClient(client_id) {
    this.http.get("https://api.nexa.bg/clients/" + client_id, {
      withCredentials: true
    })
    .subscribe(response => {
      this.sideModal.obj.client = response;
      console.log(response);
    },(error) => {                              //error() callback
      
    });
  }

  OpenProductSearch() {
    this.focusedSearch = true;
    // if(this.allow_close_product_search == true) {
    //   this.allow_close_product_search = false;
    //   clearTimeout(this.allow_close_product_search_timeout);
    //   this.allow_close_product_search_timeout = setTimeout(() => {
    //     this.allow_close_product_search = true;
    //   }, 250);
    // }
  }

  ClearProductSearch() {
    if(this.allow_close_product_search) {
      setTimeout(()=>{
        this.focusedSearch = false;
      }, 250)
    }

  }


  openStatus(order) {
    order.allowed_close = false;
    order.open_status = true;
    setTimeout(()=>{
      order.allowed_close = true;
    }, 250);
  }

  openOfferDropdown(offer) {
    offer.show = true;
    offer.allowedClose = false;
    setTimeout(()=> { offer.allowedClose = true; }, 250);
  }
  onOfferDropDownClose(ev, offer) {
    if(offer.allowedClose) {
      offer.show = false;
    }
  }

  RemoveOrder() {
    this.http.delete("https://api.nexa.bg/orders/" + this.modal.obj.uid, {
      withCredentials: true
    })
    .subscribe(response => { this.system.GetOrders(this.status, this.page, this.limit, this.timeFrame); this.closeModal(); });
  }

  MarkDenied() {
    this.modal.obj.status = 0;
    // this.http.put("https://api.nexa.bg/offers/" + this.modal.obj.uid, { 'offer':this.modal.obj }, {
    //   withCredentials: true
    // })
    // .subscribe(response => { this.system.GetOffers(this.status, this.page, this.limit); this.closeModal(); });
  }

  SendOrder() {
    this.sideModal.obj.order.status = 2;
    this.http.put("https://api.nexa.bg/orders/" + this.sideModal.obj.order.uid, { 'order':this.sideModal.obj.order }, {
      withCredentials: true
    })
    .subscribe(response => { 
      let integrations = this.system.ReturnIntegrations();
      for(var pi = 0; pi < this.sideModal.obj.new_products.length; pi++) {
        if(this.sideModal.obj.new_products[pi].quantity == 1) {
          this.http.get("https://www.mbparts-bg.com/index.php?route=extension/sitesynchronizer/sitesynchronizer/disableProduct&product_id="+ this.sideModal.obj.new_products[pi].shop_item_id +"&status=0&quantity=0")
          .subscribe(resp => {

          });
          this.http.put("https://api.nexa.bg/storage-items/" + this.sideModal.obj.new_products[pi].uid, { 'storage_item': { 'quantity':0 }}, { withCredentials: true })
          .subscribe(resp => {
            
          });
        }
        console.log(this.sideModal.obj.new_products[pi]);
      }
      this.system.GetOrders(this.status, this.page, this.limit, this.timeFrame); this.closeSideModal(); 
    });
  }

  goToPage(page) { this.page = page; this.system.GetOrders(this.status, this.page, this.limit, this.timeFrame); }
  NextPage() { this.page += 1; this.system.GetOrders(this.status, this.page, this.limit, this.timeFrame); }
  PrevPage() { this.page -= 1; this.system.GetOrders(this.status, this.page, this.limit, this.timeFrame); }
  StartPage() { this.page = 1; this.system.GetOrders(this.status, this.page, this.limit, this.timeFrame); }
  LastPage() { this.page = this.system.ReturnTotalOrdersPages(); this.system.GetOrders(this.status, this.page, this.limit, this.timeFrame); }

  openSideModal(cntx, obj) { 
    this.sideModal = { open:true, 'cntx':'', 'obj':obj }
    setTimeout(()=>{
      this.sideModal.cntx = cntx;
    }, 250);
  }
  closeSideModal() { this.sideModal.open = false;  }

  changedRow(idx, item) {
    // this.ChangedName(item);
    this.RetrieveProductForOrder(item);
    if(idx == this.modal.obj.items.length - 1) {
      if(this.modal.obj.items[idx].name.length != 0 || this.modal.obj.items[idx].quantity != 0 || this.modal.obj.items[idx].price != 0) {
        //this.invoice.items.push({ id:1, name:"", quantity:1, vat:20, unit:this.system.ReturnAvailableUnits()[0].name, price:0.00 });
        this.modal.obj.items.push(new Product(idx, "", 1, 20, this.system.ReturnAvailableUnits()[0].name, 0.00, ""));
      } 
    }
  }

  selectResult(item, result) {
    item.name = result.name;
    if(result.description !== undefined) {
      item.name += ' - ' + result.description;
    }
    item.price = result.price / 1.2;
    item.vat = result.vat;
    item.image = result.image;
    if(item.quantity == 0) { item.quantity = 1; }
    item.selecting = false;
  }

  selectClient(client){
    this.modal.obj.client_name = client.name;
    this.modal.obj.client_phone = client.phone;
    this.modal.obj.receipient_name = this.modal.obj.client_name;
    this.modal.obj.receipient_phone = this.modal.obj.client_phone;
  }

  SelectCity(city) {
    this.modal.obj.receipient_city = city.name;
    this.modal.obj.receipient_city_code = city.postCode;
    this.modal.obj.receipient_city_id = city.id;
    this.modal.obj.receipient_city_selected = true;
    if(this.modal.obj.recipient_type == 'office') {
      this.RetrieveOffices();
    } else {
      this.RetrieveStreets();
    }
  }

  SelectOffice(office){
    this.modal.obj.receipient_office = office.name;
    this.modal.obj.receipient_office_code = office.code;
    this.modal.obj.receipient_office_selected = true;
    this.CalculateShipment('office');
  }

  SelectStreet(street) {
    this.modal.obj.receipient_street = street.name;
    this.CalculateShipment('street');
  }

  RetrieveProductForOrder(item) {
    clearTimeout(this.modal.obj.check_product_timeout);
    this.modal.obj.check_product_timeout = setTimeout(()=>{
      this.http.post("https://api.nexa.bg/storage-items/get-by-key-value", { data: { 'keys':['ean', 'sku', 'name'], 'values':['%' + item.name + '%', '%' + item.name + '%', '%' + item.name + '%'], 'conditions':['WHERE', 'OR', 'OR'], 'signs':['LIKE', 'LIKE', 'LIKE'] } } , {
        withCredentials: true
      }).subscribe(response => { 
        this.modal.obj.results = [];
        let storage_items = response['storage_items'];
        for(var si = 0; si < storage_items.length; si++) {
          this.modal.obj.results.push({ 'name':storage_items[si].name, 'metric':'бр.', 'vat':20, 'price':storage_items[si].price, 'image':storage_items[si].image });
        }
      });
    }, 300);
  }

  RetrieveClientForOrder(by) {
    if(this.modal.obj.client_name.length > 2 || this.modal.obj.client_phone.length > 3) {
      this.modal.obj.receipient_name = this.modal.obj.client_name;
      this.modal.obj.receipient_phone = this.modal.obj.client_phone;
      if(by == 'phone') {
        this.http.post("https://api.nexa.bg/clients/get-by-key-value", { data: { 'keys':['phone'], 'values':['%' + this.modal.obj.client_phone + '%'], 'conditions':['WHERE'], 'signs':['LIKE'] } } , {
          withCredentials: true
        }).subscribe(response => { 
          this.modal.obj.client_suggestions = response['clients'];
          console.log(response);
        });
      } else {
        this.http.post("https://api.nexa.bg/clients/get-by-key-value", { data: { 'keys':['name'], 'values':['%' + this.modal.obj.client_name + '%'], 'conditions':['WHERE'], 'signs':['LIKE'] } } , {
          withCredentials: true
        }).subscribe(response => { 
          this.modal.obj.client_suggestions = response['clients'];
          console.log(response);
        });
      }
    }
  }

  RetrieveCity() {
    // clearTimeout(this.modal.obj.search_city_timeout);
    // this.modal.obj.search_city_timeout = setTimeout(()=>{
      this.http.get("https://crm.nexa.bg/api/econt/econt_api.php?action=get_local_cities&city=" + this.modal.obj.receipient_city , {
        withCredentials: true
      }).subscribe(response => { 
        this.modal.obj.cities = response['answer'];
        // console.log(response);
      });
    // }, 250);
  }

  RetrieveOffices() {
    clearTimeout(this.modal.obj.search_city_timeout);
    this.modal.obj.search_city_timeout = setTimeout(()=>{
      this.http.get("https://crm.nexa.bg/api/econt/econt_api.php?action=get_offices&city_id=" + this.modal.obj.receipient_city_id + "&username=hi_tech&password=960828" , {
        withCredentials: true
      }).subscribe(response => { 
        this.modal.obj.offices = response['answer']['info']['offices'];
        console.log(response);
      });
    }, 250);
    this.CalculateShipment("office");
  }

  RetrieveStreets() {
    clearTimeout(this.modal.obj.search_city_timeout);
    this.modal.obj.search_city_timeout = setTimeout(()=>{
      this.http.get("https://crm.nexa.bg/api/econt/econt_api.php?action=get_streets&city_id=" + this.modal.obj.receipient_city_id + "&username=hi_tech&password=960828" , {
        withCredentials: true
      }).subscribe(response => { 
        this.modal.obj.streets = response['answer']['info']['streets'];
        console.log(response);
      });
    }, 250);
    this.CalculateShipment("street");
  }

  CalculateShipment(type) {
    clearTimeout(this.modal.obj.calc_shipment_timeout);
    if(type == 'city') {
      console.log(this.modal.obj.cities.filter(city => city.name.toLowerCase() == this.modal.obj.receipient_city.toLowerCase()));
      if(this.modal.obj.cities.filter(city => city.name.toLowerCase() == this.modal.obj.receipient_city.toLowerCase()).length == 1) {
        this.modal.obj.receipient_city_selected = true;
        this.SelectCity(this.modal.obj.cities.filter(city => city.name.toLowerCase() == this.modal.obj.receipient_city.toLowerCase())[0])
      } else {
        this.modal.obj.receipient_city_selected = false;
      }
    } else if(type == "office") {
      if(this.modal.obj.offices.filter(office => office.name.toLowerCase() == this.modal.obj.receipient_office.toLowerCase()).length == 1) {
        this.modal.obj.receipient_office_selected = true;
        this.modal.obj.receipient_office_code = this.modal.obj.offices.filter(office => office.name.toLowerCase() == this.modal.obj.receipient_office.toLowerCase())[0].code;
      } else {
        this.modal.obj.receipient_office_selected = false;
        this.modal.obj.receipient_office_code = "";
      }
    }
    this.modal.obj.calc_shipment_timeout = setTimeout(()=>{
      this.http.post("https://crm.nexa.bg/api/econt/econt_api.php?action=create_shipment&username=hi_tech&password=960828&mode=calculate", {
        'receiver_name':this.modal.obj.receipient_name, 'receiver_phone':this.modal.obj.receipient_phone, 
        'receiver_city_code':this.modal.obj.receipient_city_code, 'receiver_city':this.modal.obj.receipient_city, 'reciver_city_id':this.modal.obj.receipient_city_id,
        'receiver_street':this.modal.obj.receipient_street, 'receiver_street_num':this.modal.obj.receipient_street_num,
        'office_code':this.modal.obj.receipient_office_code, 'cd_payment_price':parseFloat(this.modal.obj.cd_payment_price), 
        'package_weight':this.modal.obj.cargo_weight, 'package_description':this.modal.obj.cargo_description, 'items':this.modal.obj.items, 'sms_addon':this.modal.obj.sms_addon, 'receiver_type':this.modal.obj.recipient_type, 'cd_payment':true, 'payed_by':'1',
        'package_type':"PACK"
      } , {
        withCredentials: true
      }).subscribe(response => { 
        if(response['answer']['info'] !== undefined){
          this.modal.obj.services = response['answer']['info']['label']['services'];
          this.modal.obj.discountAmount = response['answer']['info']['label']['discountAmount'];
          this.modal.obj.discountDescription = response['answer']['info']['label']['discountDescription'];
          this.modal.obj.allowed_generate = true;
        } else {
          this.modal.obj.allowed_generate = false;
        }
      });
    }, 250);
  }

  GenerateShipment() {
    this.modal.obj.generating = true;
    setTimeout(()=> {
      this.http.post("https://crm.nexa.bg/api/econt/econt_api.php?action=create_shipment&username=hi_tech&password=960828&mode=create", {
        'receiver_name':this.modal.obj.receipient_name, 'receiver_phone':this.modal.obj.receipient_phone, 
        'receiver_city_code':this.modal.obj.receipient_city_code, 'receiver_city':this.modal.obj.receipient_city, 'reciver_city_id':this.modal.obj.receipient_city_id,
        'receiver_street':this.modal.obj.receipient_street, 'receiver_street_num':this.modal.obj.receipient_street_num,
        'office_code':this.modal.obj.receipient_office_code, 'cd_payment_price':parseFloat(this.modal.obj.cd_payment_price), 
        'package_weight':this.modal.obj.cargo_weight, 'package_description':this.modal.obj.cargo_description, 'items':this.modal.obj.items, 'sms_addon':this.modal.obj.sms_addon, 'receiver_type':this.modal.obj.recipient_type, 'cd_payment':true, 'payed_by':'1',
        'package_type':"PACK"
      } , {
        withCredentials: true
      }).subscribe(response => { 
        if(response['answer']['info'] !== undefined){
          this.modal.obj.generated = true;
          this.modal.obj.generated_label = response['answer']['info']['label']['shipmentNumber'];
        }
      });

    }, 500);
  }

  LoadEcontTemplates() {
    this.http.get("https://api.nexa.bg/econt-templates" , {
      withCredentials: true
    }).subscribe(response => { 
      console.log(response);
      let econt_templates = [];
      for(var ei = 0; ei < response['econt_templates'].length; ei++) {
        econt_templates.push({ 'description':response['econt_templates'][ei]['name'], 'data':JSON.parse(response['econt_templates'][ei]['data']) })
      }
      this.modal.obj.econt_templates = econt_templates;
      // console.log(response);
    });
  }

  CheckOrderBeforeAdd() {
    this.http.post("https://api.nexa.bg/clients/get-by-key-value", { data: { 'keys':['phone', 'name'], 'values':[this.modal.obj.client_phone, this.modal.obj.client_name], 'conditions':['WHERE', 'AND'], 'signs':['=', '='] } } , {
      withCredentials: true
    }).subscribe(response => { 
      if(response['clients'].length > 0) {
        this.AddOrder(response['clients'][0]['name'], response['clients'][0]['uid']);
      } else {
        let client_obj = { 'name':this.modal.obj.client_name, 'phone':this.modal.obj.client_phone, 'mol':'', 'type':'person', 'bulstat':'', 'address':'', 'city':'', 'country':'', 'website':'', 'email':'', 'registered_vat':'', 'client_group':0 };
        this.http.post("https://api.nexa.bg/clients", { 'client':client_obj}, {
          withCredentials: true
        })
        .subscribe(response => {
          if(response['success']) {
            this.AddOrder(this.modal.obj.client_name, response['uid']);
          }
        });
      }
    });
  }

  AddOrder(client_name, client_id) {
    let split_date_add = this.modal.obj.order_date.split(".");
    let dt_added = new Date(split_date_add[2] + "-" + split_date_add[1] + "-" + split_date_add[0]);
    let products = "";
    for(var pi = 0; pi < this.modal.obj.items.length; pi++) {
      if(this.modal.obj.items[pi].name.length > 0 || this.modal.obj.items[pi].price != 0) {
        products += this.modal.obj.items[pi].name + "|" + this.modal.obj.items[pi].price + "|" + this.modal.obj.items[pi].unit +  "|" + this.modal.obj.items[pi].quantity + "|" + this.modal.obj.items[pi].vat + ";";
      }
    }
    let date_now = new Date();
    dt_added.setHours(date_now.getHours());
    dt_added.setMinutes(date_now.getMinutes());
    dt_added.setSeconds(date_now.getSeconds());
    let reformed_dt_added = dt_added.getFullYear() + "-" + this.returnPaddedZero(dt_added.getMonth() + 1, 2) + "-" + this.returnPaddedZero(dt_added.getDate(), 2);
    let order_obj = { 'client_name':client_name, 'products':products, 'client_id':client_id, 'date_added':dt_added.toISOString(), total:this.returnTotal(),
      status:0,short_description:"", user_added:-1, date_executed:'0000-00-00', 'currency':'лв.', 'added_by':this.system.ReturnMe().first_name + " "  + this.system.ReturnMe().last_name, 'shipping_number':'', 'shipping_method':this.modal.obj.shipping_method };
    if(this.modal.obj.shipping_method == 'econt') {
      order_obj.shipping_number = this.modal.obj.generated_label;
    }
    this.http.post("https://api.nexa.bg/orders", { 'order':order_obj }, {
      withCredentials: true
    })
    .subscribe(response => {
      this.closeModal();
      this.system.GetOrders(this.status, this.page, this.limit, this.timeFrame);
    });
  }

  EditOrder() {
    let products = "";
    for(var pi = 0; pi < this.modal.obj.items.length; pi++) {
      if(this.modal.obj.items[pi].name.length > 0 || this.modal.obj.items[pi].price != 0) {
        products += this.modal.obj.items[pi].name + "|" + this.modal.obj.items[pi].price  + "|" + this.modal.obj.items[pi].unit + "|" + this.modal.obj.items[pi].quantity + "|" + this.modal.obj.items[pi].vat + ";";
      }
    }
    this.modal.obj.total = this.returnTotal();
    this.modal.obj.products = products;
    if(this.modal.obj.shipping_method == 'econt') {
      if(this.modal.obj.shipping_number == '') {
        this.modal.obj.shipping_number = this.modal.obj.generated_label;
      }
      
    }
    delete this.modal.obj.cities;
    delete this.modal.obj.offices;
    console.log(this.modal.obj);
    this.http.put("https://api.nexa.bg/orders/" + this.modal.obj.order_id, { 'order':this.modal.obj}, {
      withCredentials: true
    })
    .subscribe(response => {
      this.closeModal();
      this.system.GetOrders(this.status, this.page, this.limit, this.timeFrame);
    });
  }


  focusSelect(item) {
    item.selecting = true;
    this.modal.obj.results = [];
  }

  clearSelect(item) {
    setTimeout(() => {
      item.selecting = false;
    }, 250);
  }

  focusClientName(){
    this.modal.obj.client_select = true;
    this.modal.obj.client_suggestions = [];
  }

  clearFocusClientName() {
    setTimeout(() => {
      this.modal.obj.client_select = false;
    }, 250);
  }

  focusClientPhone(){
    this.modal.obj.client_phone_select = true;
    this.modal.obj.client_suggestions = [];
  }

  clearFocusClientPhone() {
    setTimeout(() => {
      this.modal.obj.client_phone_select = false;
    }, 250);
  }

  focusCity(){
    this.modal.obj.receipient_city_select = true;
    // this.modal.obj.cities = [];
  }

  clearFocusCity() {
    setTimeout(() => {
      this.modal.obj.receipient_city_select = false;
    }, 250);
  }

  focusOffice(){
    this.modal.obj.receipient_office_select = true;
    // this.modal.obj.cities = [];
  }

  clearFocusOffice() {
    setTimeout(() => {
      this.modal.obj.receipient_office_select = false;
    }, 250);
  }

  focusStreet(){
    this.modal.obj.receipient_street_select = true;
    // this.modal.obj.cities = [];
  }

  clearFocusStreet() {
    setTimeout(() => {
      this.modal.obj.receipient_street_select = false;
    }, 250);
  }

  OpenTemplateList() {
    this.modal.obj.template_open = true;
    this.modal.obj.allow_template_close = false;
    setTimeout(()=>{
      this.modal.obj.allow_template_close = true;
    }, 250);
  }

  CloseTemplateList() {
    if(this.modal.obj.allow_template_close){
      this.modal.obj.template_open = false;
    }
  }

  SelectTemplate(template) {
    this.modal.obj.cargo_weight = template.data.weight;
    this.modal.obj.cargo_description = template.description;
    this.modal.obj.cd_payment_price = template.data.cd_payment;
    this.CalculateShipment("");
    this.modal.obj.template_open = false;
  }

  AddTemplate() {
    let data = {};
    if(this.modal.obj.cargo_x != '' && this.modal.obj.cargo_y != '' && this.modal.obj.cargo_z != '') {
      data['size'] = this.modal.obj.cargo_x + "x" + this.modal.obj.cargo_y + "x" + this.modal.obj.cargo_z;
    } else {
      data['size'] = "-";
    }
    data['cd_payment'] = this.modal.obj.cd_payment_price;
    data['weight'] = this.modal.obj.cargo_weight;
    this.http.post("https://api.nexa.bg/econt-templates" , { 'econt_template':{ 'name':this.modal.obj.cargo_description, 'data':JSON.stringify(data) } }, {
      withCredentials: true
    }).subscribe(response => { 
      this.LoadEcontTemplates();
      this.system.AddNotification("Успешно добавен шаблон", "success", 3);
      // console.log(response);
    });
  }



  returnStandardTotal() {
    let total = 0.00;
    for(let i = 0; i < this.modal.obj.items.length; i++) {
      total += this.modal.obj.items[i].price * this.modal.obj.items[i].quantity;
    }
    return total.toFixed(2);
  }
  returnVatTotal() {
    let total = 0.00;
    for(let i = 0; i < this.modal.obj.items.length; i++) {
      total += (this.modal.obj.items[i].price * this.modal.obj.items[i].quantity) * ( this.modal.obj.items[i].vat / 100);
    }
    return total.toFixed(2);
  }
  returnTotal() {
    return (parseFloat(this.returnStandardTotal()) + parseFloat(this.returnVatTotal())).toFixed(2);
  }



  returnFormattedDate(dt) {
    let split_date = dt.split("T");
    let date_reorder = split_date[0].split("-");
    return date_reorder[2] + "." + date_reorder[1] + "." + date_reorder[0];
  }

  returnFormattedDateAndTime(dt) {
    let split_date = dt.split("T");
    let date_reorder = split_date[0].split("-");
    let time_split = split_date[1].split("Z");
    let time_split_dot = time_split[0].split(":");
    return date_reorder[2] + "." + date_reorder[1] + "." + date_reorder[0] + " " + time_split_dot[0] + ":" + time_split_dot[1] + ":" + time_split_dot[2];
  }

  returnProductCount(order) {
    return order.products.split(";").length;
  }
  
  ReturnPriceFixed(price) { return parseFloat(price).toFixed(2); }

  removeItemFromOrder(idx) {
    this.modal.obj.items.splice(idx, 1);
  }


  returnFixed(num) { return parseFloat(num).toFixed(2); }

  ReturnTotalWithoutVAT() {
    let total = 0.00;
    for(var pi = 0; pi < this.sideModal.obj.new_products.length; pi++) {
      total += parseFloat(this.sideModal.obj.new_products[pi].price) / 1.2;
    }
    return total.toFixed(2);
  }

  ReturnTotalVAT() {
    let total = 0.00;
    for(var pi = 0; pi < this.sideModal.obj.new_products.length; pi++) {
      total +=  parseFloat(this.sideModal.obj.new_products[pi].price)  - parseFloat(this.sideModal.obj.new_products[pi].price) / 1.2;
    }
    return total.toFixed(2);
  }

  ReturnTotal() {
    let total = 0.00;
    for(var pi = 0; pi < this.sideModal.obj.new_products.length; pi++) {
      total += parseFloat(this.sideModal.obj.new_products[pi].price);
    }
    return total.toFixed(2);
  }

  ReturnTotalWithDelivery() {
    let total = 0.00;
    for(var pi = 0; pi < this.sideModal.obj.new_products.length; pi++) {
      total += parseFloat(this.sideModal.obj.new_products[pi].price);
    }
    total += this.sideModal.obj.delivery_price;
    return total.toFixed(2);
  }

  ReturnTotalDelivery() {
    let total = 0.00;
    for(var si = 0; si < this.modal.obj.services.length; si++) {
      total += this.modal.obj.services[si].price;
    }
    return total;
  }

  ReturnTimeConverter(UNIX_timestamp){
    var a = new Date(UNIX_timestamp);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
    return time;
  }

  ReturnFilteredOffices(){
    if(this.modal.obj.receipient_office.length == 0) {
     return this.modal.obj.offices;
    } else {
     return this.modal.obj.offices.filter(office => office.name.toLowerCase().indexOf(this.modal.obj.receipient_office.toLowerCase()) > -1);
    }
  }

  ReturnFilteredStreets(){
    if(this.modal.obj.receipient_street.length == 0) {
     return this.modal.obj.streets;
    } else {
     return this.modal.obj.streets.filter(street => street.name.toLowerCase().indexOf(this.modal.obj.receipient_street.toLowerCase()) > -1);
    }
  }

  ReturnFilteredCity() {
    if(this.modal.obj.receipient_city.length == 0) {
      return this.modal.obj.cities;
     } else {
      return this.modal.obj.cities.filter(city => city.name.toLowerCase().indexOf(this.modal.obj.receipient_city.toLowerCase()) > -1);
     }
  }

  returnPaddedZero(num, len) {
    var n = Math.abs(num);
    var zeros = Math.max(0, len - Math.floor(n).toString().length );
    var zeroString = Math.pow(10,zeros).toString().substr(1);
    if( num < 0 ) {
        zeroString = '-' + zeroString;
    }

    return zeroString+n;
  }

  ReturnItemRow(products_string) {
    let arr = [];
    let products_split = products_string.split(";");
    for(var pi = 0; pi < products_split.length - 1; pi++) {
      let product_split = products_split[pi].split("|");
      arr.push({ "name":product_split[0], "price":parseFloat(product_split[1]), "unit":product_split[2], "quantity":product_split[3], "vat":parseInt(product_split[4]) })
    }
    return arr;
  }
}
