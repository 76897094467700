<!-- Modal -->
<div *ngIf="modal.open"  style=" background-color: rgba(0, 0, 0, 0.8)" class="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" x-show.transition.opacity="openModal">
    <div class="p-4 max-w-xl mx-auto relative absolute left-0 right-0 overflow-hidden mt-24">
        <div class="shadow absolute right-0 top-0 w-10 h-10 rounded-full bg-white text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer"
            (click)="modal.open = false">
            <svg class="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                    d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
            </svg>
        </div>

        <div class="shadow w-full rounded-lg bg-white overflow-hidden w-full block p-8" *ngIf="modal.cntx == 'remove-client'">
            
            <h2 class="font-bold text-2xl mb-6 text-gray-800 border-b pb-2">Премахване на клиент</h2>

            <div class="flex">
                <span>Сигурни ли сте, че искате да премахнете този клиент ?</span>
            </div>

            <div class="mt-8 text-right">
                <button type="button" (click)="modal.open = false" class="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded shadow-sm mr-2">
                    Не
                </button>	
                <button (click)="RemoveClient()" type="button" class="bg-gray-800 hover:bg-gray-700 text-white font-semibold py-2 px-4 border border-gray-700 rounded shadow-sm">
                    Да
                </button>	
            </div>
        </div>
    </div>
</div>
<!-- /Modal -->

<div class="side-modal" *ngIf="sideModal.open">
    <div class="backdrop"></div>
    <div class="modal" [ngClass]="{ 'active':sideModal.open && sideModal.cntx == 'add-client' }">
        <div class="header bg-positive text-white">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8 mt-2 float-left ml-4">
                <path d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z" />
            </svg>
              
            <span class="title float-left ml-2 font-bold">{{ languageService.ReturnTranslatedLabel('add_client') }}</span>

            <svg xmlns="http://www.w3.org/2000/svg" (click)="sideModal.open = false" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 float-right mt-3 mr-2">
                <path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clip-rule="evenodd" />
            </svg>
        </div>
        <div class="content flex flex-col w-full" style="overflow-y: auto; overflow-x: hidden; height: calc(100% - 50px);">
            <div class="search loading" *ngIf="sideModal.obj.searching">
                <div class="search__circle"></div>
                <div class="search__rectangle"></div>
              </div>
            <div class="mb-2 mt-2 w-full flex flex-col ml-4" *ngIf="!sideModal.obj.searching">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 mr-2" >{{ languageService.ReturnTranslatedLabel('label_client_name') }} <span class="text-red-500">*</span></label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" type="text">
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-col ml-4" *ngIf="!sideModal.obj.searching">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_client_type') }} <span class="text-red-500">*</span></label>
                <div class="flex w-full">
                    <select [(ngModel)]="sideModal.obj.type" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full mr-8">
                        <option value="company">{{ languageService.ReturnTranslatedLabel('label_company') }}</option>
                        <option value="person">{{ languageService.ReturnTranslatedLabel('label_person') }}</option>
                    </select> 
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-col ml-4" *ngIf="!sideModal.obj.searching">
                <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_client_group') }} <span class="text-red-500">*</span></label>
                <div class="flex w-full">
                    <select [(ngModel)]="sideModal.obj.client_group" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched  w-full mr-8">
                        <option *ngFor="let client_group of system.ReturnCompanyClientGroups()" value="{{ client_group.uid }}">{{ client_group.name }}</option>
                    </select>
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-col ml-4"  *ngIf="sideModal.obj.type == 'company' && !sideModal.obj.searching">
                <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_client_owner') }}</label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.mol" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" placeholder="Въведете МОЛ на фирмата" type="text">
                </div>
            </div>
            <div class="mb-2 mt-2 w-full flex flex-col ml-4" *ngIf="sideModal.obj.type == 'person' && !sideModal.obj.searching">
                <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_client_social') }}</label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.bulstat" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" placeholder="Въведете ЕГН на клиент" type="number">
                </div>
            </div>
            <div class="mb-2 mt-2 w-full flex flex-row ml-4" *ngIf="sideModal.obj.type == 'company' && !sideModal.obj.searching">
                <div class="w-2/4 flex flex-col" *ngIf="sideModal.obj.type == 'company'">
                    <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_client_eik') }}</label>
                    <div class="flex w-full" style="position:relative;">
                        <input [(ngModel)]="sideModal.obj.bulstat" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" placeholder="Въведете ЕИК/БУЛСТАТ на фирма" type="number">
                        <div alt="Търсене на данни за тази фирма" title="Търсене на данни за тази фирма" data-tooltip-target="tooltip-default"  (click)="GetClientData()" style="position: absolute;width: 40px;height: 40px;right: 33px;top: 1px;" class="py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-positive rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 ml-2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                              </svg>                              
                        </div>
                    </div>
                </div>

                <div class="w-2/4 flex flex-col ml-4" *ngIf="sideModal.obj.type == 'company' && !sideModal.obj.searching">
                    <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_vat_number') }}</label>
                    <div class="flex w-full">
                        <input [(ngModel)]="sideModal.obj.registered_vat" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" placeholder="Ако фирмата е регистрирана по ДДС, въведете ДДС номер" type="text">
                    </div>
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-col ml-4" *ngIf="sideModal.obj.type == 'company' && !sideModal.obj.searching">
                <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_address') }}</label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.address" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name"  placeholder="Въведете адрес на регистрация" type="text">
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-row ml-4" *ngIf="sideModal.obj.type == 'company' && !sideModal.obj.searching">
                <div class="w-2/4 flex flex-col" *ngIf="sideModal.obj.type == 'company'">
                    <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_city_town') }}</label>
                    <div class="flex w-full">
                        <input [(ngModel)]="sideModal.obj.city" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" placeholder="Въведете град на регистрация" type="text">
                    </div>
                </div>
    
                <div class="w-2/4 flex flex-col ml-4" *ngIf="sideModal.obj.type == 'company' && !sideModal.obj.searching">
                    <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_country') }}</label>
                    <div class="flex w-full">
                        <input [(ngModel)]="sideModal.obj.country" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" placeholder="Въведете държава на регистрация" type="text">
                    </div>
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-row ml-4" *ngIf="!sideModal.obj.searching">
                <div class="w-2/4 flex flex-col">
                    <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_email') }}</label>
                    <div class="flex w-full">
                        <input [(ngModel)]="sideModal.obj.email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" placeholder="Въведете е-мейл" type="text">
                    </div>
                </div>
    
                <div class="w-2/4 flex flex-col ml-4" *ngIf="!sideModal.obj.searching">
                    <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_phone') }}</label>
                    <div class="flex w-full">
                        <input [(ngModel)]="sideModal.obj.phone" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" placeholder="Въведете телефон" type="text">
                    </div>
                </div>
            </div>

            <button [ngClass]="{ 'disabled':!ReturnIsAllowedToAddClient() }" *ngIf="!sideModal.obj.searching" class="flex items-center justify-center w-full ml-4 mr-4 mt-4 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-positive rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600" (click)="AddClient()">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>                      

                <span>{{ languageService.ReturnTranslatedLabel('add_client') }}</span>
            </button>
        </div>
    </div>

    <div class="modal" [ngClass]="{ 'active':sideModal.open && sideModal.cntx == 'edit-client' }">
        <div class="header bg-positive text-white">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8 mt-2 float-left ml-4">
                <path d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z" />
            </svg>
              
            <span class="title float-left ml-2 font-bold">{{ languageService.ReturnTranslatedLabel('edit_client') }}</span>

            <svg xmlns="http://www.w3.org/2000/svg" (click)="sideModal.open = false" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 float-right mt-3 mr-2">
                <path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clip-rule="evenodd" />
            </svg>
        </div>
        <div class="content flex flex-col w-full" style="overflow-y: auto; overflow-x: hidden; height: calc(100% - 50px);">
            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 mr-2" >{{ languageService.ReturnTranslatedLabel('label_client_name') }} <span class="text-red-500">*</span></label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" type="text">
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_client_type') }} <span class="text-red-500">*</span></label>
                <div class="flex w-full">
                    <select [(ngModel)]="sideModal.obj.type" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full mr-8">
                        <option value="company">{{ languageService.ReturnTranslatedLabel('label_company') }}</option>
                        <option value="person">{{ languageService.ReturnTranslatedLabel('label_person') }}</option>
                    </select> 
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_client_group') }} <span class="text-red-500">*</span></label>
                <div class="flex w-full">
                    <select [(ngModel)]="sideModal.obj.client_group" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched  w-full mr-8">
                        <option *ngFor="let client_group of system.ReturnCompanyClientGroups()" value="{{ client_group.uid }}">{{ client_group.name }}</option>
                    </select>
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-col ml-4"  *ngIf="sideModal.obj.type == 'company'">
                <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_client_owner') }}</label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.mol" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" placeholder="Въведете МОЛ на фирмата" type="text">
                </div>
            </div>
            <div class="mb-2 mt-2 w-full flex flex-col ml-4" *ngIf="sideModal.obj.type == 'company'">
                <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_client_eik') }}</label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.bulstat" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" placeholder="Въведете ЕИК/БУЛСТАТ на фирма" type="number">
                </div>
            </div>
            <div class="mb-2 mt-2 w-full flex flex-col ml-4" *ngIf="sideModal.obj.type == 'person'">
                <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_client_social') }}</label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.bulstat" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" placeholder="Въведете ЕГН на клиент" type="number">
                </div>
            </div>
            <div class="mb-2 mt-2 w-full flex flex-col ml-4" *ngIf="sideModal.obj.type == 'company'">
                <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_vat_number') }}</label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.registered_vat" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" placeholder="Ако фирмата е регистрирана по ДДС, въведете ДДС номер" type="text">
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-col ml-4" *ngIf="sideModal.obj.type == 'company'">
                <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_address') }}</label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.address" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name"  placeholder="Въведете адрес на регистрация" type="text">
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-row ml-4" *ngIf="sideModal.obj.type == 'company'">
                <div class="w-2/4 flex flex-col" *ngIf="sideModal.obj.type == 'company'">
                    <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_city_town') }}</label>
                    <div class="flex w-full">
                        <input [(ngModel)]="sideModal.obj.city" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" placeholder="Въведете град на регистрация" type="text">
                    </div>
                </div>
    
                <div class="w-2/4 flex flex-col ml-4" *ngIf="sideModal.obj.type == 'company'">
                    <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_country') }}</label>
                    <div class="flex w-full">
                        <input [(ngModel)]="sideModal.obj.country" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" placeholder="Въведете държава на регистрация" type="text">
                    </div>
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-row ml-4">
                <div class="w-2/4 flex flex-col">
                    <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_email') }}</label>
                    <div class="flex w-full">
                        <input [(ngModel)]="sideModal.obj.email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" placeholder="Въведете е-мейл" type="text">
                    </div>
                </div>
    
                <div class="w-2/4 flex flex-col ml-4">
                    <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_phone') }}</label>
                    <div class="flex w-full">
                        <input [(ngModel)]="sideModal.obj.phone" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" placeholder="Въведете телефон" type="text">
                    </div>
                </div>
            </div>


            <button [ngClass]="{ 'disabled':!ReturnIsAllowedToAddClient() }" class="flex items-center justify-center w-full ml-4 mr-4 mt-4 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-positive rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600" (click)="EditClient()">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>                      

                <span>{{ languageService.ReturnTranslatedLabel('edit_client') }}</span>
            </button>
        </div>
    </div>
</div>

<section class="min-h-screen custom-min-h-screen bg-nexa text-gray-800">
    <div class="flex flex-col p-4">
        <div class="float-left w-full p-2">
            <div class="relative float-left font-bold text-black" style="font-size:24px;">{{ languageService.ReturnTranslatedLabel('menu_clients') }}</div>

            <div class="flex items-center float-right gap-x-3">

                <button class="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-positive rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600" (click)="openSideModal('add-client', { name:'', mol:'', type:'company', bulstat:'', address:'', city:'',country:'', website:'', email:'', phone:'',registered_vat:'', client_group:'0' })">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>                      
    
                    <span>{{ languageService.ReturnTranslatedLabel('add_client') }}</span>
                </button>
            </div>
        </div>
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 mt-2" style="padding-top:0px;padding-bottom:0px">
                <div class="p-3 bg-white md:rounded-lg h-14 border border-gray-200">
                    <div class="float-left" style="width:240px;">
                        <input type="text" class="h-8 p-2 font-semibold text-xs text-black rounded-lg search-field" (keyup)="SearchClients()" [(ngModel)]="search" placeholder="{{ languageService.ReturnTranslatedLabel('label_search') }}" />
                        <div class="btn float-right rounded-lg bg-positive text-white text-center search-btn">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                            </svg>                              
                        </div>
                    </div>
                    <div class="float-left ml-2">
                        <select [(ngModel)]="type" (change)="switchType()" class="h-8 p-2 font-semibold text-xs text-black rounded-lg">
                            <option value="all">{{ languageService.ReturnTranslatedLabel('label_all_clients') }}</option>
                            <option value="company">{{ languageService.ReturnTranslatedLabel('label_company') }}</option>
                            <option value="person">{{ languageService.ReturnTranslatedLabel('label_person') }}</option>
                        </select>
                    </div>
                    <span class="float-right">
                        <div class="float-left ml-2">
                            <span class="mr-2 text-xs font-bold text-gray-600">{{ languageService.ReturnTranslatedLabel('label_results_per_page') }}</span>
                            <select (change)="switchLimit()" [(ngModel)]="limit" class="h-8 p-2 font-semibold text-xs text-black rounded-lg">
                                <option>10</option>
                                <option>20</option>
                                <option>50</option>
                                <option>100</option>
                            </select>
                        </div>
                        <span class="page-btn" (click)="StartPage()"  [ngClass]="{ 'disabled':system.ReturnCurrentClientPages() < 2 }">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
                            </svg>                                                      
                        </span>
                        <span class="page-btn" (click)="PrevPage()"  [ngClass]="{ 'disabled':system.ReturnCurrentClientPages() < 2 }">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                              </svg>                              
                        </span>
                        <span class="page-btn hover:bg-positive hover:text-white" *ngIf="system.ReturnCurrentClientPages() > 1" (click)="goToPage( system.ReturnCurrentClientPages() - 1)">{{ system.ReturnCurrentClientPages() - 1 }}</span>
                        <span class="page-btn bg-positive text-white hover:bg-positive hover:text-white" (click)="goToPage( system.ReturnCurrentClientPages())">{{ system.ReturnCurrentClientPages() }}</span>
                        <span class="page-btn hover:bg-positive hover:text-white" *ngIf="system.ReturnTotalClientPages() > system.ReturnCurrentClientPages()" (click)="goToPage( system.ReturnCurrentClientPages() + 1)">{{ system.ReturnCurrentClientPages() + 1 }}</span>
                        <span class="page-btn" (click)="NextPage()" [ngClass]="{ 'disabled':system.ReturnCurrentClientPages() == system.ReturnTotalClientPages() }">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>                              
                        </span>
                        <span class="page-btn" (click)="LastPage()" [ngClass]="{ 'disabled':system.ReturnCurrentClientPages() == system.ReturnTotalClientPages() }">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
                            </svg>                              
                        </span>
                    </span>
                </div>
            </div>
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div class="overflow-hidden border border-gray-200 md:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500">
                                    <button class="flex items-center gap-x-3 focus:outline-none">
                                        <span>{{ languageService.ReturnTranslatedLabel('label_client') }}</span>

                                        <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                            <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                            <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                        </svg>
                                    </button>
                                </th>

                                <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                    {{ languageService.ReturnTranslatedLabel('label_client_group') }}
                                </th>

                                <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                    {{ languageService.ReturnTranslatedLabel('label_phone') }}
                                </th>

                                <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                    {{ languageService.ReturnTranslatedLabel('label_bulstat') }}
                                </th>

                                <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">{{ languageService.ReturnTranslatedLabel('label_registered_vat') }}</th>

                                <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">{{ languageService.ReturnTranslatedLabel('label_owed') }}</th>

                                <th scope="col" class="relative py-3.5 px-4">
                                    <span class="sr-only">Edit</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr *ngIf="system.ReturnClients().length == 0">
                                <td class="text-center p-4 bg-gray-100" colspan="6">{{ languageService.ReturnTranslatedLabel('label_no_clients') }}</td>
                            </tr>
                            <tr *ngFor="let client of system.ReturnClients()">
                                <td class="px-4 py-4 text-sm font-medium whitespace-nowrap">
                                    <div>
                                        <h2 class="font-medium text-gray-800" *ngIf="client.name != ''">{{ client.name }}</h2>
                                        <h2 class="font-medium text-gray-800" *ngIf="client.name == ''">Няма въведено име</h2>
                                        <p class="text-sm font-normal text-gray-600" *ngIf="client.type == 'company'">{{ languageService.ReturnTranslatedLabel('label_company') }}</p>
                                        <p class="text-sm font-normal text-gray-600" *ngIf="client.type == 'person'">{{ languageService.ReturnTranslatedLabel('label_person') }}</p>
                                    </div>
                                </td>
                                <td class="px-4 py-4 text-sm whitespace-nowrap">
                                    {{ system.ReturnClientGroup(client.client_group)['name'] }} ({{ system.ReturnClientGroup(client.client_group)['discount'] }}%)
                                </td>
                                <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                    {{ client.phone }}
                                </td>
                                <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                    {{ client.bulstat }}
                                </td>
                                <td class="px-4 py-4 text-sm whitespace-nowrap">
                                    <div class="bg-emerald-400 h-max w-max rounded-full text-white font-bold" *ngIf="client.registered_vat != ''">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 p-1">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                        </svg>                                                                         
                                    </div>
                                    <div class="bg-red-400 h-max w-max rounded-full text-white font-bold" *ngIf="client.registered_vat == ''">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 p-1">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                          </svg>                                                                                                                
                                    </div>
                                </td>
                                <td class="px-4 py-4 text-sm whitespace-nowrap">
                                    <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60">
                                        0.00 лв
                                    </div>
                                    <!-- <div class="flex items-center" *ngIf="invoice.type_payment == '1'">По банков път</div>
                                    <div class="flex items-center" *ngIf="invoice.type_payment == '2'">Пощенски паричен превод</div> -->
                                </td>

                                <td class="px-4 py-4 text-sm whitespace-nowrap">
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2 relative group" (click)="openSideModal('edit-client', client)">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                                            <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                                        </svg>     
                                        
                                        <!-- Tooltip -->
                                        <div style="padding-left:10px;padding-right:10px;" class="opacity-0 bg-black text-white text-sm rounded-md py-2 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-100">
                                            {{ languageService.ReturnTranslatedLabel('label_hint_edit_client') }}
                                        </div>
                                    </button>
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2 relative group" (click)="openModal('remove-client', client)">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path d="M10.375 2.25a4.125 4.125 0 100 8.25 4.125 4.125 0 000-8.25zM10.375 12a7.125 7.125 0 00-7.124 7.247.75.75 0 00.363.63 13.067 13.067 0 006.761 1.873c2.472 0 4.786-.684 6.76-1.873a.75.75 0 00.364-.63l.001-.12v-.002A7.125 7.125 0 0010.375 12zM16 9.75a.75.75 0 000 1.5h6a.75.75 0 000-1.5h-6z" />
                                        </svg> 
                                        
                                        <!-- Tooltip -->
                                        <div style="padding-left:10px;padding-right:10px;" class="opacity-0 bg-black text-white text-sm rounded-md py-2 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-100">
                                            {{ languageService.ReturnTranslatedLabel('label_hint_remove_client') }}
                                        </div>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>