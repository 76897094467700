<!-- Modal -->
<div *ngIf="modal.open"  style=" background-color: rgba(0, 0, 0, 0.8)" class="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" x-show.transition.opacity="openModal">
    <div class="p-4 max-w-xl mx-auto relative absolute left-0 right-0 overflow-hidden mt-24">
        <div class="shadow absolute right-0 top-0 w-10 h-10 rounded-full bg-white text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer"
            (click)="modal.open = false">
            <svg class="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                    d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
            </svg>
        </div>

        <div class="shadow w-full rounded-lg bg-white overflow-hidden w-full block p-8" *ngIf="modal.cntx == 'remove-automatic'">
            
            <h2 class="font-bold text-2xl mb-6 text-gray-800 border-b pb-2">Премахване на автоматично таксуване</h2>

            <div class="flex">
                <span>Сигурни ли сте, че искате да премахнете автоматичното таксуване ?</span>
            </div>

            <div class="mt-8 text-right">
                <button type="button" (click)="modal.open = false" class="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded shadow-sm mr-2">
                    Не
                </button>	
                <button (click)="RemoveAutomatic()" type="button" class="bg-gray-800 hover:bg-gray-700 text-white font-semibold py-2 px-4 border border-gray-700 rounded shadow-sm">
                    Да
                </button>	
            </div>
        </div>

    </div>
</div>
<!-- /Modal -->


<!-- <section class="min-h-screen custom-min-h-screen bg-nexa bg-nexa text-gray-800">
    <div class="flex flex-col p-4" style="padding-top:0px;padding-bottom:0px">
        <div class="float-left w-full p-2 mt-4">
            <div class="relative float-left font-bold text-black" style="font-size:24px;">{{ languageService.ReturnTranslatedLabel('menu_automatic_invoices') }}</div>

            <div class="flex items-center float-right gap-x-3">

                <button class="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-positive rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600" routerLink="/add-automatic-invoice">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>                      
    
                    <span>{{ languageService.ReturnTranslatedLabel('add_automatic') }}</span>
                </button>
            </div>
        </div>
        <div class="-mx-4 sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 mt-2" style="padding-top:0px;padding-bottom:0px">
                <div class="p-3 bg-white md:rounded-lg h-14 border border-gray-200">
                    <div class="float-left" style="width:240px;">
                        <input type="text" class="h-8 p-2 font-semibold text-xs text-black rounded-lg search-field" (keyup)="SearchInvoices()" [(ngModel)]="search" placeholder="{{ languageService.ReturnTranslatedLabel('label_search') }}" />
                        <div class="btn float-right rounded-lg bg-positive text-white text-center search-btn">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                            </svg>                              
                        </div>
                    </div>
                    <span class="float-right">
                        <div class="float-left ml-2">
                            <span class="mr-2 text-xs font-bold text-gray-600">{{ languageService.ReturnTranslatedLabel('label_results_per_page') }}</span>
                            <select (change)="switchLimit()" [(ngModel)]="limit" class="h-8 p-2 font-semibold text-xs text-black rounded-lg">
                                <option>10</option>
                                <option>20</option>
                                <option>50</option>
                                <option>100</option>
                            </select>
                        </div>
                        <span class="page-btn" (click)="StartPage()"  [ngClass]="{ 'disabled':system.ReturnCurrentAutomaticInvoicePages() < 2 }">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
                            </svg>                                                      
                        </span>
                        <span class="page-btn" (click)="PrevPage()"  [ngClass]="{ 'disabled':system.ReturnCurrentAutomaticInvoicePages() < 2 }">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                              </svg>                              
                        </span>
                        <span class="page-btn hover:bg-positive hover:text-white" *ngIf="system.ReturnCurrentAutomaticInvoicePages() > 1" (click)="goToPage( system.ReturnCurrentAutomaticInvoicePages() - 1)">{{ system.ReturnCurrentAutomaticInvoicePages() - 1 }}</span>
                        <span class="page-btn bg-positive text-white hover:bg-positive hover:text-white" (click)="goToPage( system.ReturnCurrentAutomaticInvoicePages())">{{ system.ReturnCurrentAutomaticInvoicePages() }}</span>
                        <span class="page-btn hover:bg-positive hover:text-white" *ngIf="system.ReturnTotalAutomaticInvoicePages() > system.ReturnCurrentAutomaticInvoicePages()" (click)="goToPage( system.ReturnCurrentAutomaticInvoicePages() + 1)">{{ system.ReturnCurrentAutomaticInvoicePages() + 1 }}</span>
                        <span class="page-btn" (click)="NextPage()" [ngClass]="{ 'disabled':system.ReturnCurrentAutomaticInvoicePages() == system.ReturnTotalAutomaticInvoicePages() }">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>                              
                        </span>
                        <span class="page-btn" (click)="LastPage()" [ngClass]="{ 'disabled':system.ReturnCurrentAutomaticInvoicePages() == system.ReturnTotalAutomaticInvoicePages() }">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
                            </svg>                              
                        </span>
                    </span>
                </div>
            </div>
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 mt-2" style="padding-top:0px;padding-bottom:0px">
                <div class="border border-gray-200  md:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200 ">
                        <thead class="bg-white">
                            <tr class="text-sm font-bold text-black">
                                <th scope="col" class="px-4 py-3.5 text-left rtl:text-right">
                                    <button class="flex items-center gap-x-3 focus:outline-none">
                                        <span>{{ languageService.ReturnTranslatedLabel('label_client') }}</span>
                                    </button>
                                </th>

                                <th scope="col" class="px-4 py-3.5 text-left rtl:text-right">
                                    <button class="flex items-center gap-x-3 focus:outline-none">
                                        {{ languageService.ReturnTranslatedLabel('label_information') }}
                                    </button>
                                </th>

                                <th scope="col" class="px-4 py-3.5 text-left rtl:text-right">
                                    <button class="flex items-center gap-x-3 focus:outline-none"> 
                                        {{ languageService.ReturnTranslatedLabel('label_payment_method') }}
                                    </button>
                                </th>
                                    

                                <th scope="col" class="px-4 py-3.5 text-left rtl:text-right">
                                    <button class="flex items-center gap-x-3 focus:outline-none">
                                        {{ languageService.ReturnTranslatedLabel('label_price') }}
                                    </button>
                                </th>

                                <th scope="col" class="relative py-3.5 px-4">
                                    <span class="sr-only">Edit</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200  ">
                            <tr *ngIf="system.ReturnAutomaticInvoices().length == 0">
                                <td class="text-center p-4 bg-gray-100" colspan="6">Няма въведени автоматични таксувания</td>
                            </tr>
                            <tr *ngFor="let invoice of system.ReturnAutomaticInvoices()">
                                <td class="px-4 py-2 text-sm whitespace-nowrap">
                                    <div>
                                        <h4 class="text-gray-700 ">{{ invoice.client_name }}</h4>
                                    </div>
                                </td>
                                <td class="px-4 py-2 text-sm whitespace-nowrap">
                                    <div>
                                        <p class="text-gray-500 ">Издаване на фактура на всяко {{invoice.send_day}}-то число</p>
                                    </div>
                                </td>
                                <td class="px-4 py-2 text-sm whitespace-nowrap">
                                    <div class="flex items-center" *ngIf="invoice.type_payment == '1'">{{ languageService.ReturnTranslatedLabel('label_payment_bank') }}</div>
                                    <div class="flex items-center" *ngIf="invoice.type_payment == '2'">{{ languageService.ReturnTranslatedLabel('label_payment_post') }}</div>
                                    <div class="flex items-center" *ngIf="invoice.type_payment == '3'">{{ languageService.ReturnTranslatedLabel('label_payment_cash') }}</div>
                                </td>


                                <td class="px-4 py-2 text-sm whitespace-nowrap">
                                    <div class="flex item-center">
                                        {{ invoice.total_value }} {{ invoice.currency }}
                                    </div>
                                </td>

                                <td class="px-4 py-2 text-sm whitespace-nowrap text-right">
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2 group relative" routerLink="/edit-automatic-invoice/{{ invoice.uid }}">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                                            <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                                        </svg>   
                                        
                            
                                        <div style="padding-left:10px;padding-right:10px;" class="opacity-0 bg-black text-white text-sm rounded-md py-2 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-100">
                                            {{ languageService.ReturnTranslatedLabel('label_hint_edit_automatic') }}
                                        </div>
                                    </button>
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2  group relative"  routerLink="/add-invoice/invoice/{{invoice.uid}}">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 013.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0121 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 017.5 16.125V3.375z" />
                                            <path d="M15 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0017.25 7.5h-1.875A.375.375 0 0115 7.125V5.25zM4.875 6H6v10.125A3.375 3.375 0 009.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625V7.875C3 6.839 3.84 6 4.875 6z" />
                                        </svg>
                                          
                                
                                        <div style="padding-left:10px;padding-right:10px;" class="opacity-0 bg-black text-white text-sm rounded-md py-2 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-100">
                                            {{ languageService.ReturnTranslatedLabel('label_hint_duplicate_automatic') }}
                                        </div>
                                    </button>
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100  group relative" (click)="openModal('remove-automatic', invoice)">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clip-rule="evenodd" />
                                        </svg>    
                                        
                       
                                        <div style="padding-left:10px;padding-right:10px;" class="opacity-0 bg-black text-white text-sm rounded-md py-2 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-100">
                                            {{ languageService.ReturnTranslatedLabel('label_hint_remove_automatic') }}
                                        </div>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section> -->


<div class="container">
    <div class="table-header">
        <h2 class="az">{{ languageService.ReturnTranslatedLabel('menu_automatic_invoices') }}</h2>

        <button class="add-invoice"  (click)="openSideModal('stats', { 'type':'documents', 'date_start':'01.09.2023' , 'date_end':'30.09.2023'})">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>                      

            <span>{{ languageService.ReturnTranslatedLabel('add_automatic') }}</span>
        </button>
        <input class="az-input" type="text" (keyup)="SearchInvoices()" [(ngModel)]="search" placeholder="{{ languageService.ReturnTranslatedLabel('label_search') }}" >
    </div>

    <table class="invoice-table">
        <thead>
            <tr>
                <th>{{ languageService.ReturnTranslatedLabel('label_client') }}</th>    
                <th>{{ languageService.ReturnTranslatedLabel('label_information') }}</th>
                <th>{{ languageService.ReturnTranslatedLabel('label_payment_method') }}</th>
                <th>{{ languageService.ReturnTranslatedLabel('label_price') }}</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="system.ReturnAutomaticInvoices().length == 0">
                <td class="text-center p-4 bg-gray-100" colspan="6">Няма въведени автоматични таксувания</td>
            </tr>
            <tr *ngFor="let invoice of system.ReturnAutomaticInvoices()">
                <td>{{ invoice.client_name }}</td>
                <td>
                    Издаване на фактура на всяко {{invoice.send_day}}-то число
                </td>
                <td>
                    <div class="flex items-center" *ngIf="invoice.type_payment == '1'">{{ languageService.ReturnTranslatedLabel('label_payment_bank') }}</div>
                    <div class="flex items-center" *ngIf="invoice.type_payment == '2'">{{ languageService.ReturnTranslatedLabel('label_payment_post') }}</div>
                    <div class="flex items-center" *ngIf="invoice.type_payment == '3'">{{ languageService.ReturnTranslatedLabel('label_payment_cash') }}</div>
                </td>
                <td>{{ invoice.total_value }} {{ invoice.currency }}</td>
                <td class="px-4 py-2 text-sm whitespace-nowrap text-right">
                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2 group relative" routerLink="/edit-automatic-invoice/{{ invoice.uid }}">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                            <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                            <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                        </svg>   
                        
                        <!-- Tooltip -->
                        <div style="padding-left:10px;padding-right:10px;" class="opacity-0 bg-black text-white text-sm rounded-md py-2 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-100">
                            {{ languageService.ReturnTranslatedLabel('label_hint_edit_automatic') }}
                        </div>
                    </button>
                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2  group relative"  routerLink="/add-invoice/invoice/{{invoice.uid}}">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                            <path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 013.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0121 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 017.5 16.125V3.375z" />
                            <path d="M15 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0017.25 7.5h-1.875A.375.375 0 0115 7.125V5.25zM4.875 6H6v10.125A3.375 3.375 0 009.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625V7.875C3 6.839 3.84 6 4.875 6z" />
                        </svg>
                        
                        <!-- Tooltip -->
                        <div style="padding-left:10px;padding-right:10px;" class="opacity-0 bg-black text-white text-sm rounded-md py-2 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-100">
                            {{ languageService.ReturnTranslatedLabel('label_hint_duplicate_automatic') }}
                        </div>
                    </button>
                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100  group relative" (click)="openModal('remove-automatic', invoice)">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                            <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clip-rule="evenodd" />
                        </svg>    
                        
                        <!-- Tooltip -->
                        <div style="padding-left:10px;padding-right:10px;" class="opacity-0 bg-black text-white text-sm rounded-md py-2 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-100">
                            {{ languageService.ReturnTranslatedLabel('label_hint_remove_automatic') }}
                        </div>
                    </button>
                </td>
            </tr>
               
           
        </tbody>
        <tfoot>
            <tr>
                <td colspan="7">
                    <div class="pagination-container">
                        <div class="results-per-page">
                            <label for="results">Резултати на страница</label>
                            <select id="results">
                                <option>10</option>
                                <option>20</option>
                                <option>50</option>
                            </select>
                        </div>
                        <div class="pagination">
                            <button class="prev disabled" (click)="PrevPage()"  [ngClass]="{ 'disabled':system.ReturnCurrentAutomaticInvoicePages() < 2 }">← Предишна</button>
                            <span class="page-info">{{ ((system.ReturnCurrentAutomaticInvoicePages() - 1) * 10) + 1 }} от {{ ((system.ReturnCurrentAutomaticInvoicePages()) * 10) }}</span>
                            <button class="next" (click)="NextPage()" [ngClass]="{ 'disabled':system.ReturnCurrentAutomaticInvoicePages() == system.ReturnTotalAutomaticInvoicePages() }">Следваща →</button>
                        </div>
                    </div>
                </td>
            </tr>
        </tfoot>
    </table>
  </div>