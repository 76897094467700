import { Component } from '@angular/core';
import { LanguageServiceService } from '../language-service.service';
import { SystemService } from '../system.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-storehouses',
  templateUrl: './storehouses.component.html',
  styleUrls: ['./storehouses.component.css']
})
export class StorehousesComponent {
  status:any = -1;
  offers:any = [];
  page:any = 1;
  limit:any = "10";

  sideModal:any = { open:false, 'cntx':'', obj:{} }
  modal:any = { open:false, 'cntx':'', obj:{} }
  constructor(public languageService:LanguageServiceService, public system:SystemService, public http:HttpClient) {
    this.system.GetStorehouses(1, 10);
  }
  ngOnInit() {this.system.GetStorehouses(this.page, this.limit); }






  // ************ HTTP FUNCTIONS ***************//

  AddStorehouse() {
    this.http.post("https://api.nexa.bg/storages", { 'storage': { 'name':this.sideModal.obj.name }}, { withCredentials: true })
    .subscribe(response => {
      this.closeSideModal();
      this.system.GetStorehouses(this.page, this.limit);
    });
  }

  EditStorehouse() {
    this.http.put("https://api.nexa.bg/storages/" + this.sideModal.obj.uid,  { 'storage':this.sideModal.obj }, { withCredentials: true })
    .subscribe(response => {
      this.closeSideModal();
      this.system.GetStorehouses(this.page, this.limit);
    });
  }

  RemoveStorehouse() {
    this.http.delete("https://api.nexa.bg/storages/" + this.modal.obj.uid, { withCredentials: true })
    .subscribe(response => {
      this.closeModal();
      this.system.GetStorehouses(this.page, this.limit);
    });
  }
  // ******************************************//

  // ********** MODAL FUNCTIONS ****************//
  openModal(cntx, obj) { this.modal = { open:true, 'cntx':cntx, 'obj':obj }; }
  closeModal() { this.modal.open = false; }
  openSideModal(cntx, obj) { this.sideModal = { open:true, 'cntx':cntx, 'obj':obj }; }
  closeSideModal() { this.sideModal.open = false; }
  // *******************************************//


  // ********** UTILITY FUNCTIONS **************//
  switchType() { this.system.GetStorehouses(this.page, this.limit);}
  switchLimit() { this.system.GetStorehouses(this.page, this.limit);}


  goToPage(page) { this.page = page;  }
  NextPage() { this.page += 1;  }
  PrevPage() { this.page -= 1; }
  StartPage() { this.page = 1;  }
  LastPage() { this.page = this.system.ReturnTotalOrdersPages(); }
  // ******************************************//

  // ************** RETURN FUNCTIONS **********//
  returnFormattedDate(dt) {
    let split_date = dt.split("T");
    let date_reorder = split_date[0].split("-");
    return date_reorder[2] + "." + date_reorder[1] + "." + date_reorder[0];
  }
  ReturnShadowCopy(obj) { return JSON.parse(JSON.stringify(obj)); }
  // ******************************************//
}
