<!-- Modal -->
 <!-- REMOVE STOREHOUSE -->
 <div *ngIf="modal.open"  style=" background-color: rgba(0, 0, 0, 0.8)" class="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" x-show.transition.opacity="openModal">
    <div class="p-4 max-w-xl mx-auto relative absolute left-0 right-0 overflow-hidden mt-24">
        <div class="shadow absolute right-0 top-0 w-10 h-10 rounded-full bg-white text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer"
            (click)="modal.open = false">
            <svg class="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                    d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
            </svg>
        </div>

        <div class="shadow w-full rounded-lg bg-white overflow-hidden w-full block p-8" *ngIf="modal.cntx == 'remove-product'">
            
            <h2 class="font-bold text-2xl mb-6 text-gray-800 border-b pb-2">Премахване на продукт</h2>

            <div class="flex">
                <span>Сигурни ли сте, че искате да премахнете продукт "{{ modal.obj.name }}"</span>
            </div>

            <div class="mt-8 text-right">
                <button type="button" (click)="modal.open = false" class="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded shadow-sm mr-2">
                    {{ languageService.ReturnTranslatedLabel('action_no') }}
                </button>	
                <button (click)="RemoveProduct()" type="button" class="bg-gray-800 hover:bg-gray-700 text-white font-semibold py-2 px-4 border border-gray-700 rounded shadow-sm">
                    {{ languageService.ReturnTranslatedLabel('action_yes') }}
                </button>	
            </div>
        </div>
    </div>
</div>
<!-- /Modal -->


<div class="side-modal" *ngIf="sideModal.open">
    <div class="backdrop"></div>
     <!-- ADD STOREHOUSE -->
    <div class="modal" *ngIf="sideModal.cntx == 'add-product'" [ngClass]="{ 'active':sideModal.open && sideModal.cntx == 'add-product' }">
        <div class="header bg-positive text-white">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8 mt-2 float-left ml-4">
                <path d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z" />
            </svg>
              
            <span class="title float-left ml-2 font-bold">Добавяне на продукт</span>

            <svg xmlns="http://www.w3.org/2000/svg" (click)="sideModal.open = false" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 float-right mt-3 mr-2">
                <path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clip-rule="evenodd" />
            </svg>
        </div>
        <div class="content flex flex-col w-full">
            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 mr-2" >Снимка на продукт<span class="text-red-500">*</span></label>
                <div class="flex w-full">
                    <div class="select-photo" [ngStyle]="{ 'background-image':'url(data:image/png;base64,'+ sideModal.obj.base[0] +')' }"  (click)="triggerFileUpload(0)">
                        <div class="upload-photo-icon" *ngIf="sideModal.obj.base[0] == ''"></div>
                        <span *ngIf="sideModal.obj.base[0] == ''">Изберете снимка за качване</span>
                        <div *ngIf="sideModal.obj.base[0] == ''" class="btn">Качване</div>
                    </div>
                    <input #inputImage0 [(ngModel)]="sideModal.obj.images[0]" accept=".png, .jpg, .jpeg" type="file" style="display:none"  (change)="onFileSelected($event, 0)">
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-row ml-4">
                <div class="small-select-photo" (click)="triggerFileUpload(1)" [ngStyle]="{ 'background-image':'url(data:image/png;base64,'+ sideModal.obj.base[1] +')' }">
                    <div class="upload-photo-icon"  *ngIf="sideModal.obj.base[1] == ''"></div>
                    <input #inputImage1 [(ngModel)]="sideModal.obj.images[1]" accept=".png, .jpg, .jpeg" type="file" style="display:none"  (change)="onFileSelected($event, 1)">
                </div>
                <div class="small-select-photo" (click)="triggerFileUpload(2)" [ngStyle]="{ 'background-image':'url(data:image/png;base64,'+ sideModal.obj.base[2] +')' }">
                    <div class="upload-photo-icon"  *ngIf="sideModal.obj.base[2] == ''"></div>
                    <input #inputImage2 [(ngModel)]="sideModal.obj.images[2]" accept=".png, .jpg, .jpeg" type="file" style="display:none"  (change)="onFileSelected($event, 2)">
                </div>
                <div class="small-select-photo" (click)="triggerFileUpload(3)" [ngStyle]="{ 'background-image':'url(data:image/png;base64,'+ sideModal.obj.base[3] +')' }">
                    <div class="upload-photo-icon" *ngIf="sideModal.obj.base[3] == ''"></div>
                    <input #inputImage3 [(ngModel)]="sideModal.obj.images[3]" accept=".png, .jpg, .jpeg" type="file" style="display:none"  (change)="onFileSelected($event, 3)">
                </div>
                <div class="small-select-photo" (click)="triggerFileUpload(4)" [ngStyle]="{ 'background-image':'url(data:image/png;base64,'+ sideModal.obj.base[4] +')' }">
                    <div class="upload-photo-icon" *ngIf="sideModal.obj.base[4] == ''"></div>
                    <input #inputImage4 [(ngModel)]="sideModal.obj.images[4]" accept=".png, .jpg, .jpeg" type="file" style="display:none"  (change)="onFileSelected($event, 4)">
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 mr-2" >Наименование на продукт<span class="text-red-500">*</span></label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" type="text">
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 mr-2" >Описание на продукт<span class="text-red-500">*</span></label>
                <div class="flex w-full">
                    <textarea [(ngModel)]="sideModal.obj.description" style="height:100px;resize:none;" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full mr-8">
                    </textarea>
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 mr-2" >Баркод на продукт<span class="text-red-500">*</span></label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.ean" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" type="text">
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 w-full mr-2">Склад</label>
                <div class="flex w-full">
                    <ng-select style="width:calc(100% - 30px);"  class="mb-1 border-2 border-white rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500" 
                    [items]="available_storages" 
                    bindLabel="name" 
                    bindValue="uid" 
                    (clear)="sideModal.obj.storage_id = 0"
                    (change)="ChangedStorehouse()"
                    [(ngModel)]="sideModal.obj.storage_id">
                    </ng-select>
                </div>
            </div>

            <span *ngIf="sideModal.obj.storage_id == 0" class="mb-2 mt-2 w-full flex flex-col ml-4 text-xs text-red-500">Трябва да изберете склад, за да може да продължите</span>

            <div class="mb-2 mt-2 w-full flex flex-col ml-4" *ngIf="sideModal.obj.storage_id != 0">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 w-full mr-2">Категория</label>
                <div class="flex w-full">
                    <ng-select style="width:calc(100% - 30px);"  class="mb-1 border-2 border-white rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500" 
                    [items]="filtered_available_categories" 
                    bindLabel="name" 
                    bindValue="uid" 
                    (clear)="sideModal.obj.category_id = 0"
                    [(ngModel)]="sideModal.obj.category_id">
                    </ng-select>
                </div>
            </div>


            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 mr-2" >Количество на продукт<span class="text-red-500">*</span></label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.quantity" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" type="text">
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 mr-2" >Цена на продукт<span class="text-red-500">*</span></label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.price" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" type="number">
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 mr-2" >Уникален идентификатор<span class="text-red-500">*</span></label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.sku" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" type="text">
                </div>
            </div>

            <button class="flex items-center justify-center w-full ml-4 mr-4 mt-4 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-positive rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600" style="margin-bottom:20px;" (click)="StartAddProduct()">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>                      

                <span>Добави продукт</span>
            </button>
        </div>
    </div>

    <div class="modal" *ngIf="sideModal.cntx == 'edit-product'" [ngClass]="{ 'active':sideModal.open && sideModal.cntx == 'edit-product' }">
        <div class="header bg-positive text-white">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8 mt-2 float-left ml-4">
                <path d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z" />
            </svg>
              
            <span class="title float-left ml-2 font-bold">Промяна на продукт</span>

            <svg xmlns="http://www.w3.org/2000/svg" (click)="sideModal.open = false" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 float-right mt-3 mr-2">
                <path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clip-rule="evenodd" />
            </svg>
        </div>
        <div class="content flex flex-col w-full">
            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 mr-2" >Снимка на продукт<span class="text-red-500">*</span></label>
                <div class="flex w-full">
                    <div class="select-photo" [ngStyle]="{ 'background-image':'url(' + sideModal.obj.image +')' }"  (click)="triggerFileUpload(0)">
                        <div class="upload-photo-icon" *ngIf="sideModal.obj.image == ''"></div>
                        <span *ngIf="sideModal.obj.image == ''">Изберете снимка за качване</span>
                        <div *ngIf="sideModal.obj.image == ''" class="btn">Качване</div>
                    </div>
                    <input #inputImage0 [(ngModel)]="sideModal.obj.images[0]" accept=".png, .jpg, .jpeg" type="file" style="display:none"  (change)="onFileSelected($event, 0)">
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-row ml-4">
                <div class="small-select-photo" (click)="triggerFileUpload(1)" [ngStyle]="{ 'background-image':'url(data:image/png;base64,'+ sideModal.obj.base[1] +')' }">
                    <div class="upload-photo-icon"  *ngIf="sideModal.obj.base[1] == ''"></div>
                    <input #inputImage1 [(ngModel)]="sideModal.obj.images[1]" accept=".png, .jpg, .jpeg" type="file" style="display:none"  (change)="onFileSelected($event, 1)">
                </div>
                <div class="small-select-photo" (click)="triggerFileUpload(2)" [ngStyle]="{ 'background-image':'url(data:image/png;base64,'+ sideModal.obj.base[2] +')' }">
                    <div class="upload-photo-icon"  *ngIf="sideModal.obj.base[2] == ''"></div>
                    <input #inputImage2 [(ngModel)]="sideModal.obj.images[2]" accept=".png, .jpg, .jpeg" type="file" style="display:none"  (change)="onFileSelected($event, 2)">
                </div>
                <div class="small-select-photo" (click)="triggerFileUpload(3)" [ngStyle]="{ 'background-image':'url(data:image/png;base64,'+ sideModal.obj.base[3] +')' }">
                    <div class="upload-photo-icon" *ngIf="sideModal.obj.base[3] == ''"></div>
                    <input #inputImage3 [(ngModel)]="sideModal.obj.images[3]" accept=".png, .jpg, .jpeg" type="file" style="display:none"  (change)="onFileSelected($event, 3)">
                </div>
                <div class="small-select-photo" (click)="triggerFileUpload(4)" [ngStyle]="{ 'background-image':'url(data:image/png;base64,'+ sideModal.obj.base[4] +')' }">
                    <div class="upload-photo-icon" *ngIf="sideModal.obj.base[4] == ''"></div>
                    <input #inputImage4 [(ngModel)]="sideModal.obj.images[4]" accept=".png, .jpg, .jpeg" type="file" style="display:none"  (change)="onFileSelected($event, 4)">
                </div>
            </div>


            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 mr-2" >Наименование на продукт<span class="text-red-500">*</span></label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" type="text">
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 mr-2" >Описание на продукт<span class="text-red-500">*</span></label>
                <div class="flex w-full">
                    <textarea [(ngModel)]="sideModal.obj.description" style="height:100px;resize:none;" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full mr-8">
                    </textarea>
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 mr-2" >Баркод на продукт<span class="text-red-500">*</span></label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.ean" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" type="text">
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 w-full mr-2">Склад</label>
                <div class="flex w-full">
                    <ng-select style="width:calc(100% - 30px);"  class="mb-1 border-2 border-white rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500" 
                    [items]="available_storages" 
                    bindLabel="name" 
                    bindValue="uid" 
                    (change)="ChangedStorehouse()"
                    (clear)="sideModal.obj.storage_id = 0"
                    [(ngModel)]="sideModal.obj.storage_id">
                    </ng-select>
                </div>
            </div>

            <span *ngIf="sideModal.obj.storage_id == 0" class="mb-2 mt-2 w-full flex flex-col ml-4 text-xs text-red-500">Трябва да изберете склад, за да може да продължите</span>

            <div class="mb-2 mt-2 w-full flex flex-col ml-4" *ngIf="sideModal.obj.storage_id != 0">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 w-full mr-2">Категория</label>
                <div class="flex w-full">
                    <ng-select style="width:calc(100% - 30px);"  class="mb-1 border-2 border-white rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500" 
                    [items]="filtered_available_categories" 
                    bindLabel="name" 
                    bindValue="uid" 
                    (clear)="sideModal.obj.category_id = 0"
                    [(ngModel)]="sideModal.obj.category_id">
                    </ng-select>
                </div>
            </div>


            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 mr-2" >Количество на продукт<span class="text-red-500">*</span></label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.quantity" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" type="text">
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 mr-2" >Цена на продукт<span class="text-red-500">*</span></label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.price" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" type="number">
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 mr-2" >Уникален идентификатор<span class="text-red-500">*</span></label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.sku" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" type="text">
                </div>
            </div>

            <button class="flex items-center justify-center w-full ml-4 mr-4 mt-4 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-positive rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600" style="margin-bottom:20px;" (click)="StartEditProduct()">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>                      

                <span>Промени продукт</span>
            </button>
        </div>
    </div>

    <div class="modal" *ngIf="sideModal.cntx == 'product-series'" [ngClass]="{ 'active':sideModal.open && sideModal.cntx == 'product-series' }">
        <div class="header bg-positive text-white">
          
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 mt-2 float-left ml-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
            </svg>
              
            <span class="title float-left ml-2 font-bold">Партида</span>

            <svg xmlns="http://www.w3.org/2000/svg" (click)="sideModal.open = false" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 float-right mt-3 mr-2">
                <path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clip-rule="evenodd" />
            </svg>
        </div>
        <div class="content flex flex-col w-full">
            
            <div class="content" *ngIf="!modal.obj.adding_series">
                <div class="header">
                    <div class="header-item">Партида номер<span class="required">*</span></div>
                    <div class="header-item">Дата на доставка<span class="required">*</span></div>
                    <div class="header-item">Дата на изтичане<span class="required">*</span></div>
                </div>
                <div class="content-row">
                    <div class="content-item">
                        <input [(ngModel)]="sideModal.obj.ean" readonly type="text" class="input" id="partidaNumber" placeholder="Партида номер" />
                    </div>
                    <div class="content-item">
                        <input  [(ngModel)]="sideModal.obj.ean" readonly type="text" class="input" id="deliveryDate" placeholder="Дата на доставка" />
                    </div>
                    <div class="content-item">
                        <input [(ngModel)]="sideModal.obj.ean" readonly type="text" class="input" id="expiryDate" placeholder="Дата на изтичане" />
                    </div>
                </div>

                <div class="flex justify-center mt-4" style="margin-top: 50px;">
                    <button (click)="openAddingSeries()" class="bg-blue-500 text-white p-2 rounded">
                        Добавяне на партида
                    </button>
                </div>
            </div>

            <div class="content" *ngIf="modal.obj.adding_series">
                <table class="w-full table-auto border-collapse border border-gray-200">
                    <tbody>
                        <tr>
                            <td class="p-4 text-left text-xs font-bold text-gray-700 uppercase">
                                Партида номер<span class="text-red-500">*</span>
                            </td>
                            <td class="p-4">
                                <input [(ngModel)]="sideModal.obj.name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2" type="text">
                            </td>
                        </tr>
                        <tr>
                            <td class="p-4 text-left text-xs font-bold text-gray-700 uppercase">
                                Дата на доставка<span class="text-red-500">*</span>
                            </td>
                            <td class="p-4">
                                <input [(ngModel)]="sideModal.obj.ean" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2" type="text">
                            </td>
                        </tr>
                        <tr>
                            <td class="p-4 text-left text-xs font-bold text-gray-700 uppercase">
                                Дата на изтичане<span class="text-red-500">*</span>
                            </td>
                            <td class="p-4">
                                <input [(ngModel)]="sideModal.obj.ean" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2" type="text">
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="flex justify-center mt-4" style="margin-top: 30px;">
                    <button (click)="closeAddingSeries()" class="bg-red-500 text-white p-2 rounded">
                        Назад
                    </button>
                </div>

                <div class="flex justify-center mt-4" style="margin-top: 50px;">
                    <button class="bg-blue-500 text-white p-2 rounded">
                        Добавави на партида
                    </button>
                </div>
            </div>
            
        
            
        </div> 

        
    </div>

    <div class="modal" style="width: 40%;" *ngIf="sideModal.cntx == 'add-delivery'" [ngClass]="{ 'active':sideModal.open && sideModal.cntx == 'add-delivery' }">
        <div class="header bg-positive text-white">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8 mt-2 float-left ml-4">
                <path d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z" />
            </svg>
            
            <span class="title float-left ml-2 font-bold">Въвеждане на доставка</span>

            <svg xmlns="http://www.w3.org/2000/svg" (click)="sideModal.open = false" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 float-right mt-3 mr-2">
                <path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clip-rule="evenodd" />
            </svg>
        </div>
        <div class="content flex flex-col w-full">
            <!-- <div class="table-sm">
                <div class="table-head">
                    <span>Баркод</span>
                    <span style="float: right; margin-right: 13%;">К-во</span>
                </div>
                <div class="table-sm-content" *ngFor="let item of sideModal.obj.items; let i = index">
                    <input style=" width: 75%; margin-bottom: 5px;"type="text" [(ngModel)]="item.barcode" (keydown)="ChangedBarcodeField(item, i)" />
                    <input style="float: right; width: 20%; margin-bottom: 5px;" type="number" [(ngModel)]="item.quantity" (keydown)="ChangedBarcodeField(item, i)" />
                </div>
            </div>

            <button class="flex items-center justify-center w-full ml-4 mr-4 mt-4 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-positive rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600" style="margin-bottom:20px;" (click)="UpdateQuantities()">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>                      

                <span>Приключване на доставка</span>
            </button> -->

            <div class="delivery-panel">
                <div class="panel-header">
                    <h2>📦 Въвеждане на доставка</h2>
                </div>
                
                <div class="table-container">
                    <div class="table-head">
                        <span>Баркод</span>
                        <span style="float: right; margin-right:9%;">К-во</span>
                    </div>
            
                    <div class="table-content" *ngFor="let item of sideModal.obj.items; let i = index">
                        <input style=" width: 80%; margin-bottom: 5px;" class="barcode-input" (focus)="item.showDropdown = true" (focusout)="DelayClose(item)" type="text" [(ngModel)]="item.name" (keydown)="ChangedBarcodeField(item, i)" placeholder="Сканирайте или въведете..."/>
                        <div *ngIf="item.showDropdown && modal.obj.results.length > 0" class="dropdown">
                            <div 
                                *ngFor="let found_item of modal.obj.results" 
                                (click)="SelectResult(item, found_item)"
                                class="dropdown-item"
                               >
                                {{ found_item.name }}
                            </div>
                        </div>
                        <input style="float: right; width: 19%; margin-bottom: 5px;"  class="quantity-input" type="number" [(ngModel)]="item.quantity" (keydown)="ChangedBarcodeField(item, i)" min="0"/>
                    </div>
                </div>
            
                <button class="confirm-button" (click)="UpdateQuantities()">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>                      
                    <span>Приключване на доставка</span>
                </button>
            </div>
        </div>
        
        
    </div>
</div>

<!-- <section class="min-h-screen custom-min-h-screen bg-nexa text-gray-800">
    <div class="flex flex-col p-4">
        <div class="float-left w-full p-2">
            <div class="relative float-left font-bold text-black" style="font-size:24px;">Продукти</div>

            <div class="flex items-center float-right gap-x-3">
                <button class="disabled flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-positive rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600"  (click)="openSideModal('add-product', { 'name':'', 'image_files':[{}, {}, {}, {}, {}], 'images':['', '', '', '', ''], 'base':['', '', '', '', ''], 'description':'', 'image_upload_count':0, 'total_image_upload_count':0, 'storage_id':0, 'category_id':0, 'quantity':1, 'ean':'', 'sku':'', 'price':0.00 })">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z" />
                    </svg>
                                         
    
                    <span>Ревизии</span>
                </button>
                <button class="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-positive rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600"  (click)="openSideModal('add-delivery', { 'items':[{'name':'', 'quantity':0}] })">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 18.75a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 0 1-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
                    </svg>
                                                             
    
                    <span>Въвеждане на доставка</span>
                </button>
                <button class="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-positive rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600"  (click)="openSideModal('add-product', { 'name':'', 'image_files':[{}, {}, {}, {}, {}], 'images':['', '', '', '', ''], 'base':['', '', '', '', ''], 'description':'', 'image_upload_count':0, 'total_image_upload_count':0, 'storage_id':0, 'category_id':0, 'quantity':1, 'ean':'', 'sku':'', 'price':0.00 })">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>                      
    
                    <span>Добавяне на продукт</span>
                </button>
            </div>
        </div>
        <div class="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 mt-2" style="padding-top:0px;padding-bottom:0px">
                <div class="p-3 bg-white md:rounded-lg h-14 border border-gray-200">
                    <div class="float-left" style="width:240px;">
                        <input type="text" class="h-8 p-2 font-semibold text-xs text-black rounded-lg search-field" placeholder="{{ languageService.ReturnTranslatedLabel('label_search') }}" />
                        <div class="btn float-right rounded-lg bg-positive text-white text-center search-btn">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                            </svg>                              
                        </div>
                    </div>

                    <span class="float-right">
                        <div class="float-left ml-2">
                            <span class="mr-2 text-xs font-bold text-gray-600">{{ languageService.ReturnTranslatedLabel('label_results_per_page') }}</span>
                            <select (change)="switchLimit()" [(ngModel)]="limit" class="h-8 p-2 font-semibold text-xs text-black rounded-lg">
                                <option>10</option>
                                <option>20</option>
                                <option>50</option>
                                <option>100</option>
                            </select>
                        </div>
                        <span class="page-btn" (click)="StartPage()"  [ngClass]="{ 'disabled':system.ReturnCurrentVirtualItemsPage() < 2 }">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
                            </svg>                                                      
                        </span>
                        <span class="page-btn" (click)="PrevPage()"  [ngClass]="{ 'disabled':system.ReturnCurrentVirtualItemsPage() < 2 }">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                              </svg>                              
                        </span>
                        <span class="page-btn hover:bg-positive hover:text-white" *ngIf="system.ReturnCurrentVirtualItemsPage() > 1" (click)="goToPage( system.ReturnCurrentVirtualItemsPage() - 1)">{{ system.ReturnCurrentVirtualItemsPage() - 1 }}</span>
                        <span class="page-btn bg-positive text-white hover:bg-positive hover:text-white" (click)="goToPage( system.ReturnCurrentVirtualItemsPage())">{{ system.ReturnCurrentVirtualItemsPage() }}</span>
                        <span class="page-btn hover:bg-positive hover:text-white" *ngIf="system.ReturnTotalVirtualItemsPage() > system.ReturnCurrentVirtualItemsPage()" (click)="goToPage( system.ReturnCurrentVirtualItemsPage() + 1)">{{ system.ReturnCurrentVirtualItemsPage() + 1 }}</span>
                        <span class="page-btn" (click)="NextPage()" [ngClass]="{ 'disabled':system.ReturnCurrentVirtualItemsPage() == system.ReturnTotalVirtualItemsPage() }">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>                              
                        </span>
                        <span class="page-btn" (click)="LastPage()" [ngClass]="{ 'disabled':system.ReturnCurrentVirtualItemsPage() == system.ReturnTotalVirtualItemsPage() }">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
                            </svg>                              
                        </span>
                    </span>
                </div>
            </div>
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 mt-2" style="padding-top:0px;padding-bottom:0px">
                <div class="border border-gray-200  md:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500">
                                    <button class="flex items-center gap-x-3 focus:outline-none">
                                        <span>Продукт</span>

                                        <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                            <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                            <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                        </svg>
                                    </button>
                                </th>

                                <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                    Активен
                                </th>

                                <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                    Категория
                                </th>

                                <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                    Склад
                                </th>

                                <th scope="col" class="px-4 py-4 text-sm font-normal text-left rtl:text-right text-gray-500">
                                    Количество
                                </th>
                                
                                <th scope="col" class="px-4 py-4 text-sm font-normal text-left rtl:text-right text-gray-500">
                                    Ед.Цена
                                </th>

                                <th scope="col" class="relative py-3.5 px-4">
                                    <span class="sr-only">Edit</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr *ngIf="system.ReturnVirtualitems().length == 0">
                                <td class="text-center p-4 bg-gray-100" colspan="7">Няма въведени продукти</td>
                            </tr>
                            <tr *ngFor="let virtual_item of system.ReturnVirtualitems()">
                                <td class="px-4 py-4 text-sm font-medium whitespace-nowrap">
                                    <div *ngIf="virtual_item.image != ''" style="float:left;width:50px;height:50px;background-image:url('{{ virtual_item.image }}');background-size:contain;background-position: center;background-repeat:no-repeat;margin-right:10px;"></div>
                                    <div *ngIf="virtual_item.image == ''" style="float:left;width:50px;height:50px;margin-right:10px;" class="w-12 h-12 bg-gray-300 rounded-lg">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 ml-3 mt-3">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
                                        </svg>   
                                    </div>
                                    <div style="float:left;margin-top:9px;">
                                        <h2 class="font-medium text-gray-800">{{ virtual_item.name }}</h2>
                                        <p class="text-sm font-normal text-gray-600 ">{{ virtual_item.ean }}</p>
                                    </div>
                                </td>

                                <td scope="col" class="px-4 py-4 text-sm whitespace-nowrap">
                                    <span class="badge-active"> <i class="badge-dot"></i> Активен</span>
                                </td>

                                
                                <td scope="col" class="px-4 py-4 text-sm whitespace-nowrap">
                                    <h4 class="text-gray-700">{{ ReturnCategoryName(virtual_item.category_id) }}</h4>
                                </td>

                                <td scope="col" class="px-4 py-4 text-sm whitespace-nowrap">
                                    <h4 class="text-gray-700">{{ ReturnStorageName(virtual_item.storage_id) }}</h4>
                                </td>

                                <td scope="col" class="px-4 py-4 text-sm whitespace-nowrap">
                                    <i class="badge-dot" [ngClass]="{ 'orange':virtual_item.quantity < 4 && virtual_item.quantity > 1,  'red':virtual_item.quantity < 2 }"></i>
                                    <h4 class="text-gray-700">{{ virtual_item.quantity }}</h4>
                                </td>

                                <td scope="col" class="px-4 py-4 text-sm whitespace-nowrap">
                                    <h4 class="text-gray-700">{{ virtual_item.price }} лв.</h4>
                                </td>

                                <td class="px-4 py-4 text-sm whitespace-nowrap text-right">
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2 group relative" (click)="openSideModal('product-series', ReturnShadowCopy(virtual_item))">
                                           
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                                        </svg>
                                        
                                      
                                        <div style="padding-left:10px;padding-right:10px;" class="opacity-0 bg-black text-white text-sm rounded-md py-2 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-100">
                                            Партида
                                        </div>
                                    </button>
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2 group relative" (click)="openSideModal('edit-product', ReturnShadowCopy(virtual_item))">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                                            <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                                        </svg>     
                                        
                                      
                                        <div style="padding-left:10px;padding-right:10px;" class="opacity-0 bg-black text-white text-sm rounded-md py-2 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-100">
                                            Промяна на продукт
                                        </div>
                                    </button>
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2 relative group" (click)="openModal('remove-product', virtual_item)">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clip-rule="evenodd" />
                                        </svg>  
                                        
                                          
                                        
                                        <div style="padding-left:10px;padding-right:10px;" class="opacity-0 bg-black text-white text-sm rounded-md py-2 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-100">
                                            Премахване на продукт
                                        </div>                                                                            
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section> -->

<div class="container">
    <div class="table-header">
        <h2 class="az">Продукти</h2>
       
        <button class="add-invoice"  >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z" />
            </svg>
                                 

            <span>Ревизии</span>
        </button>

        <button class="add-invoice" (click)="openSideModal('add-delivery', { 'items':[{'name':'', 'quantity':0}] })">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 18.75a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 0 1-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
            </svg>                   

            <span>Въвеждане на доставка</span>
        </button>

        <button class="add-invoice"  (click)="openSideModal('add-product', { 'name':'', 'image_files':[{}, {}, {}, {}, {}], 'images':['', '', '', '', ''], 'base':['', '', '', '', ''], 'description':'', 'image_upload_count':0, 'total_image_upload_count':0, 'storage_id':0, 'category_id':0, 'quantity':1, 'ean':'', 'sku':'', 'price':0.00 })">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>                     

            <span>Добавяне на продукт</span>
        </button>
        <input class="az-input" type="text" placeholder="{{ languageService.ReturnTranslatedLabel('label_search') }}" >
    </div>

    <table class="invoice-table">
        <thead>
            <tr>
                <th>
                    <div>
                        <span>Продукт</span>
                        <svg style="float: right; margin-top: 5px;" class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                            <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                            <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                        </svg>
                    </div>
                </th>
                <th>Активен</th>
                <th>Категория</th>
                <th>Склад</th>
                <th>Количество</th>
                <th>Ед.Цена</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="system.ReturnVirtualitems().length == 0">
                <td class="text-center p-4 bg-gray-100" colspan="7">Няма въведени продукти</td>
            </tr>
            <tr *ngFor="let virtual_item of system.ReturnVirtualitems()">
                <td> 
                    <div *ngIf="virtual_item.image != ''" style="float:left;width:50px;height:50px;background-image:url('{{ virtual_item.image }}');background-size:contain;background-position: center;background-repeat:no-repeat;margin-right:10px;"></div>
                                    <div *ngIf="virtual_item.image == ''" style="float:left;width:50px;height:50px;margin-right:10px;" class="w-12 h-12 bg-gray-300 rounded-lg">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 ml-3 mt-3">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
                                        </svg>   
                                    </div>
                                    <div style="float:left;margin-top:9px;">
                                        <span class="faxNum">{{ virtual_item.name }}</span>
                                        <span class="faxNum">{{ virtual_item.ean }}</span>
                                    </div>
                </td>
                <td><span class="badge-active"> <i class="badge-dot"></i> Активен</span></td>
                <td>
                    {{ ReturnCategoryName(virtual_item.category_id) }}
                </td>
                <td>
                    {{ ReturnStorageName(virtual_item.storage_id) }}
                </td>
                <td>
                    <i class="badge-dot" [ngClass]="{ 'orange':virtual_item.quantity < 4 && virtual_item.quantity > 1,  'red':virtual_item.quantity < 2 }"></i>
                    <span>{{ virtual_item.quantity }}</span>
                </td>
                <td>
                    {{ virtual_item.price }} лв.
                </td>
                <td>
                   <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2 group relative" (click)="openSideModal('product-series', ReturnShadowCopy(virtual_item))">
                                           
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                                        </svg>
                                        
                                        <!-- Tooltip -->
                                        <div style="padding-left:10px;padding-right:10px;" class="opacity-0 bg-black text-white text-sm rounded-md py-2 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-100">
                                            Партида
                                        </div>
                                    </button>
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2 group relative" (click)="openSideModal('edit-product', ReturnShadowCopy(virtual_item))">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                                            <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                                        </svg>     
                                        
                                        <!-- Tooltip -->
                                        <div style="padding-left:10px;padding-right:10px;" class="opacity-0 bg-black text-white text-sm rounded-md py-2 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-100">
                                            Промяна на продукт
                                        </div>
                                    </button>
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2 relative group" (click)="openModal('remove-product', virtual_item)">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clip-rule="evenodd" />
                                        </svg>  
                                        
                                          
                                        <!-- Tooltip -->
                                        <div style="padding-left:10px;padding-right:10px;" class="opacity-0 bg-black text-white text-sm rounded-md py-2 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-100">
                                            Премахване на продукт
                                        </div>                                                                            
                                    </button>
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td colspan="7">
                    <div class="pagination-container">
                        <div class="results-per-page">
                            <label for="results">Резултати на страница</label>
                            <select id="results">
                                <option>10</option>
                                <option>20</option>
                                <option>50</option>
                            </select>
                        </div>
                        <div class="pagination">
                            <button class="prev disabled" (click)="PrevPage()"  [ngClass]="{ 'disabled':system.ReturnCurrentVirtualItemsPage() < 2 }">← Предишна</button>
                            <span class="page-info">{{ ((system.ReturnCurrentVirtualItemsPage() - 1) * 10) + 1 }} от {{ ((system.ReturnCurrentVirtualItemsPage()) * 10) }}</span>
                            <button class="next" (click)="NextPage()" [ngClass]="{ 'disabled':system.ReturnCurrentVirtualItemsPage() == system.ReturnTotalVirtualItemsPage() }">Следваща →</button>
                        </div>
                    </div>
                </td>
            </tr>
        </tfoot>
    </table>
</div>