import { Component, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSelectModule } from 'ngx-select-ex';
import { SystemService, Product } from '../system.service';
import { LanguageServiceService } from '../language-service.service';
import { Location } from '@angular/common';

declare var $;
@Component({
  selector: 'app-add-invoice',
  templateUrl: './add-invoice.component.html',
  styleUrls: ['./add-invoice.component.css']
})
export class AddInvoiceComponent {
  sideModal:any = { open:false, 'cntx':'', obj:{} };
  modal:any = { open:false, cntx:'', obj:{} }
  invoice:any = { invoice_num:3000000001, type:'invoice', bank_id:-1, payment_type:'1', date_added:'00.00.0000', date_due:'00.00.0000','currency_name':'BGN', 'currency_label':'лв.', 'currency_position':'back', 'currency_spacing':1, items:[], client_id:'-1', client_name:"", client_mol:"", client_address:"", client_data:{}  }
  available_clients:any = [
    { client_id:1, client_name:"Аксион Солюшънс ЕООД", client_mol:"Тодор Тодоров", client_address:"Бул.Освобождение 93, блок 193, вх.В, ет.2, офис 4"  },
    { client_id:2, client_name:"Темпекс ЕООД", client_mol:"Атанас Лозанов", client_address:"улица Георги Данчов 16"  }
  ]
  results:any = [];

  @ViewChild('date_paid') date_paid: ElementRef;
  

  constructor(public languageService:LanguageServiceService,private http:HttpClient, public router:Router, public system:SystemService, public route:ActivatedRoute,  public location:Location) {
    let coming_from = this.route.snapshot.params['coming_from'];
    let coming_from_id = this.route.snapshot.params['coming_from_id'];

    if(coming_from == 'order') {
      this.http.get("https://api.nexa.bg/orders/" + coming_from_id, {
        withCredentials: true
      })
      .subscribe(response => {
        let order = response;
        let items = [];
        let products_split = order['products'].split(";");
        items = this.system.ReturnProductsFromItems(products_split);
        // for(var pi = 0; pi < products_split.length - 1; pi++) {
        //   let product_split = products_split[pi].split("|");
        //   items.push({ "name":product_split[0], "price":parseFloat(product_split[1]), "unit":product_split[2],  "quantity":product_split[3], "vat":parseInt(product_split[4]) })
        // }
        this.invoice.client_id = order['client_id'];
        this.invoice.client_name = order['client_name'];
        this.invoice.items = items;
        this.invoice.added_by = order['added_by'];
        this.selectedClient();
      });
    } else if(coming_from == 'offer') {
      this.http.get("https://api.nexa.bg/offers/" + coming_from_id, {
        withCredentials: true
      })
      .subscribe(response => {
        console.log(response);
        let offer = response;
        let items = [];
        let products_split = offer['products'].split(";");
        items = this.system.ReturnProductsFromItems(products_split);
        this.invoice.client_id = offer['client_id'];
        this.invoice.client_name = offer['client_name'];
        this.invoice.items = items;
        this.selectedClient();
      });
    } else if(coming_from == 'invoice') {
      if(coming_from_id != -1) {
        this.http.get("https://api.nexa.bg/invoices/" + coming_from_id, {
          withCredentials: true
        })
        .subscribe(response => {
          let invoice = response;
          let items = [];
          let products_split = invoice['products'].split(";");
          items = this.system.ReturnProductsFromItems(products_split);
          this.invoice.client_id = invoice['client_id'];
          this.invoice.client_name = invoice['client_name'];
          this.invoice.items = items;
          this.invoice.added_by = invoice['added_by'];
          this.selectedClient();
        });
      } else {
        this.invoice.items.push(new Product(1, "", 1, this.system.ReturnVats()[0].vat, this.system.ReturnAvailableUnits()[0].name, 0.00));
        this.invoice.added_by = this.system.ReturnMe().first_name + " " + this.system.ReturnMe().last_name;

      }
    }
    let dt = new Date();
    let dt_paid = new Date();
    dt_paid.setDate(dt_paid.getDate() + 7);
    this.invoice.date_added = this.returnPaddedZero(dt.getDate(), 2) + "." + this.returnPaddedZero(dt.getMonth() + 1, 2) + "." + dt.getFullYear();
    this.invoice.date_due = this.returnPaddedZero(dt_paid.getDate(), 2) + "." + this.returnPaddedZero(dt_paid.getMonth() + 1, 2) + "." + dt_paid.getFullYear();
  

    this.http.get("https://api.nexa.bg/clients", {
      withCredentials: true
    })
    .subscribe(response => {
      this.available_clients = response['clients'];
    });

    this.http.get("https://api.nexa.bg/invoices/max-num?subtype=invoice", {
      withCredentials: true
    })
    .subscribe(response => {
      this.invoice.invoice_num = response['max_num'];
    });

    if(this.system.ReturnBanks().length > 0) {
      this.invoice.bank_id = this.system.ReturnBanks()[0].uid;
    }

    let my_default_currency = this.system.ReturnAvailableCurrencies().filter(currency => currency.name == this.system.ReturnProfile()['currency'])[0];
    this.invoice.currency_name = my_default_currency.name;
    this.invoice.currency_label = my_default_currency.label;
    this.invoice.currency_position = my_default_currency.position;
    this.invoice.currency_spacing = my_default_currency.spacing;
    setTimeout(()=>{
      $( "#date_paid").datepicker({ dateFormat: 'dd.mm.yy' });
    }, 250);
  }

  OpenModal(cntx, obj) {
    this.modal = { open:true, 'cntx':cntx, 'obj':obj }
  }

  GetAvailableClients() {
    this.http.get("https://api.nexa.bg/clients", {
      withCredentials: true
    })
    .subscribe(response => {
      this.available_clients = response['clients'];
    });
  }

  changedInvoiceType() {
    this.http.get("https://api.nexa.bg/invoices/max-num?subtype=" + this.invoice.type, {
      withCredentials: true
    })
    .subscribe(response => {
      this.invoice.invoice_num = response['max_num'];
    });
  }

  changedRow(idx, item) {
    this.ChangedName(item);
    if(idx == this.invoice.items.length - 1) {
      if(this.invoice.items[idx].name.length != 0 || this.invoice.items[idx].quantity != 0 || this.invoice.items[idx].price != 0) {
        //this.invoice.items.push({ id:1, name:"", quantity:1, vat:20, unit:this.system.ReturnAvailableUnits()[0].name, price:0.00 });
        this.invoice.items.push(new Product(idx, "", 1, this.system.ReturnVats()[0].vat, this.system.ReturnAvailableUnits()[0].name, 0.00));
      } 
    }
  }

  ChangedName(item) {
    this.http.post("https://api.nexa.bg/services/search/name", { 'name':item.name }, {
      withCredentials: true
    })
    .subscribe(response => {
      this.results = response['services'];
    });
  }

  clearSelect(item) {
    setTimeout(() => {
      item.selecting = false;
    }, 250);
  }

  focusSelect(item) {
    item.selecting = true;
    this.results = [];
  }

  selectResult(item, result) {
    item.name = result.name;
    item.name += ' - ' + result.description;
    item.price = result.price;
    item.vat = result.vat;
    if(item.quantity == 0) { item.quantity = 1; }
    item.selecting = false;
  }

  AddInvoice() {
    let products = "";
    for(var pi = 0; pi < this.invoice.items.length; pi++) {
      if(this.invoice.items[pi].name.length > 0 || this.invoice.items[pi].price != 0) {
        products += this.invoice.items[pi].name + "|" + this.invoice.items[pi].price + "|" +  this.invoice.items[pi].unit +"|" + this.invoice.items[pi].quantity + "|" + this.invoice.items[pi].vat + ";";
      }
    }
    
    let split_date_add = this.invoice.date_added.split(".");
    let dt_added = new Date(split_date_add[2] + "-" + split_date_add[1] + "-" + split_date_add[0]);
    let reformed_dt_added = dt_added.getFullYear() + "-" + this.returnPaddedZero(dt_added.getMonth() + 1, 2) + "-" + this.returnPaddedZero(dt_added.getDate(), 2);
    
    // let split_date_due = this.invoice.date_due.split(".");
    // let dt_due = new Date(split_date_due[2] + "-" + split_date_due[1] + "-" + split_date_due[0]);
    // let reformed_dt_due = dt_due.getFullYear() + "-" + this.returnPaddedZero(dt_due.getMonth() + 1, 2) + "-" + this.returnPaddedZero(dt_due.getDate(), 2);

    const date_paid = this.date_paid.nativeElement as HTMLInputElement;
    let split_datePaid = date_paid.value.split(".");
    let new_date_paid = split_datePaid[2] + "-" + split_datePaid[1] + "-" + split_datePaid[0];


    this.http.post("https://api.nexa.bg/invoices", { 'invoice':{ num:this.invoice.invoice_num,'type':'invoices', 'subtype':this.invoice.type, 'date_added':reformed_dt_added, 'date_paid':'', 
    'date_due':new_date_paid, due_days:'7', 'client_id':this.invoice.client_id, "client_name":this.invoice.client_name, total_value:this.returnTotal(), type_payment:this.invoice.payment_type, bank_id:this.invoice.bank_id, 
    status:1, 'products':products, "automatic_template":1, 'attached_invoice':0, 'alert_closing':0, 'alert_late':0, 'added_by':this.invoice.added_by, 'comment':'', 'going_to':this.invoice.going_to, 'currency':this.invoice.currency_label, "external_invoice":0, external_currency:"", external_date:""}}, {
      withCredentials: true
    })
    .subscribe(response => {
      console.log(response);
      this.router.navigate(["print-invoice"], { queryParams: { "id":response['uid'] } })
    });
  }

  goBack() {
    this.location.back()
  }

  removeItemFromInvoice(idx) {
    this.invoice.items.splice(idx, 1);
  }

  selectedClient() {
    let client = this.available_clients.filter(client => client.uid == this.invoice.client_id)[0];
    this.http.get("https://api.nexa.bg/clients/" + this.invoice.client_id, {
      withCredentials: true
    })
    .subscribe(response => {
      this.invoice.client_name = response['name'];
      this.invoice.client_data = response;
      this.invoice.going_to = response['mol'];
    });
  }

  changedCurrency() {
    let selected_currency = this.system.ReturnAvailableCurrencies().filter(currency => currency.name == this.invoice.currency_name)[0];
    this.invoice.currency_label = selected_currency.label;
    this.invoice.currency_position = selected_currency.position;
    this.invoice.currency_spacing = selected_currency.spacing;
  }

  AddedBank() { this.modal.open = false; }

  openModal(cntx, obj) { this.modal = { open:true, 'cntx':cntx, 'obj':obj }; }
  closeModal() { this.modal.open = false; }

  AddClient() {
    this.http.post("https://api.nexa.bg/clients", { 'client':this.sideModal.obj}, {
      withCredentials: true
    })
    .subscribe(response => {
      this.GetAvailableClients();
      this.closeSideModal();
    });
  }

  openSideModal(cntx, obj) { 
    this.sideModal = { open:true, 'cntx':'', 'obj':obj }
    setTimeout(()=>{
      this.sideModal.cntx = cntx;
    }, 250);
  }
  closeSideModal() { this.sideModal.open = false; }


  ReturnIsAllowedToAddClient(){ 
    if(this.sideModal.obj.type == 'company') {
      if(this.sideModal.obj.name.length < 2) {
        return false;
      } else {
        return true;
      }
    } else {
      if(this.sideModal.obj.name.length < 2) {
        return false;
      } else {
        return true;
      }
    }
  }

  returnFixed(num) { return num.toFixed(2); }
  returnPaddedZero(num, len) {
    var n = Math.abs(num);
    var zeros = Math.max(0, len - Math.floor(n).toString().length );
    var zeroString = Math.pow(10,zeros).toString().substr(1);
    if( num < 0 ) {
        zeroString = '-' + zeroString;
    }

    return zeroString+n;
  }

  returnStandardTotal() {
    let total = 0.00;
    for(let i = 0; i < this.invoice.items.length; i++) {
      total += this.invoice.items[i].price * this.invoice.items[i].quantity;
    }
    return total.toFixed(2);
  }
  returnVatTotal() {
    let total = 0.00;
    for(let i = 0; i < this.invoice.items.length; i++) {
      total += (this.invoice.items[i].price * this.invoice.items[i].quantity) * ( this.invoice.items[i].vat / 100);
    }
    return total.toFixed(2);
  }
  returnTotal() {
    return (parseFloat(this.returnStandardTotal()) + parseFloat(this.returnVatTotal())).toFixed(2);
  }
  returnPriceWithCurrency(price) {
    let str = '';
    if(this.invoice.currency_position == 'front') {
      str += this.invoice.currency_label;
      if(this.invoice.currency_spacing == 1) { str += ' '; }
      str += price;
    } else {
      str += price;
      if(this.invoice.currency_spacing == 1) { str += ' ' }
      str += this.invoice.currency_label;
    }
    return str;
  }

  escape(s) {
    return ('' + s)
        .replace(/\\/g, '\\\\')
        .replace(/\t/g, '\\t')
        .replace(/\n/g, '\\n')
        .replace(/\u00A0/g, '\\u00A0')
        .replace(/&/g, '\\x26')
        .replace(/'/g, '\\x27')
        .replace(/"/g, '\\x22')
        .replace(/</g, '\\x3C')
        .replace(/>/g, '\\x3E');
  }

  unescape(s) {
    s = ('' + s)
      .replaceAll(/x3E/g, '>')
      .replaceAll(/x3C/g, '<')
      .replaceAll(/x22/g, '"')
      .replaceAll(/x27/g, "'")
      .replaceAll(/x26/g, '&')
      .replaceAll(/u00A0/g, '\u00A0')
      .replaceAll(/\n/g, '\n')
      .replaceAll(/\t/g, '\t');

    return s.replaceAll(/\\\\/g, '\\');
}
}
