import { Component, OnInit } from '@angular/core';
import { LanguageServiceService } from '../language-service.service';
import { SystemService } from '../system.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

declare var sha1;
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  tab:any = 3;
  subtab:any = 1;

  showDone:any = false;
  modal:any = { open:false, 'cntx':'', 'obj':{} }
  banks:any = [];
  invoice_template:any = "";
  html = '';
  htmlContent:any;
  templates:any = { invoices:[], offers:[] };

  me:any;
  company_settings:any = { company_name:"Зареждане...", company_gov_num:"Зареждане...", company_vat:"Зареждане...", company_address:"Зареждане...", company_owner:"Зареждане...", invoice_num:"Зареждане...", template:"Зареждане..." };
  original_company_settings:any = { company_name:"Зареждане...", company_gov_num:"Зареждане...", company_vat:"Зареждане...", company_address:"Зареждане...", company_owner:"Зареждане...", invoice_num:"Зареждане...", template:"Зареждане..." };
  import_settings:any = { 'storehouse_id':-1, 'import_url':'', 'import_options':[false, false, false], step:1, products:[], categories:[], manufacturers:[], importing:false, product_idx:0, category_idx:0, import_action:'categories' }
  constructor(public languageService:LanguageServiceService,public system:SystemService, public route:ActivatedRoute, public http:HttpClient){}

  integrations:any = {};
  available_integrations:any = [
    { 'name':'OpenCart', 'type':'online-store', 'class_logo':'opencart-logo', 'description':'Импортиране/експортиране на продукти, категории и синхронизация на поръчки', 'tabs':[{ 'name':'Обща информация', idx:1 }, { 'name':'Импортиране на данни', idx:2 }, { 'name':'Настройки за поръчки', idx:3 }], available:true },
    { 'name':'WordPress',  'type':'online-store', 'class_logo':'wordpress-logo', 'description':'Импортиране/експортиране на продукти, категории и синхронизация на поръчки', available:true },
    { 'name':'PrestaShop',  'type':'online-store', 'class_logo':'prestashop-logo', 'description':'Импортиране/експортиране на продукти, категории и синхронизация на поръчки', available:true },
    { 'name':'Shopify',  'type':'online-store', 'class_logo':'shopify-logo', 'description':'Импортиране/експортиране на продукти, категории и синхронизация на поръчки', available:false },
    { 'name':'ECONT',  'type':'shipment', 'class_logo':'econt-logo', 'description':'Добавяне, генериране и следене на товарителници', available:true },
    { 'name':'Speedy',  'type':'shipment', 'class_logo':'speedy-logo', 'description':'Добавяне, генериране и следене на товарителници', available:false },
  ];
  filter_integrations:any = 'all';
  all_products:any = [];
  all_categories:any = [];
  currentIntegration:any = {};

  // make sure to destory the editor
  ngOnDestroy(): void {
  }

  ngOnInit() {
    this.system.GetIntegrations();
    setTimeout(() => { 
      this.GetSettings();this.GetBanks(); 
    }, 500);
    this.route.queryParams.subscribe(params => {
      if(params['settings_go_to'] !== undefined) {
        if(params['settings_go_to'] == '1,2') {
          this.SwitchTab(1);
          this.subtab = 2;
        }
      } else {
        console.log("No activation point");
      }
    });
    this.system.GetStorehouses(1, 20);
  }

  SwitchTab(tab) {
    this.tab = tab;
    this.subtab = 1;
  }

  GetSettings() {
    if(this.system.ReturnProfile().company_name == undefined) {
      setTimeout(() => { this.GetSettings() }, 500);
    }
    this.original_company_settings = JSON.parse(JSON.stringify(this.system.ReturnProfile()));
    this.company_settings = JSON.parse(JSON.stringify(this.system.ReturnProfile()));
    this.templates = JSON.parse(JSON.stringify(this.system.ReturnTemplates()));
    this.me = JSON.parse(JSON.stringify(this.system.ReturnMe()));
    this.integrations = this.system.ReturnIntegrations();
    for(var i = 0; i < this.integrations.length; i++) {
      this.integrations[i].data = JSON.parse(this.integrations[i].data);
    }
    if(this.integrations.filter(integration => integration.name == 'OpenCart').length == 0) {
      this.integrations.push({ 'name':'OpenCart', 'data':{ 'enabled':false, 'url':'' } })
    }
    if(this.integrations.filter(integration => integration.name == 'ECONT').length == 0) {
      this.integrations.push({ 'name':'ECONT', 'data':{ 'enabled':false, 'username':'', 'password':'' } })
    }
    console.log(this.integrations);
    console.log(this.templates);
  }

  GetBanks() {
    if(this.system.ReturnProfile().company_name == undefined) {
      setTimeout(() => { this.GetBanks() }, 500);
    }
    this.banks = this.system.ReturnBanks();
  }

  openModal(cntx, obj) {
    this.modal = { open:true, 'cntx':cntx, 'obj':obj };
  }
  clsoeModal() { this.modal.open = false; }

  ShowDone() {
    this.showDone = true;
    setTimeout(()=>{ this.showDone = false; }, 2000);
  }

  AddedBank(){ 
    this.modal.open = false;
  }

  RemovedBank() {
    this.modal.open = false;
  }

  AddedVat() {
    this.modal.open = false;
  }

  RemovedVat() {
    this.modal.open = false;
  }

  SaveSettings() {
    this.system.SaveSettings(this.company_settings, this);
  }


  SelectIntegration(integration) {
    this.currentIntegration = integration;
    this.currentIntegration.tab = 1;
    if(this.currentIntegration.name == 'OpenCart') {
      if(this.ReturnOpenCartIntegrations().data.enabled) {
        this.http.get(this.ReturnOpenCartIntegrations().data.domain_url + '/index.php?route=extension/sitesynchronizer/sitesynchronizer/status', {})
        .subscribe(response => {
          this.ReturnOpenCartIntegrations().data.connected = true;
          this.ReturnOpenCartIntegrations().data.product_count = response['product_count'];
          this.ReturnOpenCartIntegrations().data.categories_count = response['categories_count'];
          this.ReturnOpenCartIntegrations().data.version = response['version'];
        },(error) => {                              //error() callback
          this.ReturnOpenCartIntegrations().data.connecting = false;
          this.ReturnOpenCartIntegrations().data.connected = false;
        });
      }

      this.http.get("https://api.nexa.bg/storage-items?page=1&limit=10000", {
        withCredentials: true
      }).subscribe(response => {
        this.all_products = response['storage_items'];
      },(error) => {                              //error() callback
      });
      this.http.get("https://api.nexa.bg/storage-categories?page=1&limit=10000", {
        withCredentials: true
      })
      .subscribe(response => {
        this.all_categories = response['storage_categories'];
      },(error) => {                              //error() callback
      });
    }
  }

  AddOpenCartIntegration() {
    if(this.ReturnOpenCartIntegrations().uid == undefined) {
      this.http.post("https://api.nexa.bg/integrations", { 'integration': { 'name':this.ReturnOpenCartIntegrations().name, 'data':JSON.stringify(this.ReturnOpenCartIntegrations().data)}}, { withCredentials: true })
      .subscribe(resp => {
        this.system.GetIntegrations();
        setTimeout(()=>{
          this.integrations = this.system.ReturnIntegrations();
          for(var i = 0; i < this.integrations.length; i++) {
            this.integrations[i].data = JSON.parse(this.integrations[i].data);
          }
        }, 250);
      });
    } else {
      this.http.put("https://api.nexa.bg/integrations", { 'integration': {'uid':this.ReturnOpenCartIntegrations().uid, 'name':this.ReturnOpenCartIntegrations().name, 'data':JSON.stringify(this.ReturnOpenCartIntegrations().data)}}, { withCredentials: true })
      .subscribe(resp => {
        this.system.GetIntegrations();
        setTimeout(()=>{
          this.integrations = this.system.ReturnIntegrations();
          for(var i = 0; i < this.integrations.length; i++) {
            this.integrations[i].data = JSON.parse(this.integrations[i].data);
          }
        }, 250);
      });
    }
  }

  AddEcontIntegration() {
    if(this.ReturnCurrentIntegrationData().uid == undefined) {
      this.http.post("https://api.nexa.bg/integrations", { 'integration': { 'name':this.ReturnCurrentIntegrationData().name, 'data':JSON.stringify(this.ReturnCurrentIntegrationData().data)}}, { withCredentials: true })
      .subscribe(resp => {
        this.system.GetIntegrations();
        setTimeout(()=>{
          this.integrations = this.system.ReturnIntegrations();
          for(var i = 0; i < this.integrations.length; i++) {
            this.integrations[i].data = JSON.parse(this.integrations[i].data);
          }
        }, 250);
      });
    } else {
      this.http.put("https://api.nexa.bg/integrations", { 'integration': {'uid':this.ReturnCurrentIntegrationData().uid, 'name':this.ReturnCurrentIntegrationData().name, 'data':JSON.stringify(this.ReturnCurrentIntegrationData().data)}}, { withCredentials: true })
      .subscribe(resp => {
        this.system.GetIntegrations();
        setTimeout(()=>{
          this.integrations = this.system.ReturnIntegrations();
          for(var i = 0; i < this.integrations.length; i++) {
            this.integrations[i].data = JSON.parse(this.integrations[i].data);
          }
        }, 250);
      });
    }
  }

  ConnectToUrl() {
    this.ReturnOpenCartIntegrations().data.connecting = true;
    this.ReturnOpenCartIntegrations().data.connected = false;
    this.http.get(this.import_settings.import_url + '/index.php?route=extension/sitesynchronizer/sitesynchronizer/status', {})
    .subscribe(response => {
      setTimeout(()=>{
        this.ReturnOpenCartIntegrations().data.connecting = false;
        this.ReturnOpenCartIntegrations().data.connected = true;
        this.ReturnOpenCartIntegrations().data.product_count = response['product_count'];
        this.ReturnOpenCartIntegrations().data.categories_count = response['categories_count'];
        this.ReturnOpenCartIntegrations().data.version = response['version'];
        this.ReturnOpenCartIntegrations().data.domain_url = this.import_settings.import_url;
        this.AddOpenCartIntegration();
      }, 1500);
      console.log(response);
    },(error) => {                              //error() callback
      this.ReturnOpenCartIntegrations().data.connecting = false;
      this.ReturnOpenCartIntegrations().data.connected = false;
    });
  }

  ImportFromURL() {
    this.http.get(this.ReturnOpenCartIntegrations().data.domain_url + "/index.php?route=extension/sitesynchronizer/sitesynchronizer/getAllData&limits=1500&start=1500", {})
    .subscribe(response => {
      console.log(response);
      this.import_settings.categories = response['categories'];
      this.import_settings.products = response['products'];
      this.import_settings.manufacturers = response['manufacturers'];
      this.import_settings.base_url = this.import_settings.import_url.split("index.php?")[0];
      for(let pi = 0; pi < this.import_settings.products.length; pi++) {
        if(this.all_products.filter(product => product.shop_item_id == this.import_settings.products[pi].product_id).length > 0) {
          this.import_settings.products[pi].state = 'OLD';
        } else {
          this.import_settings.products[pi].state = 'NEW';
        }
      }
      for(let pi = 0; pi < this.import_settings.categories.length; pi++) {
        if(this.all_categories.filter(category => category.shop_item_id == this.import_settings.categories[pi].category_id).length > 0) {
          this.import_settings.categories[pi].state = 'OLD';
        } else {
          this.import_settings.categories[pi].state = 'NEW';
        }
      }
      console.log(this.import_settings.categories);
      console.log(this.import_settings.products);
      this.import_settings.step = 2;
      let activated_id = -1;
      if(this.import_settings.import_options[0]) { activated_id = 0; }
      if(this.import_settings.import_options[1] && activated_id == -1) { activated_id = 1; }
      if(this.import_settings.import_options[2] && activated_id == -1) { activated_id = 2; }
      this.import_settings.selected_category = activated_id;
    },(error) => {                              //error() callback

    });
  }

  StartImportAction() {
    this.import_settings.importing = true; 
    this.import_settings.category_idx = 0;
    this.import_settings.product_idx = 0;
    if(this.import_settings.import_options[1]) {this.ImportCategory(); }
    if(!this.import_settings.import_options[1]) {
      this.ImportProduct();
    }
  }

  ImportCategory() {
    if(this.import_settings.categories[this.import_settings.category_idx].image != "") {
      if(this.import_settings.categories[this.import_settings.category_idx].image.split(" ").length > 0) {
        this.import_settings.categories[this.import_settings.category_idx].image = this.import_settings.categories[this.import_settings.category_idx].image.replace(/ /g, '%20');
      }
      console.log(this.import_settings.categories[this.import_settings.category_idx].image);
      this.http.post('https://images.nexa.bg/upload_category_image_from_url.php?u=' + sha1(this.system.ReturnMe().uid.toString()) + "&c=" + this.generateSafeUUID()  + "&ui=" + this.system.ReturnMe().uid.toString(), { 'image_url': this.ReturnOpenCartIntegrations().data.domain_url + "/image/"+ this.import_settings.categories[this.import_settings.category_idx].image })
      .subscribe(response => {
        let image = 'https://images.nexa.bg/' + response['file'];
        let parent_id = 0;
        if(this.import_settings.categories[this.import_settings.category_idx].parent_id != "0") {
          parent_id = this.import_settings.categories.filter(cat => cat.category_id == this.import_settings.categories[this.import_settings.category_idx].parent_id)[0].new_id;
        }
        this.http.post("https://api.nexa.bg/storage-categories", { 'storage_category': { 'name':this.import_settings.categories[this.import_settings.category_idx].name, 'description':'', 'image':image, 'storage_id':this.import_settings.storehouse_id, 'parent_id':parent_id,'shop_item_id':this.import_settings.categories[this.import_settings.category_idx].category_id }}, { withCredentials: true })
        .subscribe(resp => {
          this.import_settings.categories[this.import_settings.category_idx].new_id = resp['uid'];
          this.import_settings.category_idx += 1;
          if(this.import_settings.category_idx < this.import_settings.categories.length) {
            this.ImportCategory();
          } else {
            if(this.import_settings.import_options[2]) {
              this.ImportProduct();
            } else {
              this.import_settings.importing = false;
            }
          }
        });
      });
    } else {
      if(this.import_settings.category_idx < this.import_settings.categories.length) {
        let parent_id = 0;
        if(this.import_settings.categories[this.import_settings.category_idx].parent_id != "0") {
          parent_id = this.import_settings.categories.filter(cat => cat.category_id == this.import_settings.categories[this.import_settings.category_idx].parent_id)[0].new_id;
        }
        this.http.post("https://api.nexa.bg/storage-categories", { 'storage_category': { 'name':this.import_settings.categories[this.import_settings.category_idx].name, 'description':'', 'image':'', 'storage_id':this.import_settings.storehouse_id, 'parent_id':parent_id,'shop_item_id':this.import_settings.categories[this.import_settings.category_idx].category_id  }}, { withCredentials: true })
        .subscribe(resp => {
          this.import_settings.categories[this.import_settings.category_idx].new_id = resp['uid'];
          this.import_settings.category_idx += 1;
          if(this.import_settings.category_idx < this.import_settings.categories.length) {
            this.ImportCategory();
          } else {
            if(this.import_settings.import_options[2]) {
              this.ImportProduct();
            } else {
              this.import_settings.importing = false;
            }
          }
        });
      } else {
        this.import_settings.importing = false;
      }
    }
  }

  ImportProduct() {
    this.import_settings.import_action = 'products';
    if(this.import_settings.products[this.import_settings.product_idx].image != "") {
      this.http.post('https://images.nexa.bg/upload_product_image_from_url.php?u=' + sha1(this.system.ReturnMe().uid.toString()) + "&c=" + this.generateSafeUUID()  + "&ui=" + this.system.ReturnMe().uid.toString(), { 'image_url': this.ReturnOpenCartIntegrations().data.domain_url  + "/image/"+ this.import_settings.products[this.import_settings.product_idx].image })
      .subscribe(response => {
        let image = 'https://images.nexa.bg/' + response['file'];
        let category_id = -1;
        if(this.import_settings.import_options[1]) {
          category_id = this.import_settings.categories.filter(cat => cat.category_id == this.import_settings.products[this.import_settings.product_idx]['categories'][0])[0].new_id;
        }
        this.http.post("https://api.nexa.bg/storage-items", { 'storage_item': { 'name':this.import_settings.products[this.import_settings.product_idx].name.replace(/[']/g, ""), 'description':this.import_settings.products[this.import_settings.product_idx].description, 'image':image, 'additional_images':JSON.stringify([]), 'storage_id':this.import_settings.storehouse_id, 'category_id':category_id, 'ean':this.import_settings.products[this.import_settings.product_idx].ean, 'quantity':this.import_settings.products[this.import_settings.product_idx].quantity, 'sku':this.import_settings.products[this.import_settings.product_idx].model, 'price':this.import_settings.products[this.import_settings.product_idx].price, 'shop_item_id':this.import_settings.products[this.import_settings.product_idx].product_id, 'active':1 }}, { withCredentials: true })
        .subscribe(resp => {
          this.import_settings.product_idx += 1;
          if(this.import_settings.product_idx < this.import_settings.products.length) {
            this.ImportProduct();
          } else {
            this.import_settings.importing = false;
          }
        });
      });
    } else {
      if(this.import_settings.product_idx < this.import_settings.products.length) {
        let category_id = -1;
        if(this.import_settings.import_options[1]) {
          category_id = this.import_settings.categories.filter(cat => cat.category_id == this.import_settings.products[this.import_settings.product_idx]['categories'][0])[0].new_id;
        }
        this.http.post("https://api.nexa.bg/storage-items", { 'storage_item': { 'name':this.import_settings.products[this.import_settings.product_idx].name.replace(/[']/g, ""), 'description':this.import_settings.products[this.import_settings.product_idx].description, 'image':'', 'additional_images':JSON.stringify([]), 'storage_id':this.import_settings.storehouse_id, 'category_id':category_id, 'ean':this.import_settings.products[this.import_settings.product_idx].ean, 'quantity':this.import_settings.products[this.import_settings.product_idx].quantity, 'sku':this.import_settings.products[this.import_settings.product_idx].model, 'price':this.import_settings.products[this.import_settings.product_idx].price,  'shop_item_id':this.import_settings.products[this.import_settings.product_idx].product_id, 'active':1 }}, { withCredentials: true })
        .subscribe(resp => {
          this.import_settings.product_idx += 1;
          if(this.import_settings.product_idx < this.import_settings.products.length) {
            this.ImportProduct();
          } else {
            this.import_settings.importing = false;
          }
        });
      } else {
        this.import_settings.importing = false;
      }
    }
  }

  ConnectToEcont() {
    this.ReturnCurrentIntegrationData().data.connecting = true;
    this.http.get("https://crm.nexa.bg/api/econt/econt_api.php?username="+ this.ReturnCurrentIntegrationData().data.username +"&password="+ this.ReturnCurrentIntegrationData().data.password +"&shipment_number=1234&action=track_shipment", {
      withCredentials: true
    }).subscribe(response => { 
      this.ReturnCurrentIntegrationData().data.connected = response['data']['logged'];
      this.ReturnCurrentIntegrationData().data.connecting = false;
      if(this.ReturnCurrentIntegrationData().data.connected) {
        this.AddEcontIntegration();
      }
    }, (error) => { 
    });
  }

  AddUser() {
    this.http.post("https://api.nexa.bg/invites", { 'invite': { 'to_name':this.modal.obj.name, 'to_mail':this.modal.obj.email, 'to_role':this.modal.obj.role, 'to_birthday':'' }}, { withCredentials: true })
    .subscribe(resp => {
      this.clsoeModal();
    });
  }

  generateSafeUUID() {
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let uuid = '';
    for (let i = 0; i < 16; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      uuid += chars.charAt(randomIndex);
    }
    return uuid;
  }

  ReturnCategoryName(cat_id) {
    if(cat_id == 0) {
      return 'Няма основна категория';
    } else {
      if(this.import_settings.categories.filter(cat => cat.category_id == cat_id).length == 0) {
        return 'Няма основна категория';
      } else {
        return this.import_settings.categories.filter(cat => cat.category_id == cat_id)[0].name;
      }
  
    }

  }

  ReturnDidCompanySettingsChange() {
    let changed = false;
    let n = this.company_settings;
    let o = this.original_company_settings;
    if(n !== undefined && o !== undefined) {
      if(n.company_name != o.company_name || n.company_gov_num != o.company_gov_num || n.company_vat != o.company_vat || n.company_address != o.company_address || n.company_owner != o.company_owner || n.company_city != o.company_city) {
        changed = true;
      }
      if(n.language != o.language || n.currency != o.currency) {
        changed = true;
      }
      if(n.invoice_num != o.invoice_num || n.template != o.template || n.primary_color != o.primary_color || n.accent_color != o.accent_color) {
        changed = true;
      } 
    }

    if(n !== undefined && o !== undefined) {
      if(n.company_name_en != o.company_name_en || n.company_gov_num_en != o.company_gov_num_en || n.company_vat_en != o.company_vat_en || n.company_address_en != o.company_address_en || n.company_owner_en != o.company_owner_en || n.company_city_en != o.company_city_en) {
        changed = true;
      }
    }

    return changed;
  }

  ReturnAvatarSha(user) {
    return sha1(user.uid.toString());
  }

  SwitchedOpenCartAllowed() {
    // if(this.integrations.filter(integration => integration.name == 'OpenCart').length > 0) {

    // } else {
    //   this.integrations.push({ name:'OpenCart', 'enabled':true })
    // }
  }

  ReturnOpenCartIntegrations() {
   return this.integrations.filter(integration => integration.name == 'OpenCart')[0];
  }

  ReturnWordPressIntegrations() {
    return this.integrations.filter(integration => integration.name == 'WordPress')[0];
  }

  ReturnPrestaShopIntegrations() {
    return this.integrations.filter(integration => integration.name == 'PrestaShop')[0];
  }

  ReturnCurrentIntegrationData() {
    return this.integrations.filter(integration => integration.name == this.currentIntegration.name)[0];
  }

  ReturnFilteredIntegrations() {
    if(this.filter_integrations == 'all') {
      return this.available_integrations.sort((a, b) => b.available - a.available);
    } else {
      return this.available_integrations.filter(available_integration => available_integration.type == this.filter_integrations).sort((a, b) => b.available - a.available);
    }
  }
}
